import React, { useState } from 'react';
import categoriesService from 'modules/products/services/categories.service';
import Button from 'modules/shared/components/Button';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

import CategoryModal from './CategoryModal';

const AddCategory = () => {
  const [addIsActive, setAddIsActive] = useState(false);

  const addCategory = useSafeApiCall(categoriesService.add);

  const goToAddCategory = () => {
    setAddIsActive(true);
  };

  const toggleModal = () => {
    setAddIsActive(!addIsActive);
  };

  return (
    <>
      {addIsActive && (
        <CategoryModal
          title="Ajouter"
          manageCat={addCategory}
          toggleModal={toggleModal}
        />
      )}
      <Button
        type="button"
        value="Ajouter une catégorie de produit"
        className="button is-dark"
        onClick={goToAddCategory}
      />
    </>
  );
};

export default AddCategory;
