import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import supplierRoutes from 'modules/suppliers/supplier.routes';
import RootState from 'modules/shared/types/reducers/RootState';
import { getCurrentUserRole } from 'modules/shared/selectors/currentUser.selectors';
import Section from 'modules/shared/components/Section';
import useNotifications from 'modules/shared/hooks/useNotifications';
import rolesConstants from 'modules/shared/constants/roles.constants';
import managementRoutes from 'modules/management/management.routes';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

import AddProductModel from '../models/AddProduct.model';

import ManageProduct from '../ManageProduct';
import productsService from '../services/products.service';

interface Props extends ReduxProps {
  supplierId: string;
}

const AddProduct = ({ supplierId, role }: Props) => {
  const history = useHistory();

  const { addNotification } = useNotifications();

  const [processInProgress, setProcessInProgress] = useState(false);

  const addProduct = useSafeApiCall(productsService.addProduct);
  const uploadProductImage = useSafeApiCall(productsService.uploadImage);

  const goToProductTable = () => {
    if (role === rolesConstants.ADMINISTRATOR) {
      history.push({
        pathname: `${managementRoutes.MANAGE}${managementRoutes.SUPPLIERS}/${supplierId}${managementRoutes.PRODUCTS}`,
        search: 'action=added',
      });
    } else {
      history.push({
        pathname: `${managementRoutes.MANAGE}${supplierRoutes.PRODUCTS}`,
        search: 'action=added',
      });
    }
  };

  const createProduct = async (productCreated: AddProductModel, image?: File) => {
    if (!image?.name) {
      return;
    }

    try {
      setProcessInProgress(true);
      const [product, isFirstAborted] = await addProduct(productCreated);
      if (isFirstAborted) {
        return;
      }

      const [, isSecondAborted] = await uploadProductImage(product.id, image);
      if (isSecondAborted) {
        return;
      }

      setProcessInProgress(false);
      goToProductTable();
    } catch (error) {
      setProcessInProgress(false);
      addNotification('error', error.messages);
    }
  };

  return (
    <Section isFullHeight isLightGrey>
      <ManageProduct
        supplierId={supplierId}
        title="Ajouter un produit"
        buttonValue="Ajouter"
        submitForm={createProduct}
        processInProgress={processInProgress}
        addNotification={addNotification}
      />
    </Section>
  );
};

const mapStateToProps = (state: RootState) => ({
  role: getCurrentUserRole(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddProduct);
