import React, { useEffect } from 'react';
import classNames from 'classnames';

import Input from 'modules/shared/components/Input';
import TextArea from 'modules/shared/components/TextArea';
import Tab from 'modules/products/models/Tab.model';
import useForm from 'modules/shared/hooks/useForm.hook';
import inputStyles from 'modules/shared/components/Input/Input.module.scss';

import styles from './LanguageTab.module.css';

interface Props {
  tab: Tab;
  onValueChange: (languageId: string, propName: string, value: string) => void;
  onFormValidityChange: (languageId: string, isValid: boolean) => void;
  disableInputs?: boolean;
}

const NAME = 'name';
const DESCRIPTION = 'description';

const LanguageTab = ({
  tab,
  onValueChange,
  onFormValidityChange,
  disableInputs,
}: Props) => {
  const { language, name, description } = tab;

  const {
    getFieldValue,
    handleChange,
    isValid,
  } = useForm([
    { name: NAME, value: name, isValid: !!name },
    { name: DESCRIPTION, value: description, isValid: !!description },
  ]);

  const nameVal = getFieldValue<string>(NAME);
  const descriptionVal = getFieldValue<string>(DESCRIPTION);

  useEffect(() => {
    onFormValidityChange(language.id, isValid);
  }, [isValid]);

  const onChangedInput = (value: string, valid: boolean, elementName?: string) => {
    handleChange(value, valid, elementName);
    if (elementName) {
      onValueChange(language.id, elementName, value);
    }
  };

  return (
    <div className="content-tab">

      <div className="field">
        <div className="field is-horizontal">
          <div className="field-label is-normal is-left">
            <label htmlFor="productName" className={classNames('label', styles.label)}>
              Nom
            </label>
          </div>
          <div className="field-body">
            <Input
              type="text"
              placeholder="Entrez le nom du produit."
              className={classNames('field', styles.inputDiv)}
              value={nameVal}
              name={NAME}
              onChange={onChangedInput}
              disabled={disableInputs}
            />
          </div>
        </div>
      </div>

      <hr />

      <div className="field">
        <div className="field is-horizontal">
          <div className="field-label is-normal is-left">
            <label htmlFor="productName" className={classNames('label', styles.label)}>
              Description
            </label>
          </div>
          <div className="field-body">
            <TextArea
              placeholder="Entrez la description détaillées du produit."
              value={descriptionVal}
              name={DESCRIPTION}
              onChange={onChangedInput}
              disabled={disableInputs}
              className={inputStyles.inputControl}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageTab;
