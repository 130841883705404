import ApiError from 'modules/shared/models/ApiError.model';
import Filter from 'modules/shared/models/Filter.model';
import ProvinceMap from 'modules/salesmen/models/ProvinceMap.model';
import { makeThrowError } from 'modules/shared/utils/apiErrors.utils';
import HttpService from 'modules/shared/models/HttpService.model';
import errorCodes from 'modules/shared/constants/errorCodes.constants';

import zoneMappers from '../mappers/zones.mappers';
import Zone from '../models/Zone.model';

const mapError = (error: ApiError) => {
  switch (error.code) {
    case '401':
      return 'Votre session à expirée';
    case errorCodes.ITEM_NOT_FOUND:
      return 'La zone désirée est introuvable';
    case errorCodes.ITEMS_NOT_FOUND:
      return 'Zones introuvables';
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const endpoint = '/zones';

const getProvinces = (service: HttpService) => (
  parentId: string,
) => (): Promise<ProvinceMap[]> => service
  .get<Zone[]>(`${endpoint}/byParentId?parentId=${parentId}`)
  .then((data) => data
    .map(zoneMappers.mapToProvince))
  .catch(throwError);

const getZones = (service: HttpService) => (): Promise<Filter[]> => service
  .get<Zone[]>(endpoint)
  .then((data) => data.map(zoneMappers.mapToFilter))
  .catch(throwError);

const getPrioritizedZones = (service: HttpService) => (
  supplierUserId: string,
  contactRequestId: string,
): Promise<Zone[]> => service
  .get<Zone[]>(`${endpoint}/${supplierUserId}/prioritized?contactRequestId=${contactRequestId}`)
  .catch(throwError);

const getBelgiumProvinces = (service: HttpService) => getProvinces(service)('BE');

export default {
  getZones,
  getBelgiumProvinces,
  getPrioritizedZones,
};
