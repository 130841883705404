import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import authenticationRoutes from 'modules/authentication/authentication.routes';

import RootState from '../types/reducers/RootState';
import { getIsConnected, getCurrentUserRole } from '../selectors/currentUser.selectors';

interface Props extends ReduxProps {
  path: string;
  roles?: string[];
  render?: (userRole: string) => JSX.Element;
  children?: JSX.Element;
}

const PrivateRoute = (props: Props) => {
  const {
    roles,
    path,
    children,
    isConnected,
    userRole,
    render,
  } = props;

  return (
    <Route
      path={path}
      render={({ location }) => {
        if (!isConnected) {
          return (
            <Redirect
              to={{
                pathname: authenticationRoutes.SIGN_IN,
                state: { from: location },
              }}
            />
          );
        }

        if (roles && roles.length && !roles.includes(userRole!)) {
          return (
            <Redirect
              to={{
                pathname: '/unauthorized',
                state: { from: location },
              }}
            />
          );
        }

        if (render) {
          return render(userRole!);
        }

        return children;
      }}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  isConnected: getIsConnected(state),
  userRole: getCurrentUserRole(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PrivateRoute);
