import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import EditProduct from 'modules/products/components/EditProduct';
import useSubPath from 'modules/shared/hooks/useSubPath.hook';
import supplierRoutes from '../supplier.routes';

interface Props {
  supplierId: string;
}

const ManageProductRoute = ({ supplierId }: Props) => {
  const match = useRouteMatch<{ productId: string }>();
  const { params } = match;
  const { productId } = params;

  return (
    <Switch>
      <Route path={useSubPath(supplierRoutes.EDIT_PRODUCT)}>
        <EditProduct productId={productId} supplierId={supplierId} />
      </Route>
    </Switch>
  );
};

export default ManageProductRoute;
