import ApiError from 'modules/shared/models/ApiError.model';
import HttpService from 'modules/shared/models/HttpService.model';
import { makeThrowError } from 'modules/shared/utils/apiErrors.utils';
import errorCodes from 'modules/shared/constants/errorCodes.constants';

import PaymentMethod from '../models/PaymentMethod.model';

const mapError = (error: ApiError) => {
  switch (error.code) {
    case '401':
      return 'Votre session à expirée';
    case errorCodes.ITEM_NOT_FOUND:
      return 'La méthode de paiement désirée est introuvable';
    case errorCodes.ITEMS_NOT_FOUND:
      return 'Méthodes de paiement introuvables';
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const endpoint = '/payment-methods';

const get = (service: HttpService) => (): Promise<PaymentMethod[]> => service
  .get<PaymentMethod[]>(endpoint)
  .catch(throwError);

export default {
  get,
};
