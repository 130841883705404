import CustomerReceived from 'modules/account/models/CustomerReceived.model';
import ApiError from 'modules/shared/models/ApiError.model';
import { makeThrowError } from 'modules/shared/utils/apiErrors.utils';
import HttpService from 'modules/shared/models/HttpService.model';
import errorCodes from 'modules/shared/constants/errorCodes.constants';

import Customer from '../models/Customer.model';
import AddCustomer from '../models/AddCustomer.model';
import {
  CONFIRMED_EMAIL,
  CONFIRMED_VAT,
} from '../constants/customers.constants';

const mapError = (error: ApiError) => {
  switch (error.code) {
    case CONFIRMED_EMAIL:
      return 'Cet adresse email est déjà utilisée au sein de notre site';
    case CONFIRMED_VAT:
      return 'Ce numéro de TVA est déjà utilisé au sein de notre site';
    case errorCodes.ITEMS_NOT_FOUND:
      return 'Clients introuvables';
    case errorCodes.ITEM_NOT_FOUND:
      return 'Le client désiré est introuvable';
    case errorCodes.ADD_USER_ERROR:
      return 'Cet utilisateur est déjà inscrit sur le site';
    case '401':
      return 'Votre session à expirée';
    case '409':
      return 'Il semble que vous ayez déjà un compte! Connectez-vous';
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const endpoint = '/customers';

const getById = (service: HttpService) => (
  customerId: string,
): Promise<CustomerReceived> => service
  .get<CustomerReceived>(`${endpoint}/${customerId}`)
  .catch(throwError);

const addCustomer = (service: HttpService) => (
  customer: Customer,
  confirmation: boolean,
): Promise<AddCustomer> => service
  .post<AddCustomer>(`${endpoint}?confirmation=${confirmation}`, customer)
  .catch(throwError);

const updateCustomer = (service: HttpService) => (
  customer: Customer,
  id: string,
): Promise<AddCustomer> => service
  .put<AddCustomer>(`${endpoint}/${id}`, customer)
  .catch(throwError);

export default {
  getById,
  addCustomer,
  updateCustomer,
};
