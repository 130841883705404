import React, { useState } from 'react';
import classNames from 'classnames';

import Input from '../Input';
import styles from './SearchInput.module.scss';

interface Props {
  placeholder: string,
  onChanged: (key: string) => void;
}

const SearchInput = ({ placeholder, onChanged }: Props) => {
  const [visible, setVisible] = useState(true);

  const handleChange = (key: string) => {
    onChanged(key);
    setVisible(key.length === 0);
  };

  return (
    <div className={classNames('control', 'has-icons-right')}>
      <Input
        className={styles.input}
        type="search"
        placeholder={placeholder}
        onChange={(e) => handleChange(e)}
      />
      {visible && (
        <span className={classNames(styles.icon, 'icon', 'is-medium', 'is-right')}>
          <i className="fas fa-search" />
        </span>
      )}
    </div>
  );
};
export default SearchInput;
