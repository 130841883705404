import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import RootState from 'modules/shared/types/reducers/RootState';
import { getDecodedToken } from 'modules/authentication/selectors/token.selectors';
import Loading from 'modules/shared/components/Loading';
import useNotifications from 'modules/shared/hooks/useNotifications';
import Supplier from 'modules/products/models/Supplier.model';
import Customer from 'modules/customers/models/Customer.model';
import AddCustomer from 'modules/customers/models/AddCustomer.model';
import { getCurrentUserRole } from 'modules/shared/selectors/currentUser.selectors';

import CustomerReceived from '../models/CustomerReceived.model';
import SupplierReceived from '../models/SupplierReceived.model';
import MyProfile from './MyProfile';

interface Props extends ReduxProps {
  load: (token: string) => Promise<[CustomerReceived | SupplierReceived, boolean]>;
  update: (user: Customer | Supplier, id: string) => Promise<[AddCustomer | Supplier, boolean]>;
  id: string,
}

const ManageUser = (props: Props) => {
  const {
    load,
    update,
    id,
    role,
  } = props;

  const [user, setUser] = useState<CustomerReceived | SupplierReceived>();
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotifications();

  const loadUser = async () => {
    if (!id) {
      return;
    }

    try {
      const [result, isAborted] = await load(id);
      if (isAborted) {
        return;
      }
      setUser(result);
    } catch (err) {
      addNotification('error', err.messages);
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  const submit = async (userToUpdate: Customer | Supplier) => {
    try {
      setIsLoading(true);
      const [, isAborted] = await update(userToUpdate, id);
      if (isAborted) {
        return;
      }

      addNotification('success', 'Modification effectuée avec succès');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      addNotification('error', error.messages);
    }
  };

  if (!user) {
    return <Loading loading />;
  }

  return (
    <MyProfile
      user={user}
      submitForm={submit}
      role={role!}
      isLoading={isLoading}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  token: getDecodedToken(state),
  role: getCurrentUserRole(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ManageUser);
