import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import AccountProfile from 'modules/account/components/AccountProfile';
import useSubPath from 'modules/shared/hooks/useSubPath.hook';
import supplierRoutes from 'modules/suppliers/supplier.routes';
import ManageProductsRoutes from './ManageProductsRoutes';

const ManageSuppliersRoutes = () => {
  const match = useRouteMatch<{ supplierId: string }>();
  const { params, path } = match;
  const { supplierId } = params;

  return (
    <Switch>
      <Route path={`${useSubPath(supplierRoutes.PRODUCTS)}`}>
        <ManageProductsRoutes />
      </Route>
      <Route path={path}>
        <AccountProfile supplierId={supplierId} />
      </Route>
    </Switch>
  );
};

export default ManageSuppliersRoutes;
