import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SuppliersManagement from 'modules/management/components/SuppliersManagement';
import managementRoutes from 'modules/management/management.routes';
import useSubPath from 'modules/shared/hooks/useSubPath.hook';

import ManageSuppliersRoutes from './ManageSuppliersRoutes';
import CategoriesManagement from './Categories/CategoriesManagement';
import CompanyTypes from './CompanyTypes';

const ManageRoutes = () => (
  <Switch>
    <Route path={`${useSubPath(managementRoutes.SUPPLIERS)}/:supplierId`}>
      <ManageSuppliersRoutes />
    </Route>
    <Route path={useSubPath(managementRoutes.SUPPLIERS)}>
      <SuppliersManagement />
    </Route>
    <Route path={useSubPath(managementRoutes.CATEGORIES)}>
      <CategoriesManagement />
    </Route>
    <Route path={useSubPath(managementRoutes.COMPANY_TYPES)}>
      <CompanyTypes />
    </Route>
  </Switch>
);

export default ManageRoutes;
