import React from 'react';

import Appointment from 'modules/requests/models/Appointment.model';
import RadioButton from 'modules/shared/components/RadioButton';
import { toPrettyDateRange } from 'modules/requests/utils/dates.utils';

interface Props {
  appointmentProposals: Appointment[];
  onChange: (proposalId: string) => void;
}

const CustomerProposals = ({ appointmentProposals, onChange }: Props) => (
  <>
    <div className="mb-6">
      {appointmentProposals && appointmentProposals.map(({ id, startDate, endDate }) => (
        <div key={id}>
          <RadioButton
            name="radio"
            value={id}
            label={toPrettyDateRange(new Date(startDate), new Date(endDate))}
            onChange={() => onChange(id)}
          />
        </div>
      ))}
    </div>
  </>
);

export default CustomerProposals;
