import React, { useState, useEffect, useRef } from 'react';

import Section from 'modules/shared/components/Section';
import Button from 'modules/shared/components/Button';
import CompanyType from 'modules/company/models/companyType.model';
import companyTypeService from 'modules/company/services/companyType.service';
import Loading from 'modules/shared/components/Loading';
import useNotifications from 'modules/shared/hooks/useNotifications';
import Modal from 'modules/shared/components/Modal';
import CompanyCard from 'modules/company/components/CompanyCard';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

import ManageCompanyType from './ManageCompanyType';

const CompanyTypes = () => {
  const [companyTypes, setCompanyTypes] = useState<CompanyType[]>([]);
  const [companyType, setCompanyType] = useState<CompanyType | null>();
  const [editIsActive, setEditIsActive] = useState(false);
  const [deleteIsActive, setDeleteIsActive] = useState(false);
  const { addNotification } = useNotifications();

  const typeId = useRef('');
  const [loading, setLoading] = useState(false);

  const getAllCompanyTypes = useSafeApiCall(companyTypeService.getAll);
  const removeCompanyType = useSafeApiCall(companyTypeService.remove);

  const loadCompanyTypes = async () => {
    try {
      setLoading(true);
      const [result, isAborted] = await getAllCompanyTypes();
      if (isAborted) {
        return;
      }

      setCompanyTypes(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  const toggleModalManage = () => {
    setCompanyType(null);
    setEditIsActive(!editIsActive);
  };

  const toggleModalDelete = () => {
    typeId.current = '';
    setDeleteIsActive(!deleteIsActive);
  };

  useEffect(() => {
    loadCompanyTypes();
  }, []);

  const deleteSalesman = async () => {
    try {
      const [, isAborted] = await removeCompanyType(typeId.current);
      if (isAborted) {
        return;
      }

      loadCompanyTypes();
    } catch (err) {
      toggleModalDelete();
      addNotification('error', err.messages);
    }
  };

  const handleUpdateCompanyType = (type: CompanyType) => {
    toggleModalManage();
    setCompanyType(type);
  };

  const handleDeleteCompanyType = (id: string) => {
    toggleModalDelete();
    typeId.current = id!;
  };

  return (
    <Section isFullHeight isLightGrey hasAlwaysPadding>
      <Loading loading={loading}>
        {editIsActive && (
          <Modal
            title={companyType?.id ? 'Modifier' : 'Ajouter'}
            actions={[
              {
                text: 'Fermer',
                onClick: toggleModalManage,
                className: 'button is-dark',
              },
            ]}
          >
            <ManageCompanyType
              companyType={companyType}
              load={loadCompanyTypes}
              toggleManageCompanyModel={toggleModalManage}
              addNotification={addNotification}
            />
          </Modal>
        )}
        {deleteIsActive && (
          <Modal
            title="Supprimer"
            actions={[
              {
                text: 'Confirmer',
                onClick: deleteSalesman,
                className: 'button is-danger',
              },
              {
                text: 'Fermer',
                onClick: toggleModalDelete,
                className: 'button is-dark',
              },
            ]}
          >
            <p>Etes-vous sûr de vouloir supprimer?</p>
          </Modal>
        )}
        <Button
          type="button"
          value="Ajouter un type d'établissement"
          className="button is-dark"
          onClick={toggleModalManage}
        />

        <hr />

        <div className="container">
          <ul className="columns is-multiline">
            {companyTypes.length
              ? companyTypes.map((cp) => (
                <CompanyCard
                  key={cp.id}
                  companyType={cp}
                  handleDelete={handleDeleteCompanyType}
                  handleUpdate={handleUpdateCompanyType}
                />
              ))
              : (
                <div>
                  <h2 className="mb-1 subtitle">Aucun type d&apos;établissement en base de données</h2>
                  <p>
                    Cette situation peut indiquer un problème pour les utilisateurs de My Salesman :
                    aucun type d&apos;établissement n&apos;est sélectionnable lors de l&apos;
                    inscription. Veuillez ajouter des types d&apos;établissement au plus vite.
                  </p>
                </div>
              )}
          </ul>
        </div>
      </Loading>
    </Section>
  );
};

export default CompanyTypes;
