import React from 'react';
import classNames from 'classnames';

import { FieldValue } from 'modules/shared/hooks/useForm.hook';
import SelectOption from 'modules/shared/models/SelectOption.model';
import Form from 'modules/shared/components/Form';
import BelgiumMap from 'modules/salesmen/components/BelgiumMap';
import Button from 'modules/shared/components/Button';
import Input from 'modules/shared/components/Input';
import Select from 'modules/shared/components/Select';
import formStyles from 'modules/shared/components/Form/Form.module.scss';
import inputStyles from 'modules/shared/components/Input/Input.module.scss';

import ProvinceMap from '../../models/ProvinceMap.model';

import styles from './SalesmanForm.module.scss';

interface Props {
  title: string;
  provinces: ProvinceMap[];
  licenseOptions: SelectOption[];
  licensePriceOptions: SelectOption[];
  paymentMethodOptions: SelectOption[];
  price: number;
  isDisabled: boolean;
  buttonValue: string;
  fields: string[],
  fieldValues: string[],
  submit: (e: React.FormEvent<HTMLFormElement>) => void;
  onChange: (value: FieldValue, isValid: boolean, name?: string) => void;
  onZoneClick: (e: React.MouseEvent<SVGGElement, MouseEvent>) => void;
  onLicenseChange: (value: FieldValue, valid: boolean, name?: string) => void;
  handleRemoveSalesman: () => void,
}

const SalesmanForm = ({
  title,
  provinces,
  licenseOptions,
  licensePriceOptions,
  paymentMethodOptions,
  price,
  isDisabled,
  buttonValue,
  fields: [
    FIRSTNAME,
    LASTNAME,
    EMAIL,
    PHONENUMBER,
    LICENSE_ID,
    LICENSE_PRICE_ID,
    PAYMENT_METHOD_ID,
  ],
  fieldValues: [
    firstName,
    lastName,
    email,
    phoneNumber,
    licenseId,
    licensePriceId,
    paymentMethodId,
  ],
  submit,
  onChange,
  onZoneClick,
  onLicenseChange,
  handleRemoveSalesman,
}: Props) => (
  <Form title={title} submit={submit}>
    <div className={formStyles.formControls}>
      <Input
        placeholder="Nom"
        label="Nom"
        type="text"
        value={lastName}
        name={LASTNAME}
        onChange={onChange}
        required
      />

      <Input
        placeholder="Prénom"
        label="Prénom"
        type="text"
        value={firstName}
        name={FIRSTNAME}
        onChange={onChange}
        required
      />
    </div>

    <div className={formStyles.formControls}>
      <Input
        placeholder="Email"
        label="Email"
        type="email"
        value={email}
        name={EMAIL}
        onChange={onChange}
        required
      />
    </div>
    <div className={formStyles.formControls}>
      <Input
        label="Numéro de téléphone"
        placeholder="0472 47 31 28"
        delimiters={[' ']}
        type="text"
        value={phoneNumber}
        name={PHONENUMBER}
        onChange={onChange}
        required
      />
    </div>
    <div className={formStyles.formControls}>
      <div className={inputStyles.formControl}>
        <BelgiumMap
          provinces={provinces}
          onProvinceClick={onZoneClick}
        />
      </div>
    </div>
    <div className={formStyles.formControls}>
      <Select
        labelText="Licence"
        placeholder="Selectionner"
        options={licenseOptions}
        name={LICENSE_ID}
        value={licenseId || ''}
        onChange={onLicenseChange}
        required
      />

      <Select
        labelText="Durée"
        placeholder="Selectionner"
        options={licensePriceOptions}
        name={LICENSE_PRICE_ID}
        value={licensePriceId || ''}
        onChange={onChange}
        disabled={isDisabled}
        required
      />

      <Select
        labelText="Mode de paiement"
        placeholder="Selectionner"
        options={paymentMethodOptions}
        name={PAYMENT_METHOD_ID}
        value={paymentMethodId || ''}
        onChange={onChange}
        disabled={isDisabled}
        required
      />

      <div className={classNames(inputStyles.formControl, inputStyles.isEnd)}>
        {price && (
          <>
            <p className="label">Prix</p>
            <h3 className={classNames('title is-3', styles.title)}>
              {price}
              &euro;
            </h3>
          </>
        )}
      </div>
    </div>

    <div className={classNames(formStyles.formControls, formStyles.right)}>
      <div className={classNames('buttons', styles.button)}>
        <Button
          type="submit"
          className="button is-dark"
          value={buttonValue}
        />
        <Button
          type="button"
          className="button is-danger is-small"
          style={{ width: '40px' }}
          iconName="fa-trash"
          onClick={handleRemoveSalesman}
        />
      </div>
    </div>
  </Form>
);

export default SalesmanForm;
