import { Dispatch } from 'redux';

import createAction from 'modules/shared/utils/createAction.util';
import httpService from 'modules/shared/services/http.service';

import Credentials from '../models/Credentials.model';
import authenticationServices from '../services/authentication.service';
import {
  SIGN_IN,
  SIGN_IN_FAILED,
} from '../auth.actionTypes';
import authActions from './auth.actions';

const signIn = (credentials: Credentials) => async (dispatch: Dispatch) => {
  dispatch(createAction(SIGN_IN));
  try {
    const result = await authenticationServices.login(httpService)(credentials);
    dispatch(authActions.setToken(result));
  } catch (error) {
    dispatch(createAction(SIGN_IN_FAILED, error));
  }
};

export default {
  signIn,
};
