import createAction from 'modules/shared/utils/createAction.util';
import {
  SIGN_IN_SUCCESS,
  SIGN_OUT,
} from '../auth.actionTypes';

import Token from '../models/Token.model';

const setToken = (token: Token) => createAction(SIGN_IN_SUCCESS, token);

const signOut = () => createAction(SIGN_OUT);

export default {
  setToken,
  signOut,
};
