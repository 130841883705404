const CUSTOMER = '/customer';
const PROPOSALS = '/proposals';
const APPOINTMENTS = '/appointments';
const APPOINTMENT_CANCELLATION = '/appointment-cancellation';

export default {
  CUSTOMER,
  PROPOSALS,
  APPOINTMENTS,
  APPOINTMENT_CANCELLATION,
};
