const ADMINISTRATOR = 'Administrator';
const CUSTOMER = 'Customer';
const SUPPLIER = 'Supplier';
const SALESMAN = 'Salesman';

export default {
  ADMINISTRATOR,
  CUSTOMER,
  SUPPLIER,
  SALESMAN,
};
