import React from 'react';

import CompanyType from 'modules/company/models/companyType.model';

import Button from '../../shared/components/Button';
import Card from '../../shared/components/Card';
import IconText from '../../shared/components/IconText';

export interface Props {
  companyType: CompanyType;
  handleDelete: (id: string) => void;
  handleUpdate: (type: CompanyType) => void;
}

const CompanyCard = ({ companyType, handleDelete, handleUpdate }: Props) => (
  <li className="column is-full-tablet is-one-third-desktop">
    <div className="card is-fullheight">
      <Card title={companyType.label}>
        <IconText iconName="fa-info-circle">
          {companyType.type}
        </IconText>
      </Card>
      <div className="card-footer">
        <Button
          style={{ margin: '10px' }}
          value="Éditer"
          className="button is-dark is-small is-fullwidth"
          iconName="fa-edit"
          type="button"
          onClick={() => handleUpdate(companyType)}
        />
        <Button
          className="button is-danger is-small"
          style={{ margin: '10px', width: '100px' }}
          type="button"
          iconName="fa-trash"
          onClick={() => handleDelete(companyType.id!)}
        />
      </div>
    </div>
  </li>
);
export default CompanyCard;
