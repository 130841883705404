import ApiError from 'modules/shared/models/ApiError.model';
import HttpService from 'modules/shared/models/HttpService.model';
import { makeThrowError } from 'modules/shared/utils/apiErrors.utils';
import errorCodes from 'modules/shared/constants/errorCodes.constants';

import CompanyType from '../models/companyType.model';

const mapError = (error: ApiError) => {
  switch (error.code) {
    case '401':
      return 'Votre session à expirée';
    case errorCodes.ITEM_NOT_FOUND:
      return 'Le type d\'établissement désiré est introuvable';
    case errorCodes.ITEMS_NOT_FOUND:
      return 'Types d\'établissement introuvables';
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const endpoint = '/company-types';

const getAll = (service: HttpService) => (): Promise<CompanyType[]> => service
  .get<CompanyType[]>(endpoint)
  .catch(throwError);

const add = (service: HttpService) => (
  companyType: CompanyType,
): Promise<CompanyType> => service
  .post<CompanyType>(endpoint, companyType)
  .catch(throwError);

const update = (service: HttpService) => (
  companyTypeId: string,
  companyType: CompanyType,
): Promise<void> => service
  .put<void>(`${endpoint}/${companyTypeId}`, companyType)
  .catch(throwError);

const remove = (service: HttpService) => (companyTypeId: string): Promise<void> => service
  .remove(`${endpoint}/${companyTypeId}`)
  .catch(throwError);

export default {
  getAll,
  update,
  add,
  remove,
};
