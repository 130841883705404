import React from 'react';

import styles from './Logo.module.scss';

interface Props {
  width: number,
  height: number,
}

const Logo = ({ width, height } : Props) => (
  <svg
    className={styles.img}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 388.99 388.99"
    width={width}
    height={height}
  >
    <title>My Salesman</title>
    <g id="Calque_4" data-name="Calque 4">
      <circle className={styles.cls1} cx="194.49" cy="194.49" r="188.54" />
      <path className={styles.cls2} d="M132.64,166s-12.17-20.28-12.17-36.49,8.11-24.33,16.22-28.39S144.8,97.07,148.86,89s0-24.33,32.43-28.38,85.15,16.22,93.26,64.87c0,8.11-8.11,4.06-8.11,8.11s-4.05,36.49-4.05,36.49-2,2-2-10.14-4.06-28.38-4.06-28.38-28.38,0-40.54-16.22c0,0-8.11-4-16.22,0s-24.33,4.06-36.49,0-28.38,4.06-28.38,24.33V168S134.67,172.08,132.64,166Z" transform="translate(-5.05 -5.97)" />
      <polygon className={styles.cls2} points="125.31 155.87 128.38 162.13 129.62 158 125.31 155.87" />
      <circle className={styles.cls3} cx="161.04" cy="163.07" r="23.31" />
      <path className={styles.cls4} d="M189.4,166.71a9.14,9.14,0,0,1,18.25,0" transform="translate(-5.05 -5.97)" />
      <circle className={styles.cls3} cx="225.92" cy="163.07" r="23.31" />
      <path className={styles.cls2} d="M247,209s-11,18.81-24.22,6.7-25.28-1.22-25.28-1.22h-1.79s-12.05-10.88-25.27,1.22-24.22-6.7-24.22-6.7-2.55-2.93-2.69,1.37,5.13,23,22.88,26.76a33.61,33.61,0,0,0,29.3-7.75h1.79a33.62,33.62,0,0,0,29.31,7.75c17.75-3.74,23-22.46,22.88-26.76S247,209,247,209Z" transform="translate(-5.05 -5.97)" />
      <path className={styles.cls5} d="M240.16,300.72v1.4a9.67,9.67,0,0,1,5.8,6.52c1.16,4.9-1.73,17-15,19.46s-27.19-21.76-27.19-21.76h-12.3s-13.92,24.21-27.19,21.76-16.16-14.56-15-19.46a9.64,9.64,0,0,1,5.8-6.52v-4a9.67,9.67,0,0,1-5.8-6.52c-1.17-4.9,1.73-17,15-19.46s27.19,21.76,27.19,21.76h12.3S217.7,269.71,231,272.16s16.16,14.56,15,19.46a9.69,9.69,0,0,1-5.8,6.52v2.58Z" transform="translate(-5.05 -5.97)" />
      <line className={styles.cls6} x1="138.49" y1="164.37" x2="128.79" y2="164.37" />
      <line className={styles.cls7} x1="257.34" y1="164.21" x2="250.42" y2="164.21" />
    </g>
  </svg>
);

export default Logo;
