import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import RootState from 'modules/shared/types/reducers/RootState';
import { getCurrentUserRole } from 'modules/shared/selectors/currentUser.selectors';
import customerServices from 'modules/customers/services/customer.service';
import rolesConstants from 'modules/shared/constants/roles.constants';
import suppliersService from 'modules/suppliers/services/suppliers.service';
import { getUserId } from 'modules/authentication/selectors/token.selectors';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

import ManageUser from './ManageUser';

interface Props extends ReduxProps {
  supplierId?: string;
}

const AccountProfile = (props: Props) => {
  const {
    role,
    tokenId,
    supplierId,
  } = props;

  const getCustomerById = useSafeApiCall(customerServices.getById);
  const updateCustomer = useSafeApiCall(customerServices.updateCustomer);

  const getSupplierById = useSafeApiCall(suppliersService.getById);
  const updateSupplier = useSafeApiCall(suppliersService.updateSupplier);

  switch (role) {
    case rolesConstants.CUSTOMER:
      return (
        <ManageUser
          load={getCustomerById}
          update={updateCustomer}
          id={tokenId!}
        />
      );
    case rolesConstants.SUPPLIER:
      return (
        <ManageUser
          load={getSupplierById}
          update={updateSupplier}
          id={tokenId!}
        />
      );
    case rolesConstants.ADMINISTRATOR:
      return (
        <ManageUser
          load={getSupplierById}
          update={updateSupplier}
          id={supplierId!}
        />
      );
    default:
      return null;
  }
};

const mapStateToProps = (state: RootState) => ({
  role: getCurrentUserRole(state),
  tokenId: getUserId(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AccountProfile);
