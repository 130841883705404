import React from 'react';
import {
  Calendar as ReactBigCalendar,
  dateFnsLocalizer,
  EventPropGetter,
} from 'react-big-calendar';
import {
  format,
  parse,
  startOfWeek,
  getDay,
} from 'date-fns';
import { fr } from 'date-fns/locale';

import CalendarEvent from '../models/CalendarEvent.model';

const locales = {
  fr,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const messages = {
  today: 'Aujourd\'hui',
  previous: 'Précédent',
  next: 'Suivant',
  month: 'Mois',
  week: 'Semaine',
  day: 'Jour',
  agenda: 'Planning',
};

const eventStyleGetter: EventPropGetter<CalendarEvent> = (event) => {
  let bgColor;
  switch (event.status) {
    case 'ACCEPTED':
      bgColor = 'hsl(141, 53%,  53%)';
      break;
    case 'CANCELED':
      bgColor = 'hsl(14,  100%, 53%)';
      break;
    default:
      bgColor = 'hsl(204, 71%,  53%)';
      break;
  }

  return {
    style: {
      background: bgColor,
    },
  };
};

interface Props {
  events: CalendarEvent[];
  onClickEvent?: (event: CalendarEvent) => void;
}

const Calendar = ({ events, onClickEvent }: Props) => (
  <ReactBigCalendar
    localizer={localizer}
    events={events}
    defaultView="month"
    showMultiDayTimes
    startAccessor="start"
    endAccessor="end"
    culture="fr"
    messages={messages}
    onSelectEvent={onClickEvent}
    eventPropGetter={eventStyleGetter}
    style={{ height: 500 }}
  />
);

export default Calendar;
