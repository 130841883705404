import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { parse } from 'query-string';

import Section from 'modules/shared/components/Section';
import Button from 'modules/shared/components/Button';
import Product from 'modules/products/models/Product.model';
import AddProduct from 'modules/products/models/AddProduct.model';
import Modal from 'modules/shared/components/Modal';
import RootState from 'modules/shared/types/reducers/RootState';
import { getCurrentUserRole } from 'modules/shared/selectors/currentUser.selectors';
import rolesConstants from 'modules/shared/constants/roles.constants';
import managementRoutes from 'modules/management/management.routes';
import useNotifications from 'modules/shared/hooks/useNotifications';
import ProductCard from 'modules/products/components/ProductCard';
import SearchInput from 'modules/shared/components/SearchInput';

import supplierRoutes from '../supplier.routes';

interface Props extends ReduxProps {
  products: Product[];
  onProductRemove: (productId: string) => void;
  onProductUpdate: (product: AddProduct, productId: string) => void;
  supplierId: string;
}

const Products = (props: Props) => {
  const {
    products,
    onProductRemove,
    onProductUpdate,
    supplierId,
    role,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const productId = useRef<string>('');
  const { action } = parse(location.search);
  const { addNotification } = useNotifications();
  const [isActive, setIsActive] = useState(false);
  const [filter, setFilter] = useState('');

  const goToAddProduct = () => {
    if (role === rolesConstants.ADMINISTRATOR) {
      history.push({
        pathname: `${managementRoutes.MANAGE}${managementRoutes.SUPPLIERS}/${supplierId}${supplierRoutes.PRODUCTS}${supplierRoutes.ADD_PRODUCT}`,
      });
    } else {
      history.push({
        pathname: `${managementRoutes.MANAGE}${supplierRoutes.PRODUCTS}${supplierRoutes.ADD_PRODUCT}`,
      });
    }
  };

  const gotToEditProduct = (product: Product) => {
    if (role === rolesConstants.ADMINISTRATOR) {
      history.push({
        pathname: `${managementRoutes.MANAGE}${managementRoutes.SUPPLIERS}/${supplierId}${supplierRoutes.PRODUCTS}/${product.id}/edit`,
      });
    } else {
      history.push({
        pathname: `${managementRoutes.MANAGE}${supplierRoutes.PRODUCTS}/${product.id}${supplierRoutes.EDIT_PRODUCT}`,
      });
    }
  };

  const removeNotifFromUrl = () => {
    setTimeout(() => {
      if (role === rolesConstants.ADMINISTRATOR) {
        history.push({
          pathname: `${managementRoutes.MANAGE}${managementRoutes.SUPPLIERS}/${supplierId}${supplierRoutes.PRODUCTS}`,
        });
      } else {
        history.push({
          pathname: `${managementRoutes.MANAGE}${supplierRoutes.PRODUCTS}`,
        });
      }
    }, 4000);
  };

  const handleChange = (key: string) => {
    setFilter(key);
  };

  useEffect(() => {
    if (action === 'edited') {
      addNotification('success', 'Le produit a bien été modifié');
      removeNotifFromUrl();
    } else if (action === 'added') {
      addNotification('success', 'Le produit a bien été ajouté');
      removeNotifFromUrl();
    }
  }, []);

  const toggleModal = () => {
    productId.current = '';
    setIsActive(!isActive);
  };

  const deleteProduct = () => {
    if (productId.current) {
      onProductRemove(productId.current);
    }

    toggleModal();
  };

  const handleDeleteProduct = (id: string) => {
    toggleModal();
    productId.current = id;
  };

  const filterWithName = useMemo(() => products
    .filter((product) => product.name.toLowerCase()
      .includes(filter.toLowerCase())), [products, filter]);

  return (
    <Section isFullHeight isLightGrey hasAlwaysPadding>
      {isActive && (
        <Modal
          title="Confirmation"
          actions={[
            {
              text: 'Supprimer',
              onClick: deleteProduct,
              className: 'button is-danger',
            },
            {
              text: 'Annuler',
              onClick: toggleModal,
              className: 'button is-dark',
            },
          ]}
        >
          <p>Etes-vous sur de vouloir supprimer cet article?</p>
        </Modal>
      )}
      <Button
        type="button"
        value="Ajouter un produit"
        className="button is-dark"
        onClick={goToAddProduct}
      />

      <hr />
      <div className="container">
        <SearchInput
          placeholder="Rechercher un produit"
          onChanged={handleChange}
        />
        <ul className="columns is-multiline">
          {products.length
            ? filterWithName.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                goToEditProduct={gotToEditProduct}
                goToDeleteProduct={handleDeleteProduct}
                onProductUpdate={onProductUpdate}
              />
            ))
            : (

              <div>
                <h2 className="mb-1 subtitle">Aucun produit enregistré</h2>
                <p>
                  Ce fournisseur ne propose pas encore de produits sur My Salesman.
                  Veuillez réessayer plus tard.
                </p>
              </div>
            )}
        </ul>
      </div>
    </Section>
  );
};

const mapStateToProps = (state: RootState) => ({
  role: getCurrentUserRole(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Products);
