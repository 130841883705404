import React from 'react';
import classNames from 'classnames';

import ContactRequest from 'modules/requests/models/ContactRequest.model';

import styles from './RequestPanel.module.scss';
import RequestItems from '../RequestItems';

interface Props {
  requests: ContactRequest[];
  isSelectedRequest: (request: ContactRequest) => void;
  setSelectedRequest: (request: ContactRequest) => void;
}

const RequestPanel = ({ requests, isSelectedRequest, setSelectedRequest }: Props) => (
  <nav className={classNames('panel', styles.panel)}>
    <p className={classNames('panel-heading', styles.heading)}>
      Demandes de passage
    </p>
    <div className="panel-block">
      <p className="control has-icons-left">
        <input className="input" type="text" placeholder="Search" />
        <span className="icon is-left">
          <i className="fas fa-search" aria-hidden="true" />
        </span>
      </p>
    </div>

    <RequestItems
      requests={requests}
      isSelectedRequest={isSelectedRequest}
      setSelectedRequest={setSelectedRequest}
    />
  </nav>
);

export default RequestPanel;
