import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import AddProduct from 'modules/products/components/AddProduct';
import useSubPath from 'modules/shared/hooks/useSubPath.hook';
import RootState from 'modules/shared/types/reducers/RootState';
import { getUserId } from 'modules/authentication/selectors/token.selectors';

import supplierRoutes from '../supplier.routes';
import SupplierProducts from './SupplierProducts';
import ManageProductRoute from './ManageProductRoute';

const ProductRoutes = ({ supplierId }: ReduxProps) => (
  <Switch>
    <Route path={useSubPath(supplierRoutes.ADD_PRODUCT)}>
      <AddProduct supplierId={supplierId!} />
    </Route>
    <Route path={useSubPath('/:productId')}>
      <ManageProductRoute supplierId={supplierId!} />
    </Route>
    <Route path={useSubPath('/')}>
      <SupplierProducts supplierId={supplierId!} />
    </Route>
  </Switch>
);

const mapStateToProps = (state: RootState) => ({
  supplierId: getUserId(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ProductRoutes);
