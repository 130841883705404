import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';

import Input from 'modules/shared/components/Input';
import Form from 'modules/shared/components/Form';
import formStyles from 'modules/shared/components/Form/Form.module.scss';

import Button from 'modules/shared/components/Button';
import RootState from 'modules/shared/types/reducers/RootState';
import useQuickForm from 'modules/shared/hooks/useQuickForm.hook';
import Credentials from 'modules/authentication/models/Credentials.model';
import useNotifications from '../../shared/hooks/useNotifications';
import signInActions from '../actions/signIn.actions';

interface Props extends ReduxProps {
  labelStyle?: string;
}

const SignInForm = (props: Props) => {
  const {
    labelStyle,
    signIn,
    error,
  } = props;

  const {
    fields: [EMAIL, PASSWORD],
    getFieldValue,
    handleChange,
    isValid,
  } = useQuickForm(['', '']);

  const email = getFieldValue<string>(EMAIL);
  const password = getFieldValue<string>(PASSWORD);
  const [isLoading, setIsLoading] = useState(false);

  const { addNotification } = useNotifications();

  const onChanged = (value: string, valid: boolean, name?: string) => {
    handleChange(value, valid, name);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    if (isValid) {
      const credentials: Credentials = {
        username: email,
        password,
      };
      signIn(credentials);
    }
  };

  useEffect(() => {
    if (error) {
      addNotification('error', 'Adresse email ou mot de passe incorrect');
      setIsLoading(false);
    }
  }, [error]);

  return (
    <>
      <Form
        title="Se connecter"
        submit={onSubmit}
      >
        <div className={formStyles.formControls}>
          <Input
            label="Adresse email"
            type="text"
            labelClassName={labelStyle}
            name={EMAIL}
            value={email}
            placeholder="example@example.com"
            className="is-rounded"
            onChange={onChanged}
            disabled={isLoading}
            required
          />
        </div>

        <div className={formStyles.formControls}>
          <Input
            label="Mot de passe"
            type="password"
            labelClassName={labelStyle}
            name={PASSWORD}
            value={password}
            placeholder="Mot de passe"
            className="is-rounded"
            onChange={onChanged}
            disabled={isLoading}
            required
          />
        </div>

        <div className={formStyles.formControls}>
          <Button
            type="submit"
            value="Se connecter"
            className={classNames(
              'button is-primary is-fullwidth',
              { 'is-loading': isLoading },
            )}
          />
        </div>
      </Form>
    </>
  );
};

const mapDispatchToProps = {
  signIn: signInActions.signIn,
};

const mapStateToProps = ({ token, auth }: RootState) => ({
  hasToken: !!token.token,
  error: auth.error,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SignInForm);
