import { stringify } from 'query-string';

import ApiError from 'modules/shared/models/ApiError.model';
import HttpService from 'modules/shared/models/HttpService.model';
import { makeThrowError } from 'modules/shared/utils/apiErrors.utils';
import errorCodes from 'modules/shared/constants/errorCodes.constants';

import Vat from '../models/Vat.model';

const mapError = (error: ApiError) => {
  switch (error.code) {
    case errorCodes.ITEM_NOT_FOUND:
      return 'Ce numéro de TVA est introuvable';
    case '401':
      return 'Votre session à expirée';
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const endpoint = '/vat';

const getVatInfo = (service: HttpService) => (
  vatNumber: string,
): Promise<Vat> => service
  .get<Vat>(`${endpoint}?${stringify({ vatNumber })}`)
  .catch(throwError);

export default {
  getVatInfo,
};
