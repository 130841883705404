import React, { useEffect, useState } from 'react';

import productsService from 'modules/products/services/products.service';
import Product from 'modules/products/models/Product.model';
import Products from 'modules/suppliers/components/Products';
import Loading from 'modules/shared/components/Loading';
import useNotifications from 'modules/shared/hooks/useNotifications';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';
import AddProduct from 'modules/products/models/AddProduct.model';

interface Props {
  supplierId: string;
}

const SupplierProducts = ({ supplierId }: Props) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);

  const { addNotification } = useNotifications();

  const getUserProducts = useSafeApiCall(productsService.getProductByUser);
  const deleteProduct = useSafeApiCall(productsService.deleteProduct);
  const updateProduct = useSafeApiCall(productsService.updateProduct);

  const loadSupplierProduct = async () => {
    try {
      setLoading(true);
      const [result, isAborted] = await getUserProducts(supplierId, false);
      if (isAborted) {
        return;
      }

      setProducts(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  const removeProduct = async (productId: string) => {
    const [, isAborted] = await deleteProduct(productId);
    if (isAborted) {
      return;
    }

    loadSupplierProduct();
  };

  const onProductUpdate = async (product: AddProduct, productId: string) => {
    const [result, isAborted] = await updateProduct(product, productId);
    if (isAborted) {
      return;
    }

    setProducts(products.map((p) => ((p.id === result.id) ? result : p)));
  };

  useEffect(() => {
    loadSupplierProduct();
  }, []);

  return (
    <Loading loading={loading}>
      <Products
        products={products}
        onProductRemove={removeProduct}
        supplierId={supplierId}
        onProductUpdate={onProductUpdate}
      />
    </Loading>
  );
};

export default SupplierProducts;
