import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import NavLink from 'modules/shared/components/NavLink';
import managementRoutes from 'modules/management/management.routes';
import supplierRoutes from 'modules/suppliers/supplier.routes';
import { getCurrentUserRole, getIsConnected } from 'modules/shared/selectors/currentUser.selectors';
import RootState from 'modules/shared/types/reducers/RootState';
import authenticationRoutes from 'modules/authentication/authentication.routes';
import accountRoutes from 'modules/account/account.routes';
import rolesConstants from 'modules/shared/constants/roles.constants';
import authActions from 'modules/authentication/actions/auth.actions';
import { getUserId, getUserEmail } from 'modules/authentication/selectors/token.selectors';
import registrationRoutes from 'modules/registration/registration.routes';
import salesmanRoutes from 'modules/salesmen/salesman.routes';
import customerRoutes from 'modules/customers/customer.routes';
import searchRoutes from 'modules/search/components/search.routes';
import Logo from 'modules/search/components/Logo';

const Navbar = (props: ReduxProps) => {
  const {
    isConnected,
    role,
    signOut,
    email,
  } = props;

  const location = useLocation();

  const [nav, setNav] = useState<boolean>(false);

  const navBar = classNames({
    'is-active': nav,
  });

  useEffect(() => {
    setNav(false);
  }, [location]);

  const renderAdmin = () => (
    <>
      <NavLink linkName="Fournisseurs" to={`${managementRoutes.MANAGE}${managementRoutes.SUPPLIERS}`} />
      <NavLink linkName="Catégories" to={`${managementRoutes.MANAGE}${managementRoutes.CATEGORIES}`} />
      <NavLink linkName="Types d'établissement" to={`${managementRoutes.MANAGE}${managementRoutes.COMPANY_TYPES}`} />

    </>
  );

  const renderCustomer = () => (
    <>
      <NavLink linkName="Mon profil" to={accountRoutes.ACCOUNT_PROFILE} />
      <NavLink linkName="Mon agenda" to={customerRoutes.APPOINTMENTS} />
    </>
  );

  const renderSalesman = () => (
    <>
      <NavLink linkName="Mes demandes" to={salesmanRoutes.REQUESTS} />
      <NavLink linkName="Mon agenda" to={salesmanRoutes.AGENDA} />
    </>
  );

  const renderSupplier = () => (
    <>
      <NavLink linkName="Mes produits" to={`${managementRoutes.MANAGE}${supplierRoutes.PRODUCTS}`} />
      <NavLink linkName="Mes représentants" to={`${managementRoutes.MANAGE}${supplierRoutes.SALESMEN}`} />
      <NavLink linkName="Demandes" to={`${managementRoutes.MANAGE}${supplierRoutes.REQUESTS}`} />
      <NavLink linkName="Mon profil" to={accountRoutes.ACCOUNT_PROFILE} />
    </>
  );

  const renderStart = () => {
    if (!isConnected) {
      return null;
    }
    switch (role) {
      case rolesConstants.ADMINISTRATOR:
        return renderAdmin();
      case rolesConstants.CUSTOMER:
        return renderCustomer();
      case rolesConstants.SUPPLIER:
        return renderSupplier();
      case rolesConstants.SALESMAN:
        return renderSalesman();
      default:
        return null;
    }
  };

  const renderEnd = () => {
    if (isConnected) {
      return (
        <div className="navbar-item has-dropdown is-hoverable">
          <a className="navbar-link">
            {email}
          </a>
          <div className="navbar-dropdown is-boxed">
            <a onClick={signOut} className="navbar-item">
              Se déconnecter
            </a>
          </div>
        </div>
      );
    }

    return (
      <>
        <NavLink linkName="Inscription" to={registrationRoutes.CHOOSE_SIGN_UP} />
        <NavLink linkName="Connexion" to={authenticationRoutes.SIGN_IN} />
      </>
    );
  };

  const toggleMenu = () => {
    setNav(!nav);
  };

  return (
    <nav
      className="navbar is-dark"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link
          to={searchRoutes.MAIN_PAGE}
          style={{ paddingTop: 6, paddingLeft: 6, paddingRight: 8 }}
        >
          <div>
            <Logo width={48} height={48} />
          </div>
        </Link>

        <a
          onClick={toggleMenu}
          className={`navbar-burger burger ${navBar}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>

      <div
        id="navbarBasicExample"
        className={`navbar-menu ${navBar}`}
      >
        <div className="navbar-start">
          {renderStart()}
        </div>
        <div className="navbar-end">
          {renderEnd()}
        </div>
      </div>
    </nav>
  );
};

const mapDispatchToProps = {
  signOut: authActions.signOut,
};

const mapStateToProps = (state: RootState) => ({
  isConnected: getIsConnected(state),
  email: getUserEmail(state),
  id: getUserId(state),
  role: getCurrentUserRole(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Navbar);
