import React, {
  useEffect,
  useImperativeHandle,
  Ref,
  forwardRef,
  useState,
  useMemo,
} from 'react';
import { connect, ConnectedProps } from 'react-redux';

import Input from 'modules/shared/components/Input';
import useQuickForm from 'modules/shared/hooks/useQuickForm.hook';
import Select from 'modules/shared/components/Select';
import Category from 'modules/products/models/Category.model';
import { mapCategoryToSelectOption } from 'modules/shared/mappers/filters.mappers';
import AddCategory from 'modules/products/models/AddCategory.model';

import * as categoryAction from '../../category.actions';

interface Props extends ReduxProps {
  category?: Category | null;
  categories: Category[];
  manageCat: (category: AddCategory, id?: string) => Promise<[Category, boolean]>;
}

export interface ManageCategoryRef {
  submit: () => void;
}

const ManageCategory = (props: Props, ref: Ref<ManageCategoryRef>) => {
  const {
    categories,
    category,
    manageCat,
    loadCat,
  } = props;

  const {
    fields: [
      LABEL,
      PARENT,
    ],
    isValid,
    getFieldValue,
    handleChange,
  } = useQuickForm(['', { value: null, isValid: true }]);

  const label = getFieldValue<string>(LABEL);
  const parent = getFieldValue<string>(PARENT);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (category) {
      handleChange(category.label, true, LABEL);
      if (category.parent) {
        handleChange(category.parent.id, true, PARENT);
      }
    }
  }, [category]);

  const submit = async () => {
    if (!isValid) {
      return;
    }

    const cat: AddCategory = {
      id: category?.id ? category.id : '',
      label,
      parentId: parent,
    };

    try {
      const [, isAborted] = await manageCat(cat, category?.id);
      if (isAborted) {
        return;
      }

      loadCat();
    } catch (err) {
      setError(err.messages);
    }
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const categoryOptions = useMemo(() => categories
    .filter((c) => c.label !== label).map(mapCategoryToSelectOption), [categories]);

  return (
    <form>
      <div className="column is-12">
        <Input placeholder="Label" name={LABEL} value={label} onChange={handleChange} required />
      </div>
      <div className="column is-12">
        <Select
          name={PARENT}
          options={categoryOptions}
          onChange={handleChange}
          placeholder="Selectionner un parent s'il y en a un"
          value={parent || ''}
        />
      </div>
      <div>
        {error}
      </div>
    </form>
  );
};

const mapDispatchToProps = {
  loadCat: categoryAction.loadCategories,
};

const connector = connect(null, mapDispatchToProps, null, { forwardRef: true });
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(forwardRef(ManageCategory));
