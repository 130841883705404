import React from 'react';

import Category from 'modules/products/models/Category.model';
import Button from 'modules/shared/components/Button';
import Card from 'modules/shared/components/Card';
import IconText from 'modules/shared/components/IconText';

export interface Props {
  category: Category;
  handleEdit: (category: Category) => void;
}

const CategoryCard = ({ category, handleEdit }: Props) => {
  const renderCategoryParent = (cat: Category) => {
    if (cat.parent) {
      return (
        <IconText iconName="fa-chevron-up">
          {cat.parent!.label}
        </IconText>
      );
    }

    return null;
  };
  return (
    <li className="column is-full-tablet is-one-third-desktop">
      <div className="card is-fullheight">
        <Card
          title={category.label}
          rightElement={renderCategoryParent(category)!}
        />
        <div className="card-footer">
          <Button
            style={{ margin: '10px' }}
            value="Éditer"
            className="button is-dark is-small is-fullwidth"
            iconName="fa-edit"
            type="button"
            onClick={() => handleEdit(category)}
          />
        </div>
      </div>
    </li>
  );
};
export default CategoryCard;
