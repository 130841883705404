import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import searchRoutes from 'modules/search/components/search.routes';

import Supplier from 'modules/products/models/Supplier.model';

import suppliersService from 'modules/suppliers/services/suppliers.service';

import Input from 'modules/shared/components/Input';

import useNotifications from 'modules/shared/hooks/useNotifications';
import useQuickForm from 'modules/shared/hooks/useQuickForm.hook';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

import SignUp from './SignUp';

const SupplierSignUp = () => {
  const history = useHistory();

  const {
    fields: [CONTACT],
    isValid,
    handleChange,
    getFieldValue,
  } = useQuickForm(['']);

  const contact = getFieldValue<string>(CONTACT);

  const [isLoading, setLoading] = useState(false);

  const { addNotification } = useNotifications();

  const addSupplier = useSafeApiCall(suppliersService.addSupplier);

  const renderContactField = () => (
    <Input
      label="Département de contact"
      placeholder="Contact"
      name={CONTACT}
      onChange={handleChange}
      value={contact}
    />
  );

  const createSupplier = async (supplier: Supplier) => {
    if (!isValid) {
      return;
    }

    try {
      setLoading(true);
      const [, isAborted] = await addSupplier({ ...supplier, contact });
      if (isAborted) {
        return;
      }

      history.push({
        pathname: searchRoutes.MAIN_PAGE,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  return (
    <SignUp
      submit={createSupplier}
      renderAdditionalFields={renderContactField}
      buttonValue="S'inscrire"
      title="S'inscrire"
      isLoading={isLoading}
    />
  );
};

export default SupplierSignUp;
