import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import supplierRoutes from 'modules/suppliers/supplier.routes';

import useNotifications from 'modules/shared/hooks/useNotifications';
import Loading from 'modules/shared/components/Loading';
import Section from 'modules/shared/components/Section';

import rolesConstants from 'modules/shared/constants/roles.constants';
import { connect, ConnectedProps } from 'react-redux';
import managementRoutes from 'modules/management/management.routes';
import RootState from 'modules/shared/types/reducers/RootState';
import { getCurrentUserRole } from 'modules/shared/selectors/currentUser.selectors';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

import Product from '../models/Product.model';
import ManageProduct from '../ManageProduct';
import productsService from '../services/products.service';
import AddProductModel from '../models/AddProduct.model';

interface Props extends ReduxProps {
  productId: string;
  supplierId: string;
}

const EditProduct = ({ productId, supplierId, role }: Props) => {
  const { addNotification } = useNotifications();

  const history = useHistory();

  const [product, setProduct] = useState<Product>();
  const [loading, setLoading] = useState(false);
  const [processInProgress, setProcessInProgress] = useState(false);

  const getProduct = useSafeApiCall(productsService.getProduct);
  const putProduct = useSafeApiCall(productsService.updateProduct);
  const uploadProductImage = useSafeApiCall(productsService.uploadImage);

  const loadProduct = async () => {
    try {
      setLoading(true);
      const [result, isAborted] = await getProduct(productId, false);
      if (isAborted) {
        return;
      }

      setProduct(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  useEffect(() => {
    loadProduct();
  }, []);

  const goToProductTable = () => {
    if (rolesConstants.ADMINISTRATOR === role) {
      history.push({
        pathname: `${managementRoutes.MANAGE}${managementRoutes.SUPPLIERS}/${supplierId}${managementRoutes.PRODUCTS}`,
        search: 'action=edited',
      });
    } else {
      history.push({
        pathname: `${managementRoutes.MANAGE}${supplierRoutes.PRODUCTS}`,
        search: 'action=edited',
      });
    }
  };

  const updateProduct = async (productToUpdate: AddProductModel, image?: File) => {
    if (!product) {
      return;
    }
    try {
      setProcessInProgress(true);
      const [result, isAborted] = await putProduct(productToUpdate, product.id);
      if (isAborted) {
        return;
      }

      if (image?.name) {
        const [, isUploadAborted] = await uploadProductImage(result.id, image);

        if (isUploadAborted) return;
      }

      setProcessInProgress(false);
      goToProductTable();
    } catch (err) {
      setProcessInProgress(false);
      addNotification('error', err.messages);
    }
  };

  return (
    <Section isFullHeight isLightGrey>
      <Loading loading={loading}>
        <ManageProduct
          title="Éditer un produit"
          buttonValue="Éditer"
          submitForm={updateProduct}
          prod={product}
          supplierId={supplierId}
          processInProgress={processInProgress}
          addNotification={addNotification}
        />
      </Loading>
    </Section>
  );
};

const mapStateToProps = (state: RootState) => ({
  role: getCurrentUserRole(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(EditProduct);
