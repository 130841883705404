import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SupplierSignUp from 'modules/registration/components/SupplierSignUp';
import CustomerSignUp from 'modules/registration/components/CustomerSignUp';
import ChooseSignUp from 'modules/registration/components/ChooseSignUp';
import registrationRoutes from 'modules/registration/registration.routes';
import useSubPath from 'modules/shared/hooks/useSubPath.hook';

const SignUpRoutes = () => (
  <Switch>
    <Route path={useSubPath(registrationRoutes.SUPPLIER_SIGN_UP)}>
      <SupplierSignUp />
    </Route>
    <Route path={useSubPath(registrationRoutes.CUSTOMER_SIGN_UP)}>
      <CustomerSignUp />
    </Route>
    <Route path={registrationRoutes.CHOOSE_SIGN_UP}>
      <ChooseSignUp />
    </Route>
  </Switch>
);

export default SignUpRoutes;
