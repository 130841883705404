import React from 'react';
import { NavLink as Nav } from 'react-router-dom';

interface Props {
  to: string;
  linkName: string;
}

const NavLink = (props: Props) => {
  const {
    to,
    linkName,
  } = props;

  return (
    <Nav activeClassName="is-active" to={to} className="navbar-item">
      {linkName}
    </Nav>
  );
};

export default NavLink;
