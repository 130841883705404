import ApiError from 'modules/shared/models/ApiError.model';
import HttpService from 'modules/shared/models/HttpService.model';
import { makeThrowError } from 'modules/shared/utils/apiErrors.utils';
import errorCodes from 'modules/shared/constants/errorCodes.constants';

import LicensePrice from '../models/LicensePrice.model';

const mapError = (error: ApiError) => {
  switch (error.code) {
    case '401':
      return 'Votre session à expirée';
    case errorCodes.ITEM_NOT_FOUND:
      return 'Le prix de la licence désirée est introuvable';
    case errorCodes.ITEMS_NOT_FOUND:
      return 'Prix des licences introuvables';
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const endpoint = '/license-prices';

const getLicensePrices = (service: HttpService) => (
  licenseId: string,
): Promise<LicensePrice[]> => service
  .get<LicensePrice[]>(`${endpoint}/${licenseId}`)
  .catch(throwError);

export default {
  getLicensePrices,
};
