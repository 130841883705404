import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';

import Loading from 'modules/shared/components/Loading';
import useNotifications from 'modules/shared/hooks/useNotifications';
import Section from 'modules/shared/components/Section';
import Button from 'modules/shared/components/Button';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';
import CustomerAppointmentDetails from 'modules/customers/components/CustomerAppointmentDetails';
import SalesmanAppointmentDetails from 'modules/salesmen/components/SalesmanAppointmentDetails';
import rolesConstants from 'modules/shared/constants/roles.constants';

import appointmentService from '../../services/appointment.service';
import UpdateAppointmentToken from '../../models/UpdateAppointmentToken.model';
import Appointment from '../../models/Appointment.model';

import styles from './CancelAppointment.module.scss';

interface Props {
  role: string;
}

const CancelAppointment = ({ role }: Props) => {
  const location = useLocation();

  const [token, setToken] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [appointment, setAppointment] = useState<Appointment>();

  const { addNotification } = useNotifications();

  const cancelAppointmentCustomer = useSafeApiCall(appointmentService.cancelCustomerAppointment);

  const cancelAppointmentSalesman = useSafeApiCall(appointmentService.cancelSalesmanAppointment);

  const getAppointmentById = useSafeApiCall(appointmentService.getById);

  const handleCancelAppointmentCustomer = async () => {
    try {
      setLoading(true);
      const updateAppointmentToken: UpdateAppointmentToken = { token: token! };
      const [result, isAborted] = await cancelAppointmentCustomer(
        appointment!.id,
        updateAppointmentToken,
      );
      if (isAborted) {
        return;
      }
      setAppointment(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  const handleCancelAppointmentSalesman = async () => {
    try {
      setLoading(true);
      const updateAppointmentToken: UpdateAppointmentToken = { token: token! };
      const [result, isAborted] = await cancelAppointmentSalesman(
        appointment!.id,
        updateAppointmentToken,
      );
      if (isAborted) {
        return;
      }
      setAppointment(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  const handleCancelAppointment = () => {
    if (role === rolesConstants.CUSTOMER) {
      handleCancelAppointmentCustomer();
    } else {
      handleCancelAppointmentSalesman();
    }
  };

  const loadAppointment = async (id: string) => {
    if (!id) {
      return;
    }

    try {
      setLoading(true);
      const [result, isAborted] = await getAppointmentById(id);
      if (isAborted) {
        return;
      }

      setLoading(false);
      setAppointment(result);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  useEffect(() => {
    const {
      appointmentId: id,
      token: tok,
    } = parse(location.search);

    if (id && !Array.isArray(id)) {
      loadAppointment(id);
    }
    if (tok && !Array.isArray(tok)) {
      setToken(tok);
    }
  }, [location.search]);

  const renderStatus = () => {
    switch (appointment?.appointmentStatusId) {
      case 'ACCEPTED':
        return 'Accepté';
      case 'CANCELED':
        return 'Annulé';
      case 'WAITING':
        return 'En attente';
      default:
        return '';
    }
  };

  const renderAppointmentDetails = () => {
    if (!appointment) {
      return null;
    }

    return (
      role === rolesConstants.CUSTOMER
        ? (
          <CustomerAppointmentDetails appointment={appointment} />
        ) : (
          <SalesmanAppointmentDetails appointment={appointment} />
        )
    );
  };

  return (
    <Loading loading={loading}>
      <Section isFullHeight isLightGrey isCentered>
        <div className={styles.box}>
          <h1 className={styles.title}>Annulation d&apos;un rendez-vous</h1>
          <h2 className="has-text-weight-bold is-size-4 mb-4">{renderStatus()}</h2>
          {renderAppointmentDetails()}
          <h2 className="subtitle mt-6">
            Cliquez sur le bouton pour confirmer l&apos;annulation de votre rendez-vous
          </h2>
          <Button
            type="button"
            className="button is-danger"
            value="Confirmer"
            onClick={handleCancelAppointment}
          />
        </div>
      </Section>
    </Loading>
  );
};

export default CancelAppointment;
