import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import authenticationRoutes from 'modules/authentication/authentication.routes';
import searchRoutes from 'modules/search/components/search.routes';
import SearchResult from 'modules/search/components/SearchResult/SearchResult';
import SearchPage from 'modules/search/components/SearchPage';
import ProductDescription from 'modules/search/components/ProductDescription';
import registrationRoutes from 'modules/registration/registration.routes';
import LogIn from 'modules/authentication/components/Login/LogIn';
import PrivateRoute from 'modules/shared/components/PrivateRoute';
import rolesConstants from 'modules/shared/constants/roles.constants';
import AnonymousRoute from 'modules/shared/components/AnonymousRoute';
import accountRoutes from 'modules/account/account.routes';
import AccountProfile from 'modules/account/components/AccountProfile';
import managementRoutes from 'modules/management/management.routes';
import ManageRoutes from 'modules/management/components/ManageRoutes';
import SupplierRoutes from 'modules/suppliers/components/SupplierRoutes';
import SignUpRoutes from 'modules/registration/components/SignUpRoutes';
import CustomerSignUp from 'modules/registration/components/CustomerSignUp';
import ConnectedSalesmanRoutes from 'modules/salesmen/components/ConnectedSalesmanRoutes';
import salesmanRoutes from 'modules/salesmen/salesman.routes';
import customerRoutes from 'modules/customers/customer.routes';
import CustomerRoutes from 'modules/customers/components/CustomerRoutes';
import SalesmanAgenda from 'modules/salesmen/components/SalesmanAgenda';
import CustomerAgenda from 'modules/customers/components/CustomerAgenda';
import CancelAppointment from 'modules/requests/components/CancelAppointment';

import PageNotFound from './PageNotFound/PageNotFound';
import Forbidden from './Forbidden';

const AppRoutes = () => (
  <Switch>
    {/* Nesting */}
    <PrivateRoute
      path={managementRoutes.MANAGE}
      roles={[rolesConstants.SUPPLIER, rolesConstants.ADMINISTRATOR]}
      render={(userRole: string) => {
        if (userRole === rolesConstants.SUPPLIER) {
          return <SupplierRoutes />;
        }

        return <ManageRoutes />;
      }}
    />
    {/* Fin Nesting */}

    <AnonymousRoute path={authenticationRoutes.SIGN_IN}>
      <LogIn />
    </AnonymousRoute>

    <AnonymousRoute path={registrationRoutes.CHOOSE_SIGN_UP}>
      <SignUpRoutes />
    </AnonymousRoute>

    <PrivateRoute
      path={accountRoutes.ACCOUNT_PROFILE}
      roles={[rolesConstants.SUPPLIER, rolesConstants.CUSTOMER, rolesConstants.ADMINISTRATOR]}
    >
      <AccountProfile />
    </PrivateRoute>

    <PrivateRoute
      path={salesmanRoutes.REQUESTS}
      roles={[rolesConstants.SALESMAN]}
    >
      <ConnectedSalesmanRoutes />
    </PrivateRoute>

    <PrivateRoute
      path={salesmanRoutes.AGENDA}
      roles={[rolesConstants.SALESMAN]}
    >
      <SalesmanAgenda />
    </PrivateRoute>

    <PrivateRoute
      path={customerRoutes.APPOINTMENTS}
      roles={[rolesConstants.CUSTOMER]}
    >
      <CustomerAgenda />
    </PrivateRoute>

    <Route path={searchRoutes.SEARCH_RESULT}>
      <SearchResult />
    </Route>
    <Route path={searchRoutes.PRODUCT_DESCRIPTION}>
      <ProductDescription />
    </Route>
    <Route path={searchRoutes.PRODUCT_REQUEST}>
      <CustomerSignUp />
    </Route>
    <Route path={customerRoutes.CUSTOMER}>
      <CustomerRoutes />
    </Route>
    <Route path={salesmanRoutes.APPOINTMENT_CANCELLATION}>
      <CancelAppointment role={rolesConstants.SALESMAN} />
    </Route>

    <Route path="/404">
      <PageNotFound />
    </Route>
    <Route path="/unauthorized">
      <Forbidden />
    </Route>
    <Route path={searchRoutes.MAIN_PAGE} exact>
      <SearchPage />
    </Route>

    <Route path="*">
      <Redirect to="/404" />
    </Route>
  </Switch>
);

export default AppRoutes;
