import React from 'react';

import Appointment from 'modules/requests/models/Appointment.model';
import { toPrettyDateRange } from 'modules/requests/utils/dates.utils';
import EmbeddedMap from 'modules/shared/components/EmbeddedMap';

interface Props {
  appointment: Appointment;
}

const SalesmanAppointmentDetails = ({ appointment }: Props) => {
  const {
    startDate,
    endDate,
    contactRequest: {
      customerAddress,
      customerCity,
      customerCompanyName,
      customerEmail,
      customerFirstName,
      customerLastName,
      customerPhoneNumber,
      productName,
      productDescription,
    },
  } = appointment;

  return (
    <>
      <h2 className="has-text-weight-bold is-size-4">
        {toPrettyDateRange(new Date(startDate), new Date(endDate))}
      </h2>
      <div className="mt-2">
        <p className="has-text-weight-bold is-size-5">Client : </p>
        <h3>
          <span className="has-text-weight-bold">Nom et prénom : </span>
          {customerLastName}
          {' '}
          {customerFirstName}
        </h3>
        <p>
          <span className="has-text-weight-bold">Coordonnées : </span>
          {customerCompanyName}
          {' : '}
          {customerEmail}
          {' - '}
          {customerPhoneNumber}
        </p>
        <p className="has-text-weight-bold mt-2 is-size-5">Produit :</p>
        <p>
          <span className="has-text-weight-bold">Nom : </span>
          {productName}
        </p>
        <p>
          <span className="has-text-weight-bold">Description : </span>
          {productDescription}
        </p>
        <div className="mt-5">
          <p className="has-text-weight-bold my-2 is-size-5">
            Adresse du client :
          </p>
          <EmbeddedMap
            address={customerAddress}
            city={customerCity}
          />
        </div>
      </div>
    </>
  );
};

export default SalesmanAppointmentDetails;
