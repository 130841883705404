import { createSelector } from 'reselect';

import { getDecodedToken } from 'modules/authentication/selectors/token.selectors';

export const getCurrentUserRole = createSelector(
  [getDecodedToken],
  (token) => token && token.role,
);

export const getIsConnected = createSelector(
  [getDecodedToken],
  (token) => !!token,
);

export const getCurrentUserId = createSelector(
  [getDecodedToken],
  (token) => token && token.id,
);
