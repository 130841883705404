import React from 'react';
import { Switch, Route } from 'react-router-dom';

import useSubPath from 'modules/shared/hooks/useSubPath.hook';
import CancelAppointment from 'modules/requests/components/CancelAppointment';
import rolesConstants from 'modules/shared/constants/roles.constants';

import customerRoutes from '../customer.routes';
import CustomerContactRequest from './CustomerContactRequest';

const CustomerRoutes = () => (
  <Switch>
    <Route path={useSubPath(customerRoutes.PROPOSALS)}>
      <CustomerContactRequest />
    </Route>
    <Route path={useSubPath(customerRoutes.APPOINTMENT_CANCELLATION)}>
      <CancelAppointment role={rolesConstants.CUSTOMER} />
    </Route>
  </Switch>
);

export default CustomerRoutes;
