import { StylesConfig } from 'react-select';

import GroupSelectOption from 'modules/shared/models/GroupSelectOption.model';
import SelectOption from 'modules/shared/models/SelectOption.model';

export const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '5px 0',
};

export const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center' as const,
};

export const customStyles: StylesConfig<SelectOption, false, GroupSelectOption> = {
  control: (base) => ({
    ...base,
    border: '2px solid hsl(0, 0%, 71%)',
    borderRadius: '3px',
    transition: 'all .5s',
    boxShadow: 'none',
    padding: '.2rem .6rem',
    '&:focus, &:focus-within': {
      borderColor: '#02C4A7',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    // eslint-disable-next-line no-nested-ternary
    background: isSelected ? '#02C4A7' : isFocused ? '#e0fefa' : undefined,
    color: isSelected ? '#fff' : undefined,
    ':active': {
      ...base[':active'],
      background: isSelected ? '#02C4A7' : '#e0fefa',
      color: isSelected ? '#fff' : undefined,
    },
  }),
  dropdownIndicator: (base, { isFocused }) => ({
    ...base,
    svg: {
      fill: isFocused ? '#02C4A7' : 'hsl(0, 0%, 71%)',
      transition: 'transform .5s',
      transform: isFocused ? 'rotate(0deg)' : 'rotate(90deg)',
    },
  }),
};
