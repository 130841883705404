import React from 'react';

import SearchBar from 'modules/search/components/SearchBar';
import Section from 'modules/shared/components/Section';

import Logo from '../Logo';
import styles from './SearchPage.module.scss';

const SearchPage = () => (
  <Section containerClassName={styles.searchPage}>
    <div className={styles.logo}>
      <Logo width={200} height={200} />
    </div>
    <h1 className={styles.title}>
      MySalesman
    </h1>
    <SearchBar />
  </Section>
);

export default SearchPage;
