import Category from 'modules/products/models/Category.model';
import LicensePrice from 'modules/salesmen/models/LicensePrice.model';
import PaymentMethod from 'modules/salesmen/models/PaymentMethod.model';
import Filter from '../models/Filter.model';
import SelectOption from '../models/SelectOption.model';

export const mapToSelectOption = (filter: Filter): SelectOption => ({
  label: filter.name,
  value: filter.id,
});

export const mapCategoryToSelectOption = (category: Category): SelectOption => ({
  label: category.label,
  value: category.id,
});

export const mapLicensePriceToSelectOption = (licensePrice: LicensePrice): SelectOption => ({
  label: `${String(licensePrice.duration)} jours`,
  value: licensePrice.id,
});

export const mapPaymentMethodToSelectOption = (paymentMethod: PaymentMethod): SelectOption => ({
  label: paymentMethod.type,
  value: paymentMethod.id,
});
