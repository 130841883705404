import React from 'react';
import Section from 'modules/shared/components/Section';

import registrationRoutes from 'modules/registration/registration.routes';

import styles from './ChooseSignUp.module.scss';

import ChooseBox from '../ChooseBox';

const ChooseSignUp = () => (
  <Section isFullHeight isLightGrey>
    <div className={styles.content}>
      <ChooseBox
        title="Je suis un fournisseur"
        text="En tant que fournisseur,
        vous ajouterez des représentants
        et des produits qui seront visible par l'ensemble de la communauté."
        to={`${registrationRoutes.CHOOSE_SIGN_UP}${registrationRoutes.SUPPLIER_SIGN_UP}`}
      />
      <ChooseBox
        title="Je suis un client HORECA"
        text="En tant que client,
        vous pouvez rechercher un produit et demander la visite d'un représentant dans
        votre établissement en seulement 2 clics!"
        to={`${registrationRoutes.CHOOSE_SIGN_UP}${registrationRoutes.CUSTOMER_SIGN_UP}`}
      />
    </div>
  </Section>
);

export default ChooseSignUp;
