import React from 'react';

import ProvinceMap from '../../models/ProvinceMap.model';
import styles from './BelgiumMap.module.scss';

interface Props {
  provinces: ProvinceMap[];
  onProvinceClick: (e: React.MouseEvent<SVGGElement, MouseEvent>) => void;
}

const BelgiumMap = ({ provinces, onProvinceClick }: Props) => (
  <div className={styles.wrapper}>
    <h2 className={styles.label}>Zones</h2>
    <div className={styles.mapWrapper}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 752.16895 611.36615"
        className={styles.belgiumMap}
      >
        {provinces.map(({
          d,
          coordinates,
          isSelected,
          zone,
        }: ProvinceMap) => (
          <g onClick={onProvinceClick} id={zone.id} key={zone.id}>
            <path
              d={d}
              className={isSelected ? styles.selected : ''}
            />

            <text
              x={coordinates.x}
              y={coordinates.y}
            >
              {zone.name}
            </text>
          </g>
        ))}
      </svg>
    </div>
  </div>
);

export default BelgiumMap;
