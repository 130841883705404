import { AnyAction } from 'redux';

import CategoryState from 'modules/shared/types/reducers/CategoryState';
import { LOAD, LOAD_SUCCESS, LOAD_FAILED } from '../category.actionTypes';

const initialState: CategoryState = {
  isLoading: false,
  categories: [],
  error: null,
};

export default (state: CategoryState = initialState, action: AnyAction): CategoryState => {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.payload,
      };
    case LOAD_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
