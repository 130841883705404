import React from 'react';
import classNames from 'classnames';

import Section from 'modules/shared/components/Section';
import SignInForm from 'modules/authentication/components/SignInForm';

import styles from './Login.module.scss';

const LogIn = () => (
  <Section isFullHeight isLightGrey isCentered>
    <div className={classNames(styles.boxSize)}>
      <SignInForm />
    </div>
  </Section>
);

export default LogIn;
