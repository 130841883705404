import React from 'react';

import Salesman from '../models/Salesman.model';

import Button from '../../shared/components/Button';
import Card from '../../shared/components/Card';
import IconText from '../../shared/components/IconText';

interface Props {
  salesman: Salesman;
  goToEditSalesman: (salesman: Salesman) => void;
}

const SalesmanCard = ({
  salesman,
  goToEditSalesman,
}: Props) => (
  <li className="column is-full-tablet is-one-third-desktop">
    <div className="card is-fullheight">
      <Card title={`${salesman.user.lastName} ${salesman.user.firstName}`}>
        <IconText iconName="fa-envelope">
          <a href={`mailto:${salesman.user.email}`}>
            {salesman.user.email}
          </a>
        </IconText>
        <IconText iconName="fa-phone">
          <a href={`mailto:${salesman.user.phoneNumber}`}>
            {salesman.user.phoneNumber}
          </a>
        </IconText>
      </Card>
      <div className="card-footer">
        <Button
          style={{ margin: '10px' }}
          value="Éditer"
          className="button is-dark is-small is-fullwidth"
          iconName="fa-edit"
          type="button"
          onClick={() => goToEditSalesman(salesman)}
        />
      </div>
    </div>
  </li>
);

export default SalesmanCard;
