import React from 'react';
import { useSelector } from 'react-redux';

import { getUserId } from 'modules/authentication/selectors/token.selectors';
import ManageRequests from 'modules/requests/components/ManageRequests';

const ConnectedSalesmanRoutes = () => {
  const userId = useSelector(getUserId);

  return (
    <ManageRequests salesmanUserId={userId!} />
  );
};

export default ConnectedSalesmanRoutes;
