import React from 'react';

import { randomString } from 'modules/shared/utils/strings.utils';

import AppointmentProposal from './AppointmentProposal';
import Proposal from '../models/Proposal.model';
import ContactRequest from '../models/ContactRequest.model';

interface Props {
  proposals: Proposal[];
  selectedContactRequest: ContactRequest;
  toggleModal: () => void;
  onProposalClick: (index: number) => void;
}

const AppointmentProposals = ({
  proposals,
  selectedContactRequest,
  toggleModal,
  onProposalClick,
}: Props) => {
  const isRequestInTreatment = !!selectedContactRequest.salesmanId;

  const renderProposals = () => (
    <>
      <AppointmentProposal
        key={randomString()}
        proposal={proposals[0]}
        proposalIndex={1}
        toggleModal={toggleModal}
        onClick={() => onProposalClick(0)}
      />
      <AppointmentProposal
        key={randomString()}
        proposal={proposals[1]}
        proposalIndex={2}
        toggleModal={toggleModal}
        onClick={() => onProposalClick(1)}
      />
      <AppointmentProposal
        key={randomString()}
        proposal={proposals[2]}
        proposalIndex={3}
        toggleModal={toggleModal}
        onClick={() => onProposalClick(2)}
      />
    </>
  );

  const renderCurrentSalesman = () => (
    <>
      <div className="has-text-weight-bold">
        Demande actuellement traitée par
        {' '}
        {selectedContactRequest?.salesmanFirstName!}
        {' '}
        {selectedContactRequest?.salesmanLastName!}
      </div>
      <div>
        Informations supplémentaires :
        {' '}
        {selectedContactRequest?.salesmanEmail!}
        {' ('}
        {selectedContactRequest?.salesmanCompanyName!}
        )
      </div>
    </>
  );

  return (
    <>
      <h2 className="subtitle has-text-weight-bold is-5">Propositions</h2>
      {isRequestInTreatment ? renderCurrentSalesman() : renderProposals()}
    </>
  );
};

export default AppointmentProposals;
