import React, { useRef } from 'react';

import { randomString } from 'modules/shared/utils/strings.utils';

interface Props {
  name: string,
  label: string;
  value: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton = (props: Props) => {
  const {
    name,
    label,
    value,
    onChange,
  } = props;

  const id = useRef(randomString());

  return (
    <>
      <input
        id={id.current}
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
      />
      <label htmlFor={id.current} style={{ marginLeft: '20px' }}>
        {label}
      </label>
    </>
  );
};

export default RadioButton;
