import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Section from 'modules/shared/components/Section';
import Loading from 'modules/shared/components/Loading';
import useNotifications from 'modules/shared/hooks/useNotifications';
import supplierRoutes from 'modules/suppliers/supplier.routes';
import managementRoutes from 'modules/management/management.routes';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

import salesmanService from '../services/salesmen.service';
import ManageSalesman from './ManageSalesman';
import Salesman from '../models/Salesman.model';
import AddSalesman from '../models/AddSalesman.model';

interface Props {
  supplierId: string;
  salesmanId: string;
}

const EditSalesman = ({ salesmanId, supplierId }: Props) => {
  const { addNotification } = useNotifications();

  const history = useHistory();

  const [salesman, setSalesman] = useState<Salesman>();
  const [loading, setLoading] = useState(false);

  const getFullSalesman = useSafeApiCall(salesmanService.getFullSalesman);
  const putSalesman = useSafeApiCall(salesmanService.updateSalesman);

  const loadSalesman = async () => {
    try {
      setLoading(true);
      const [result, isAborted] = await getFullSalesman(salesmanId);
      if (isAborted) {
        return;
      }

      setSalesman(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSalesman();
  }, []);

  const goToSalesmanTable = () => {
    history.push({
      pathname: `${managementRoutes.MANAGE}${supplierRoutes.SALESMEN}`,
      search: 'action=updated',
    });
  };

  const updateSalesman = async (salesmanToUpdate: AddSalesman) => {
    if (!salesman?.id) {
      return;
    }

    const s: AddSalesman = {
      ...salesmanToUpdate,
      id: salesman?.id,
      userId: salesman?.user.id,
    };

    try {
      const [, isAborted] = await putSalesman(salesmanId, s);
      if (isAborted) {
        return;
      }

      goToSalesmanTable();
    } catch (error) {
      addNotification('error', error.messages);
    }
  };

  return (
    <Section isFullHeight isLightGrey>
      <Loading loading={loading}>
        <ManageSalesman
          supplierUserId={supplierId}
          salesman={salesman}
          submitForm={updateSalesman}
          title="Éditer un représentant"
          buttonValue="Enregistrer les modifications"
        />
      </Loading>
    </Section>
  );
};

export default EditSalesman;
