import React from 'react';
import classNames from 'classnames';

import ContactRequest from 'modules/requests/models/ContactRequest.model';

import styles from './RequestItems.module.scss';

interface Props {
  requests: ContactRequest[];
  isSelectedRequest: (request: ContactRequest) => void;
  setSelectedRequest: (request: ContactRequest) => void;
}

const RequestItems = ({ requests, isSelectedRequest, setSelectedRequest }: Props) => {
  const renderAdditionalMessage = (request: ContactRequest) => (
    request.salesmanId ? '(Demande déjà en cours de traitement)' : ''
  );

  return (
    <div className={styles.scrollable}>
      {requests.map((request, index) => (
        <a
          key={request.id}
          className={classNames('panel-block', {
            [styles.selected]: isSelectedRequest(request),
            [styles.disabled]: !!request.salesmanId,
          })}
          onClick={() => setSelectedRequest(request)}
        >
          {index + 1}
          {' : '}
          {request.customerLastName}
          {' '}
          {request.customerFirstName}
          {' - '}
          {request.productName}
          {' '}
          {renderAdditionalMessage(request)}
        </a>
      ))}
    </div>
  );
};

export default RequestItems;
