import React, { useEffect } from 'react';
import classNames from 'classnames';

import Button from '../Button';
import { NotificationType } from '../../models/Notification.model';

import styles from './Notification.module.scss';

interface Props {
  text: string;
  type?: NotificationType;
  onCloseClick: () => void;
}

const Notification = (props: Props) => {
  const {
    text,
    type,
    onCloseClick,
  } = props;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onCloseClick();
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const getColor = () => {
    switch (type) {
      case 'success':
        return 'is-success';
      case 'warning':
        return 'is-warning';
      case 'error':
        return 'is-danger';
      default:
        return 'is-info';
    }
  };

  return (
    <div className={classNames('notification', getColor(), styles.notification)}>
      <Button type="button" value="" className="delete" onClick={onCloseClick} />
      {text}
    </div>
  );
};

export default Notification;
