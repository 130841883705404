import React, { useMemo } from 'react';

import Select from 'modules/shared/components/Select';
import useQuickForm from 'modules/shared/hooks/useQuickForm.hook';
import { FieldValue } from 'modules/shared/hooks/useForm.hook';
import Zone from 'modules/products/models/Zone.model';
import zonesMappers from 'modules/products/mappers/zones.mappers';

interface Props {
  salesmen: Zone[];
  handleSalesmanChange: (salesmanId: string) => void;
}

const SalesmanChoice = ({ salesmen, handleSalesmanChange }: Props) => {
  const {
    fields: [
      SALESMAN_ID,
    ],
    getFieldValue,
    handleChange,
  } = useQuickForm(['']);

  const salesmanId = getFieldValue<string>(SALESMAN_ID);

  const salesmenZonesOptions = useMemo(() => salesmen
    .map(zonesMappers.mapToGroupOption), [salesmen]);

  const handleSelectChange = (value: FieldValue, valid: boolean, name?: string) => {
    handleSalesmanChange(value!.toString());
    handleChange(value, valid, name);
  };

  return (
    <>
      <h2 className="subtitle has-text-weight-bold is-5">Choix du représentant</h2>
      {salesmen?.length ? (
        <Select
          placeholder="Veuillez choisir le représentant allant traiter cette demande"
          name={SALESMAN_ID}
          onChange={handleSelectChange}
          required
          options={salesmenZonesOptions}
          value={salesmanId}
        />
      ) : (
        <>
          <div className="has-text-weight-bold">Aucun représentant n&apos;est disponible</div>
          <div>Veuillez vérifier l&apos;expiration des licences de vos représentants</div>
        </>
      )}
    </>
  );
};

export default SalesmanChoice;
