import React, { createContext, PropsWithChildren, useState } from 'react';

import NotificationContainer from 'modules/shared/components/NotificationContainer';
import Notification from 'modules/shared/models/Notification.model';

interface NotificationContextModel {
  addNotification: (notification: Notification) => void;
  addNotifications: (notifications: Notification[]) => void;
}

export const NotificationContext = createContext<NotificationContextModel>({
  addNotification: () => { },
  addNotifications: () => { },
});

const NotificationContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = (newNotification: Notification) => {
    setNotifications([...notifications, newNotification]);
  };

  const addNotifications = (newNotifications: Notification[]) => {
    setNotifications([
      ...notifications,
      ...newNotifications,
    ]);
  };

  const removeNotification = (id: string) => {
    setNotifications(notifications.filter((n) => n.id !== id));
  };

  return (
    <NotificationContext.Provider value={{ addNotification, addNotifications }}>
      <NotificationContainer
        notif={notifications}
        removeNotif={removeNotification}
      />
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
