import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import useSubPath from 'modules/shared/hooks/useSubPath.hook';
import EditSalesman from 'modules/salesmen/components/EditSalesman';
import supplierRoutes from '../supplier.routes';

const ManageSalesmanRoutes = () => {
  const match = useRouteMatch<{ salesmanId: string, supplierId: string }>();
  const { params } = match;
  const { salesmanId, supplierId } = params;

  return (
    <Switch>
      <Route path={useSubPath(supplierRoutes.EDIT_SALESMAN)}>
        <EditSalesman salesmanId={salesmanId} supplierId={supplierId} />
      </Route>
    </Switch>
  );
};

export default ManageSalesmanRoutes;
