import { stringify } from 'query-string';

import Product from 'modules/products/models/Product.model';
import AddProduct from 'modules/products/models/AddProduct.model';
import Search from 'modules/products/models/Search.model';
import { makeThrowError } from 'modules/shared/utils/apiErrors.utils';
import ApiError from 'modules/shared/models/ApiError.model';
import HttpService from 'modules/shared/models/HttpService.model';
import errorCodes from 'modules/shared/constants/errorCodes.constants';

const mapError = (error: ApiError) => {
  switch (error.code) {
    case '401':
      return 'Votre session à expirée';
    case errorCodes.ITEM_NOT_FOUND:
      return 'Le produit désiré est introuvable';
    case errorCodes.ITEMS_NOT_FOUND:
      return 'Produits introuvables';
    case '204':
      return 'Aucune image trouvée';
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const endpoint = '/products';

const getProduct = (service: HttpService) => (
  productId: string,
  localized: boolean = true,
): Promise<Product> => service
  .get<Product>(`${endpoint}/${productId}?localized=${localized}`)
  .catch(throwError);

const getProducts = (service: HttpService) => (search: Search): Promise<Product[]> => service
  .get<Product[]>(`${endpoint}?${stringify(search)}`)
  .catch(throwError);

const getProductByUser = (service: HttpService) => (
  userId: string,
  onlyVisible: boolean,
): Promise<Product[]> => service
  .get<Product[]>(`${endpoint}/user/${userId}?onlyVisible=${onlyVisible}`)
  .catch(throwError);

const getProductBySupplier = (service: HttpService) => (
  supplierId: string,
  onlyVisible: boolean,
): Promise<Product[]> => service
  .get<Product[]>(`${endpoint}/supplier/${supplierId}?onlyVisible=${onlyVisible}`)
  .catch(throwError);

const addProduct = (service: HttpService) => (product: AddProduct): Promise<Product> => service
  .post<Product>(endpoint, product)
  .catch(throwError);

const updateProduct = (service: HttpService) => (
  product: AddProduct,
  productId: string,
): Promise<Product> => service
  .put<Product>(`${endpoint}/${productId}`, product)
  .catch(throwError);

const uploadImage = (service: HttpService) => (
  productId: string,
  logo: File,
): Promise<Product> => {
  const formData = new FormData();
  formData.append('productId', productId);
  formData.append('image', logo);

  return service
    .postFormData<Product>(`${endpoint}/image`, formData)
    .catch(throwError);
};

const deleteProduct = (service: HttpService) => (productId: string): Promise<void> => service
  .remove(`${endpoint}/${productId}`)
  .catch(throwError);

export default {
  getProducts,
  getProduct,
  addProduct,
  updateProduct,
  getProductByUser,
  getProductBySupplier,
  uploadImage,
  deleteProduct,
};
