import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';

interface Props {
  title: string;
  style?: React.CSSProperties;
  actions: { text: string, onClick: () => void, className?: string, disabled?: boolean }[];
}

const InnerModal = (props: PropsWithChildren<Props>) => {
  const {
    title,
    children,
    actions,
    style,
  } = props;

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card" style={style}>
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
        </header>
        <section className="modal-card-body">
          {children}
        </section>
        <footer className="modal-card-foot">
          {actions.map((action) => (
            <button
              key={action.text}
              type="button"
              className={classNames('button', action.className)}
              onClick={action.onClick}
              disabled={!!action.disabled}
            >
              {action.text}
            </button>
          ))}
        </footer>
      </div>
    </div>
  );
};

const Modal = (props: PropsWithChildren<Props>) => ReactDOM
  .createPortal(
    (
      <InnerModal
        title={props.title}
        actions={props.actions}
        style={props.style}
      >
        {props.children}
      </InnerModal>
    ),
    document.getElementById('modal')!,
  );

export default Modal;
