import React, { useEffect } from 'react';

import Input from 'modules/shared/components/Input';
import Button from 'modules/shared/components/Button';
import CompanyType from 'modules/company/models/companyType.model';
import useQuickForm from 'modules/shared/hooks/useQuickForm.hook';
import companyTypeService from 'modules/company/services/companyType.service';
import Select from 'modules/shared/components/Select';
import SelectOption from 'modules/shared/models/SelectOption.model';
import { NotificationType } from 'modules/shared/models/Notification.model';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

interface Props {
  companyType?: CompanyType | null;
  load: () => void;
  toggleManageCompanyModel: () => void;
  addNotification: (type: NotificationType, text: string) => void;
}

const ManageCompanyType = (props: Props) => {
  const {
    companyType,
    load,
    toggleManageCompanyModel,
    addNotification,
  } = props;

  const {
    fields: [
      LABEL,
      TYPE,
    ],
    isValid,
    getFieldValue,
    handleChange,
  } = useQuickForm(['', '']);

  const label = getFieldValue<string>(LABEL);
  const type = getFieldValue<string>(TYPE);

  const addCompanyType = useSafeApiCall(companyTypeService.update);
  const updateCompanyType = useSafeApiCall(companyTypeService.add);

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValid) {
      return;
    }

    const compType: CompanyType = {
      id: companyType?.id!,
      label,
      type,
    };

    if (companyType?.id) {
      try {
        const [, isAborted] = await addCompanyType(companyType.id!, compType);
        if (isAborted) {
          return;
        }

        toggleManageCompanyModel();
        load();
      } catch (err) {
        addNotification('error', err.messages);
      }
    } else {
      try {
        const [, isAborted] = await updateCompanyType(compType);
        if (isAborted) {
          return;
        }

        toggleManageCompanyModel();
        load();
      } catch (err) {
        addNotification('error', err.messages);
      }
    }
  };

  useEffect(() => {
    if (companyType) {
      handleChange(companyType.label, true, LABEL);
      handleChange(companyType.type, true, TYPE);
    }
  }, [companyType]);

  const options: SelectOption[] = [
    {
      label: 'Fournisseurs',
      value: 'Fournisseurs',
    },
    {
      label: 'Clients',
      value: 'Clients',
    },
  ];

  return (
    <form onSubmit={submit}>
      <div className="column is-12">
        <Input placeholder="Label" name={LABEL} value={label} onChange={handleChange} required />
      </div>
      <div className="column is-12">
        <Select
          name={TYPE}
          options={options}
          onChange={handleChange}
          placeholder="Selectionner un type"
          value={type}
          required
        />

      </div>
      <div className="column is-12">
        <div className="buttons is-right">
          <Button value={companyType?.id ? 'Modifier' : 'Ajouter'} className="button is-dark" type="submit" />
        </div>
      </div>
    </form>
  );
};

export default ManageCompanyType;
