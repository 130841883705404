import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface Props {
  iconName: string;
}

const IconText = ({ iconName, children }: PropsWithChildren<Props>) => (
  <div className="icon-text">
    <span className="icon">
      <i className={classNames('fas', iconName)} />
    </span>
    <span>
      {children}
    </span>
  </div>
);

export default IconText;
