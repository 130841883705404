import ApiError from 'modules/shared/models/ApiError.model';
import { makeThrowError } from 'modules/shared/utils/apiErrors.utils';
import HttpService from 'modules/shared/models/HttpService.model';
import errorCodes from 'modules/shared/constants/errorCodes.constants';

import AddContactRequest from '../models/AddContactRequest.model';
import ContactRequest from '../models/ContactRequest.model';

const mapError = (error: ApiError) => {
  switch (error.code) {
    case '401':
      return 'Votre session à expirée';
    case errorCodes.ITEM_NOT_FOUND:
      return 'La demande désirée est introuvable';
    case errorCodes.ITEMS_NOT_FOUND:
      return 'Demandes introuvables';
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const endpoint = '/contact-requests';

const getRequests = (service: HttpService) => (): Promise<ContactRequest[]> => service
  .get<ContactRequest[]>(endpoint)
  .catch(throwError);

const getSupplierRequests = (service: HttpService) => (
  supplierUserId: string,
): Promise<ContactRequest[]> => service
  .get<ContactRequest[]>(`${endpoint}/supplier/${supplierUserId}`)
  .catch(throwError);

const getRequestsBySalesman = (service: HttpService) => (
  salesmanUserId: string,
): Promise<ContactRequest[]> => service
  .get<ContactRequest[]>(`${endpoint}/salesman/${salesmanUserId}`)
  .catch(throwError);

const addRequest = (service: HttpService) => (
  request: AddContactRequest,
): Promise<ContactRequest> => (
  service.post<ContactRequest>(endpoint, request))
  .catch(throwError);

export default {
  getRequests,
  getSupplierRequests,
  getRequestsBySalesman,
  addRequest,
};
