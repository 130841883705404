import {
  format,
  addMinutes,
  roundToNearestMinutes,
} from 'date-fns';
import { fr } from 'date-fns/locale';

const formatFr = (date: Date, formatString: string) => format(date, formatString, { locale: fr });

const toPrettyDate = (date: Date) => formatFr(date, 'EEEE dd/MM/yyyy');
const getDateHour = (date: Date) => formatFr(date, "HH'h'mm");

export const toPrettyDateRange = (start: Date, end: Date) => `Le ${toPrettyDate(start)} de ${getDateHour(start)} à ${getDateHour(end)}`;

export const toDatelocalString = (date: Date) => formatFr(date, "yyyy-MM-dd'T'HH:mm");
export const defaultEndDate = (date: Date) => toDatelocalString(addMinutes(date, 15));

export const roundByInterval = (
  interval: number,
) => (
  date: Date,
) => roundToNearestMinutes(date, { nearestTo: interval });
