import React, { useRef } from 'react';
import classNames from 'classnames';

import { randomString } from 'modules/shared/utils/strings.utils';
import { NotificationType } from 'modules/shared/models/Notification.model';

import styles from './ImagePicker.module.scss';

interface Props {
  label?: string;
  preview?: string;
  disabled?: boolean;
  onChange: (image: FileList, preview: string) => void;
  name?: string;
  labelClassName?: string;
  addNotification?: (type: NotificationType, text: string) => void;
}

const ImagePicker = (props: Props) => {
  const {
    label,
    disabled,
    onChange,
    preview,
    name,
    labelClassName,
    addNotification,
  } = props;

  const id = useRef(randomString());

  const checkFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.item(0);

    if (!selectedFile) {
      addNotification!('warning', 'Aucun nouveau fichier sélectionné.');
      return;
    }

    if (selectedFile.size > 2000000) {
      addNotification!('warning', 'L\'image entrée est supérieur à 2MB.');
      return;
    }

    if (e.target.files) {
      onChange(e.target.files, URL.createObjectURL(selectedFile));
    }
  };

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <div className="field-label is-normal is-left">
          <label htmlFor={id.current} className={classNames('label', labelClassName)}>{label}</label>
        </div>
      </div>
      <div className="field-body">
        <label htmlFor={id.current} className="file-label">
          <div className="columns">
            <div className="column is-narrow">
              <input
                type="file"
                name={name}
                id={id.current}
                className="file-input"
                onChange={checkFile}
                accept=".jpg, .png, .jpeg, .gif, .bmp"
                disabled={disabled}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload" />
                </span>
              </span>
            </div>
            <div className={classNames('column is-narrow')}>
              <figure>
                <img src={preview} alt="" className={styles.preview} />
              </figure>
            </div>
          </div>
        </label>
      </div>
    </div>

  );
};

export default ImagePicker;
