import { combineReducers } from 'redux';

import authReducer from 'modules/authentication/auth.reducer';
import tokenReducer from 'modules/authentication/token.reducer';
import { persistReducer } from 'modules/shared/utils/persistReducer.util';
import categoryReducer from 'modules/management/components/category.reducer';

export default combineReducers({
  auth: authReducer,
  token: persistReducer('token', tokenReducer),
  category: categoryReducer,
});
