import React from 'react';
import { Switch, Route } from 'react-router-dom';

import useSubPath from 'modules/shared/hooks/useSubPath.hook';
import ManageRequests from 'modules/requests/components/ManageRequests';

import supplierRoutes from '../supplier.routes';
import ProductRoutes from './ProductRoutes';
import SalesmanRoutes from './SalesmanRoutes';

const SupplierRoutes = () => (
  <Switch>
    <Route path={useSubPath(supplierRoutes.PRODUCTS)}>
      <ProductRoutes />
    </Route>
    <Route path={useSubPath(supplierRoutes.SALESMEN)}>
      <SalesmanRoutes />
    </Route>
    <Route path={useSubPath(supplierRoutes.REQUESTS)}>
      <ManageRequests />
    </Route>
  </Switch>
);

export default SupplierRoutes;
