import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'query-string';

import Appointment from 'modules/requests/models/Appointment.model';
import appointmentService from 'modules/requests/services/appointment.service';
import useNotifications from 'modules/shared/hooks/useNotifications';
import Section from 'modules/shared/components/Section';
import Loading from 'modules/shared/components/Loading';
import Form from 'modules/shared/components/Form';
import Button from 'modules/shared/components/Button';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';
import UpdateAppointment from 'modules/requests/models/UpdateAppointment.model';
import searchRoutes from 'modules/search/components/search.routes';

import CustomerProposals from './CustomerProposals';

const CustomerContactRequest = () => {
  const location = useLocation();
  const history = useHistory();

  const { addNotification } = useNotifications();
  const [loading, setLoading] = useState(false);
  const [proposals, setProposals] = useState<Appointment[]>([]);
  const [selectedProposalId, setSelectedProposalId] = useState('');

  const getCustomerProposals = useSafeApiCall(appointmentService.getCustomerProposals);
  const acceptProposal = useSafeApiCall(appointmentService.acceptAppointment);

  const loadProposals = async () => {
    const { contactRequestId, customerId } = parse(location.search);

    const invalidContactRequestId = !contactRequestId || Array.isArray(contactRequestId);
    const invalidCustomerId = !customerId || Array.isArray(customerId);

    if (invalidContactRequestId || invalidCustomerId) {
      history.push({ pathname: `${searchRoutes.MAIN_PAGE}` });
      addNotification('error', 'Un problème a été rencontré lors du chargement de cette demande');
      return;
    }

    try {
      setLoading(true);
      const [result, isAborted] = await getCustomerProposals(contactRequestId as string);
      if (isAborted) {
        return;
      }

      setProposals(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  const acceptAppointment = async () => {
    const { customerId } = parse(location.search);
    if (!customerId || Array.isArray(customerId)) {
      history.push({ pathname: `${searchRoutes.MAIN_PAGE}` });
      return;
    }

    const updatedAppointment: UpdateAppointment = {
      updatedByUserId: customerId!,
    };

    try {
      await acceptProposal(selectedProposalId, updatedAppointment);
      history.push({ pathname: `${searchRoutes.MAIN_PAGE}` });
      addNotification('success', 'Le rendez-vous a été confirmé');
    } catch (err) {
      addNotification('error', err.messages);
    }
  };

  const handleSelectProposal = (propsalId: string) => setSelectedProposalId(propsalId);

  const submitProposal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    acceptAppointment();
  };

  useEffect(() => {
    loadProposals();
  }, []);

  return (
    <Section isFullHeight isLightGrey>
      <Loading loading={loading}>
        <Form title="Proposition de rendez-vous" submit={submitProposal}>
          <CustomerProposals
            appointmentProposals={proposals}
            onChange={handleSelectProposal}
          />
          <Button
            className="button is-success"
            type="submit"
            value="Confirmer le rendez-vous"
          />
        </Form>
      </Loading>
    </Section>
  );
};

export default CustomerContactRequest;
