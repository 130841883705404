import React, { useEffect } from 'react';
import { addMinutes, addDays } from 'date-fns';

import Modal from 'modules/shared/components/Modal';
import useQuickForm from 'modules/shared/hooks/useQuickForm.hook';
import DatePicker from 'modules/shared/components/DatePicker';
import formStyles from 'modules/shared/components/Form/Form.module.scss';
import Calendar from 'modules/shared/components/Calendar';

import Appointment from '../models/Appointment.model';
import appointmentsMappers from '../mappers/appointments.mappers';
import { roundByInterval } from '../utils/dates.utils';

interface Props {
  isActive: boolean;
  events: Appointment[];
  addProposal: (start: Date, end: Date) => void;
  toggleModal: () => void;
}

const ProposalModal = ({
  isActive,
  events,
  addProposal,
  toggleModal,
}: Props) => {
  const MINUTES_INTERVAL = 15;
  const roundMinutes = roundByInterval(MINUTES_INTERVAL);

  const {
    fields: [
      START_DATE,
      END_DATE,
    ],
    getFieldValue,
    handleChange,
  } = useQuickForm([
    { value: roundMinutes(addDays(new Date(), 1)), isValid: true },
    { value: roundMinutes(addMinutes(addDays(new Date(), 1), 15)), isValid: true },
  ]);

  const startDate = getFieldValue<Date>(START_DATE);
  const endDate = getFieldValue<Date>(END_DATE);

  useEffect(() => {
    if (startDate) {
      const newEndDate = roundMinutes(addMinutes(startDate, 15));
      handleChange(newEndDate, true, END_DATE);
    }
  }, [startDate]);

  if (!isActive) {
    return null;
  }

  return (
    <Modal
      title="Proposition de rendez-vous"
      style={{ width: '80%' }}
      actions={[
        {
          text: 'Confirmer',
          className: 'button is-success',
          onClick: () => addProposal(new Date(startDate), new Date(endDate)),
        },
        {
          text: 'Fermer',
          className: 'button is-dark',
          onClick: toggleModal,
        },
      ]}
    >
      <div className={formStyles.formControls}>
        <DatePicker
          label="Date de début"
          name={START_DATE}
          value={startDate}
          onChange={handleChange}
          min={addDays(new Date(), 1)}
        />
        <DatePicker
          label="Date de fin"
          name={END_DATE}
          value={endDate}
          onChange={handleChange}
          min={addDays(new Date(), 1)}
        />
      </div>

      <div className="mt-6">
        <Calendar
          events={events.map(appointmentsMappers.mapToSalesmanCalendarEvent)}
        />
      </div>
    </Modal>
  );
};

export default ProposalModal;
