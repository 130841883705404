import ApiError from 'modules/shared/models/ApiError.model';
import { makeThrowError } from 'modules/shared/utils/apiErrors.utils';
import HttpService from 'modules/shared/models/HttpService.model';
import errorCodes from 'modules/shared/constants/errorCodes.constants';

import Credentials from '../models/Credentials.model';
import Token from '../models/Token.model';

const mapError = (error: ApiError) => {
  switch (error.code) {
    case '401':
      return 'Adresse email ou mot de passe incorrect.';
    case errorCodes.ITEM_NOT_FOUND:
      return 'Le compte désiré est introuvable';
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const endpoint = '/auth/login';

const login = (service: HttpService) => (credentials: Credentials): Promise<Token> => service
  .post<Token>(endpoint, credentials)
  .catch(throwError);

export default {
  login,
};
