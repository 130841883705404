import ApiError from 'modules/shared/models/ApiError.model';
import HttpService from 'modules/shared/models/HttpService.model';
import { makeThrowError } from 'modules/shared/utils/apiErrors.utils';
import errorCodes from 'modules/shared/constants/errorCodes.constants';

import Language from '../models/Language.model';

const mapError = (error: ApiError) => {
  switch (error.code) {
    case '401':
      return 'Votre session à expirée';
    case errorCodes.ITEM_NOT_FOUND:
      return 'La langue désirée est introuvable';
    case errorCodes.ITEMS_NOT_FOUND:
      return 'Langues introuvables';
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const endpoint = '/languages';

const getLanguages = (service: HttpService) => (): Promise<Language[]> => service
  .get<Language[]>(endpoint)
  .catch(throwError);

const getLanguageByCode = (service: HttpService) => (code: string): Promise<Language> => service
  .get<Language>(`${endpoint}/code/${code}`)
  .catch(throwError);

export default {
  getLanguages,
  getLanguageByCode,
};
