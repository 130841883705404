import ApiError from 'modules/shared/models/ApiError.model';
import { makeThrowError } from 'modules/shared/utils/apiErrors.utils';
import HttpService from 'modules/shared/models/HttpService.model';
import errorCodes from 'modules/shared/constants/errorCodes.constants';

import AddAppointment from '../models/AddAppointment.model';
import Appointment from '../models/Appointment.model';
import UpdateAppointment from '../models/UpdateAppointment.model';

import {
  NUMBER_OF_PROPOSALS,
  INVALID_CONTACT_REQUEST,
  INVALID_APPOINTMENT_TOKEN,
  INVALID_APPOINTMENT_DATE,
  INVALID_APPOINTMENT_PROPOSAL_COUNT,
} from '../constants/requests.constant';
import UpdateAppointmentToken from '../models/UpdateAppointmentToken.model';

const mapError = (error: ApiError) => {
  switch (error.code) {
    case INVALID_CONTACT_REQUEST:
      return 'Cette demande est déjà en cours de traitement par un autre représentant';
    case INVALID_APPOINTMENT_TOKEN:
      return 'Le token d\'annulation est expirée';
    case INVALID_APPOINTMENT_DATE:
      return 'Les dates de début et de fin de chaque proposition doivent être dans la même journée\nAinsi qu\'être au moins un jour après la journée où les dates sont proposées';
    case INVALID_APPOINTMENT_PROPOSAL_COUNT:
      return `Le nombre de propositions doit être de ${NUMBER_OF_PROPOSALS}`;
    case '401':
      return 'Votre session à expirée';
    case errorCodes.ITEM_NOT_FOUND:
      return 'Le rendez-vous désiré est introuvable';
    case errorCodes.ITEMS_NOT_FOUND:
      return 'Rendez-vous introuvables';
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const endpoint = '/appointments';

const getById = (service: HttpService) => (
  appointmentId: string,
): Promise<Appointment> => service
  .get<Appointment>(`${endpoint}/${appointmentId}`)
  .catch(throwError);

const getBySalesman = (service: HttpService) => (
  userId: string,
): Promise<Appointment[]> => service
  .get<Appointment[]>(`${endpoint}/salesman/${userId}`)
  .catch(throwError);

const getByCustomer = (service: HttpService) => (
  userId: string,
): Promise<Appointment[]> => service
  .get<Appointment[]>(`${endpoint}/customer/${userId}`)
  .catch(throwError);

const getCustomerProposals = (service: HttpService) => (
  contactRequestId: string,
): Promise<Appointment[]> => service
  .get<Appointment[]>(`${endpoint}/customer/proposals/${contactRequestId}`)
  .catch(throwError);

const addAppointment = (service: HttpService) => (
  appointment: AddAppointment,
): Promise<Appointment[]> => service
  .post<Appointment[]>(endpoint, appointment)
  .catch(throwError);

const acceptAppointment = (service: HttpService) => (
  appointmentId: string,
  userId: UpdateAppointment,
): Promise<Appointment> => service
  .put<Appointment>(`${endpoint}/customer/proposals/approval/${appointmentId}`, userId)
  .catch(throwError);

const cancelAppointment = (service: HttpService) => (
  appointmentId: string,
  userId: UpdateAppointment,
): Promise<Appointment> => service
  .put<Appointment>(`${endpoint}/cancellation/${appointmentId}`, userId)
  .catch(throwError);

const cancelSalesmanAppointment = (service: HttpService) => (
  appointmentId: string,
  token: UpdateAppointmentToken,
): Promise<Appointment> => service
  .put(`${endpoint}/salesman/cancellation/${appointmentId}`, token);

const cancelCustomerAppointment = (service: HttpService) => (
  appointmentId: string,
  token: UpdateAppointmentToken,
): Promise<Appointment> => service
  .put(`${endpoint}/customer/cancellation/${appointmentId}`, token);

export default {
  getById,
  getBySalesman,
  getByCustomer,
  getCustomerProposals,
  addAppointment,
  acceptAppointment,
  cancelAppointment,
  cancelSalesmanAppointment,
  cancelCustomerAppointment,
};
