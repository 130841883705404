import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';

import Section from 'modules/shared/components/Section';
import CheckBox from 'modules/shared/components/CheckBox';
import useQuickForm from 'modules/shared/hooks/useQuickForm.hook';
import Button from 'modules/shared/components/Button';
import Input from 'modules/shared/components/Input';
import vatService from 'modules/customers/services/vat.service';
import Vat from 'modules/customers/models/Vat.model';
import Customer from 'modules/customers/models/Customer.model';
import Supplier from 'modules/products/models/Supplier.model';
import { isVATNumberValid, isEmailValid } from 'modules/shared/validators';
import Select from 'modules/shared/components/Select';
import companyTypeService from 'modules/company/services/companyType.service';
import CompanyType from 'modules/company/models/companyType.model';
import Form from 'modules/shared/components/Form';
import formStyles from 'modules/shared/components/Form/Form.module.scss';
import { FieldValue } from 'modules/shared/hooks/useForm.hook';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';
import useNotifications from 'modules/shared/hooks/useNotifications';
import config from 'modules/shared/config';

import * as companyMappers from 'modules/company/mappers/companies.mappers';

interface Props {
  renderAdditionalFields?: () => JSX.Element;
  buttonValue: string;
  title: string;
  showSaveCheckBox?: boolean;
  isLoading: boolean;
  submit: (customer: Customer, confirmation: boolean) => void;
}

const SignUp = ({
  renderAdditionalFields,
  buttonValue,
  title,
  showSaveCheckBox,
  isLoading,
  submit,
}: Props) => {
  const {
    fields: [
      VAT,
      EMAIL,
      PHONENUMBER,
      LASTNAME,
      FIRSTNAME,
      COMPANY,
      STREET,
      CITY,
      ZIP,
      TYPE,
    ],
    getFieldValue,
    isValid,
    handleChange,
  } = useQuickForm(['', '', '', '', '', '', '', '', '', '']);

  const vat = getFieldValue<string>(VAT);
  const email = getFieldValue<string>(EMAIL);
  const phoneNumber = getFieldValue<string>(PHONENUMBER);
  const lastName = getFieldValue<string>(LASTNAME);
  const firstName = getFieldValue<string>(FIRSTNAME);
  const establishmentName = getFieldValue<string>(COMPANY);
  const street = getFieldValue<string>(STREET);
  const city = getFieldValue<string>(CITY);
  const zip = getFieldValue<string>(ZIP);
  const type = getFieldValue<string>(TYPE);

  const canSubmit = isValid;

  const [isSaveInfo, setIsSaveInfo] = useState(false);
  const [vatInfo, setVatInfo] = useState<Vat>();
  const [companyType, setCompanyType] = useState<CompanyType[]>([]);

  const [loading, setLoading] = useState(false);
  const [socialReason, setSocialReason] = useState('');

  const { addNotification } = useNotifications();

  const getAllCompanyTypes = useSafeApiCall(companyTypeService.getAll);
  const getVatInfo = useSafeApiCall(vatService.getVatInfo);

  const loadVatInfo = async () => {
    const [result, isAborted] = await getVatInfo(vat);
    if (isAborted) {
      return;
    }

    setVatInfo(result);
  };

  const loadCompanyType = async () => {
    try {
      const [result, isAborted] = await getAllCompanyTypes();
      if (isAborted) {
        return;
      }

      setCompanyType(result);
    } catch (err) {
      addNotification('error', err.messages);
    }
  };

  const onChanged = (value: FieldValue, valid: boolean, name?: string) => {
    handleChange(value, valid, name);
  };

  const companyTypeOptions = useMemo(() => companyType
    .map(companyMappers.mapToSelectOption), [companyType]);

  useEffect(() => {
    if (!showSaveCheckBox) {
      setIsSaveInfo(true);
    }
    loadCompanyType();
  }, []);

  useEffect(() => {
    if (!config?.vatLength) {
      return;
    }

    const vatLength = parseInt(config?.vatLength!, 10);
    if (vat.length === vatLength) {
      setLoading(true);
      loadVatInfo();
    }
  }, [vat]);

  useEffect(() => {
    if (vatInfo) {
      handleChange(vatInfo?.address, true, STREET);
      handleChange(vatInfo?.name, true, COMPANY);
      handleChange(vatInfo?.city, true, CITY);
      setSocialReason(vatInfo?.name);
    }
    setLoading(false);
  }, [vatInfo]);

  const saveInfoClicked = () => {
    setIsSaveInfo(!isSaveInfo);
  };

  const showSaveBox = () => {
    if (!showSaveCheckBox) {
      return null;
    }

    return (
      <CheckBox
        onChange={saveInfoClicked}
        labelText="Voulez-vous sauvegarder vos informations?"
        key="save"
        checked={isSaveInfo}
      />
    );
  };

  const showCompanyName = () => {
    if (!vatInfo) {
      return null;
    }

    return (
      <div className="column is-6">
        <br />
        <h3 className="title is-3">
          {socialReason}
        </h3>
      </div>
    );
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const tempCustomer: Customer | Supplier = {
      firstName,
      lastName,
      email,
      companyName: socialReason,
      companyEstablishmentName: establishmentName,
      phoneNumber,
      companyTypeId: type,
      companyVat: vat,
      addressStreet: street,
      addressCity: city,
      addressZipCode: zip,
    };

    if (canSubmit) {
      submit(tempCustomer, isSaveInfo);
    }
  };

  return (
    <Section isFullHeight isLightGrey>
      <Form
        title={title}
        submit={submitForm}
      >
        <div className={formStyles.formControls}>
          <Input
            label="Numéro de TVA"
            placeholder="BE 0696.782.573"
            delimiters={[' ', '.']}
            blocks={[2, 4, 3, 3]}
            name={VAT}
            isValid={isVATNumberValid}
            value={vat}
            onChange={onChanged}
            required
            controlClassName={classNames({ 'is-loading': loading })}
            disabled={isLoading}
          />
          {showCompanyName()}
        </div>

        <div className={formStyles.formControls}>
          {renderAdditionalFields && renderAdditionalFields()}
        </div>

        <div className={formStyles.formControls}>
          <Input
            label="Nom de l'établissement"
            placeholder="Nom de l'établissement"
            name={COMPANY}
            value={establishmentName}
            onChange={onChanged}
            required
            disabled={isLoading}
          />

          <Select
            labelText="Type d'établissement"
            placeholder="Choisissez un type"
            name={TYPE}
            onChange={onChanged}
            value={type}
            required
            options={companyTypeOptions}
            disabled={isLoading}
          />
        </div>

        <div className={formStyles.formControls}>
          <Input
            type="email"
            label="Email"
            placeholder="Votre adresse email"
            name={EMAIL}
            onChange={onChanged}
            isValid={isEmailValid}
            value={email}
            required
            disabled={isLoading}
          />
        </div>

        <div className={formStyles.formControls}>
          <Input
            label="Numéro de téléphone"
            placeholder="0472 47 31 28"
            delimiters={[' ']}
            blocks={[4, 2, 2, 2]}
            name={PHONENUMBER}
            onChange={onChanged}
            value={phoneNumber}
            disabled={isLoading}
            required
          />
        </div>

        <div className={formStyles.formControls}>
          <Input
            label="Nom de la personne de contact"
            placeholder="Nom"
            name={LASTNAME}
            onChange={onChanged}
            value={lastName}
            disabled={isLoading}
            required
          />

          <Input
            label="Prénom de la personne de contact"
            placeholder="Prénom"
            name={FIRSTNAME}
            onChange={onChanged}
            value={firstName}
            disabled={isLoading}
            required
          />
        </div>

        <div className={formStyles.formControls}>
          <Input
            label="Rue et numéro"
            placeholder="Rue et numéro"
            name={STREET}
            onChange={onChanged}
            value={street}
            disabled={isLoading}
            required
          />

          <Input
            label="Ville"
            placeholder="Ville"
            name={CITY}
            onChange={onChanged}
            value={city}
            disabled={isLoading}
            required
          />

          <Input
            label="Code postal"
            placeholder="Code postal"
            name={ZIP}
            onChange={onChanged}
            value={zip}
            disabled={isLoading}
            required
          />
        </div>

        <div className={formStyles.formControls}>
          {showSaveBox()}
        </div>

        <div className={classNames(formStyles.formControls, formStyles.right)}>
          <Button
            type="submit"
            value={buttonValue}
            className={classNames('button is-dark', { 'is-loading': isLoading })}
            disabled={isLoading}
          />
        </div>
      </Form>
    </Section>
  );
};

export default SignUp;
