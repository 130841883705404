import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import Category from 'modules/products/models/Category.model';
import categoriesService from 'modules/products/services/categories.service';
import RootState from 'modules/shared/types/reducers/RootState';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

import CategoryModal from './CategoryModal';
import { getCategories } from '../category.selectors';
import CategoryCard from './CategoryCard';

const CategoriesContainer = ({ categories }: ReduxProps) => {
  const [editIsActive, setEditIsActive] = useState(false);
  const [category, setCategory] = useState<Category>();

  const updateCategory = useSafeApiCall(categoriesService.update);

  const goToEditCategory = (cat: Category) => {
    setCategory(cat);
    setEditIsActive(true);
  };

  const modalIsActive = () => {
    setEditIsActive(false);
  };

  return (
    <>
      {editIsActive && (
        <CategoryModal
          title="Éditer"
          manageCat={updateCategory}
          category={category}
          toggleModal={modalIsActive}
        />
      )}

      <hr />

      <div className="container">
        <ul className="columns is-multiline">
          {categories.length
            ? categories.map((cat) => (
              <CategoryCard
                key={cat.id}
                category={cat}
                handleEdit={goToEditCategory}
              />
            ))
            : (
              <div>
                <h2 className="mb-1 subtitle">Aucune catégorie enregistrée en base de données</h2>
                <p>
                  Cette situation peut indiquer un problème pour les utilisateurs de My Salesman :
                  aucune catégorie de produit n&apos;est sélectionnable lors de l&apos;
                  enregistrement d&apos;un nouveau produit. Veuillez ajouter des catégories
                  de produit au plus vite.
                </p>
              </div>
            )}
        </ul>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  categories: getCategories(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CategoriesContainer);
