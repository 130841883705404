import React, { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import classNames from 'classnames';

import Loading from 'modules/shared/components/Loading';
import useQuickForm from 'modules/shared/hooks/useQuickForm.hook';
import CheckBox from 'modules/shared/components/CheckBox';
import Button from 'modules/shared/components/Button';
import Input from 'modules/shared/components/Input';
import Select from 'modules/shared/components/Select';
import AddProductModel from 'modules/products/models/AddProduct.model';
import { getUserId } from 'modules/authentication/selectors/token.selectors';
import RootState from 'modules/shared/types/reducers/RootState';
import Filter from 'modules/shared/models/Filter.model';
import ImagePicker from 'modules/shared/components/ImagePicker/ImagePicker';
import { NotificationType } from 'modules/shared/models/Notification.model';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';
import Section from 'modules/shared/components/Section';
import Form from 'modules/shared/components/Form';
import * as filterMappers from 'modules/shared/mappers/filters.mappers';

import LanguagesTabs from '../components/LanguagesTabs';

import Product from '../models/Product.model';
import Language from '../models/Language.model';
import Tab from '../models/Tab.model';

import categoriesService from '../services/categories.service';
import zonesService from '../services/zones.service';
import languagesService from '../services/languages.service';

import styles from './ManageProduct.module.scss';
import Category from '../models/Category.model';

interface Props extends ReduxProps {
  prod?: Product;
  title?: string;
  buttonValue?: string;
  supplierId: string
  submitForm: (product: AddProductModel, image?: File) => void;
  processInProgress?: boolean;
  addNotification?: (type: NotificationType, text: string) => void
}

export interface Preview {
  preview: string,
  raw: File,
}

const createTab = (language: Language, prod: Product | undefined): Tab => ({
  language,
  name: (prod && prod.names.find((n) => n.languageId === language.id)!.value) || '',
  description: (prod && prod.descriptions.find((d) => d.languageId === language.id)!.value) || '',
  isValid: true,
});

const ManageProduct = (props: Props) => {
  const {
    prod,
    title,
    buttonValue,
    submitForm,
    tokenId,
    processInProgress: showLoader,
    addNotification,
    supplierId,
  } = props;

  // UseStates

  const [categories, setCategories] = useState<Category[]>([]);
  const [zones, setZones] = useState<Filter[]>([]);
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [preview, setPreview] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const getCategories = useSafeApiCall(categoriesService.getCategories);

  const getZones = useSafeApiCall(zonesService.getZones);

  const getLanguages = useSafeApiCall(languagesService.getLanguages);
  const getLanguageByCode = useSafeApiCall(languagesService.getLanguageByCode);

  // Init

  const getInitialFormState = () => {
    if (prod) {
      const {
        format,
        isVisible,
      } = prod;

      const {
        id: categoryId,
      } = prod.category;

      const {
        id: originId,
      } = prod.origin;

      return [
        format,
        originId,
        categoryId!,
        isVisible,
        null,
      ].map((value) => ({ value, isValid: true }));
    }
    return ['', '', '', false, null];
  };

  // UseForm

  const {
    fields: [FORMAT, ZONE, CATEGORY, IS_VISIBLE, IMAGE],
    getFieldValue,
    isValid,
    handleChange,
  } = useQuickForm(getInitialFormState());

  // UseEffects
  useEffect(() => {
    // Loads
    const loadCategories = async () => {
      try {
        setLoading(true);
        const [result, isAborted] = await getCategories();
        if (isAborted) {
          return;
        }

        setCategories(result);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        addNotification?.('error', err.messages);
      }
    };

    const loadZones = async () => {
      try {
        setLoading(true);
        const [result, isAborted] = await getZones();
        if (isAborted) {
          return;
        }

        setZones(result);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        addNotification?.('error', err.messages);
      }
    };

    const loadLanguages = async () => {
      try {
        setLoading(true);
        const [defaultLanguage, isFirstAborted] = await getLanguageByCode('FR');
        if (isFirstAborted) {
          return;
        }

        const [languages, isSecondAborted] = await getLanguages();
        if (isSecondAborted) {
          return;
        }

        const filteredLanguages = languages.filter((l) => defaultLanguage.id !== l.id);
        const newTabs = [defaultLanguage, ...filteredLanguages].map((lang, index) => {
          const tab = createTab(lang, prod);

          if (index !== 0) {
            return tab;
          }

          return {
            ...tab,
            isValid: false,
          };
        });

        setTabs(newTabs);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        addNotification?.('error', err.messages);
      }
    };

    const load = () => {
      loadCategories();
      loadZones();
      loadLanguages();
    };

    load();

    if (prod) {
      setPreview(prod.imageUrl);
    }
  }, [prod]);

  const format = getFieldValue<string>(FORMAT);
  const zone = getFieldValue<string>(ZONE);
  const category = getFieldValue<string>(CATEGORY);
  const isVisible = getFieldValue<boolean>(IS_VISIBLE);
  const image = getFieldValue<FileList>(IMAGE);

  const zoneFilterOptions = useMemo(() => zones.map(filterMappers.mapToSelectOption), [zones]);
  const categoryFilterOptions = useMemo(() => categories
    .map(filterMappers.mapCategoryToSelectOption), [categories]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // l'autorisation d'ajout d'un produit pour un admin se fera dans la branche "admin-supplier"
    // Juste après la validation de celle-ci
    if (!tokenId || !preview || !isValid) {
      return;
    }

    const product: AddProductModel = {
      userId: supplierId,
      names: tabs.map((tab) => ({ languageId: tab.language.id, value: tab.name })),
      descriptions: tabs.map((tab) => ({ languageId: tab.language.id, value: tab.description })),
      format,
      categoryId: category,
      isVisible,
      originId: zone,
    };

    if (image && image.length) {
      submitForm(product, image.item(0)!);
    } else {
      submitForm(product);
    }
  };

  const onTabValueChanged = (languageId: string, propName: string, value: string | boolean) => {
    const newTabs = tabs.map((t) => (
      t.language.id !== languageId
        ? t
        : { ...t, [propName]: value }
    ));
    setTabs(newTabs);
  };

  const onChange = (img: FileList, prev: string) => {
    setPreview(prev);
    handleChange(img, true, IMAGE);
  };

  if (!tabs.length) {
    return null;
  }

  // Component

  return (
    <Section isFullHeight isLightGrey>
      <Loading loading={loading}>
        <Form title={title!} submit={handleSubmit}>
          <div>
            <LanguagesTabs
              tabs={tabs}
              onValueChange={onTabValueChanged}
              onFormValidityChange={(id, validity) => onTabValueChanged(id, 'isValid', validity)}
              disableInputs={showLoader}
            />
            <hr />
            <div className="field">
              <div className="field is-horizontal">
                <div className="field-label is-normal is-left">
                  <label htmlFor="productIsVisible" className={classNames('label', styles.label)}>
                    <p>Format</p>
                  </label>
                </div>
                <div className="field-body">
                  <Input
                    type="text"
                    name={FORMAT}
                    value={format}
                    placeholder="Entrez les formats disponibles."
                    onChange={handleChange}
                    disabled={showLoader}
                  />
                </div>
              </div>
            </div>
            <hr />
            <Select
              labelText="Catégorie"
              options={categoryFilterOptions}
              placeholder="Selectionner une catégorie"
              labelClassName={styles.label}
              onChange={handleChange}
              name={CATEGORY}
              value={category}
              disabled={showLoader}
            />
            <hr />
            <Select
              labelText="Origine du produit"
              options={zoneFilterOptions}
              placeholder="Selectionner une origine"
              labelClassName={styles.label}
              onChange={handleChange}
              name={ZONE}
              value={zone}
              disabled={showLoader}
            />
            <hr />
            <div className="field">
              <div className="field is-horizontal">
                <div className="field-label is-normal is-left">
                  <label htmlFor="productIsVisible" className={classNames('label', styles.label)}>
                    Rendre le produit visible
                  </label>
                </div>
                <div className="field-body">
                  <CheckBox
                    onChange={handleChange}
                    name={IS_VISIBLE}
                    checked={isVisible}
                    disabled={showLoader}
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="field" id="file-container">
              <ImagePicker
                label="Logo"
                preview={preview}
                disabled={showLoader}
                name={IMAGE}
                onChange={onChange}
                labelClassName={styles.label}
                addNotification={addNotification}
              />
            </div>
            <hr />
            <div className="field">
              <Button
                type="submit"
                value={buttonValue}
                className="button is-primary"
                disabled={showLoader}
                showLoader={showLoader}
              />
            </div>
          </div>
        </Form>
      </Loading>
    </Section>
  );
};

const mapStateToProps = (state: RootState) => ({
  tokenId: getUserId(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ManageProduct);
