import React from 'react';

import Proposal from 'modules/requests/models/Proposal.model';

import { toPrettyDateRange } from '../utils/dates.utils';

interface Props {
  proposal?: Proposal;
  proposalIndex: number;
  toggleModal: () => void;
  onClick: () => void;
}

const AppointmentProposal = ({
  proposal,
  proposalIndex,
  toggleModal,
  onClick,
}: Props) => {
  const handleClick = () => {
    toggleModal();
    onClick();
  };

  return (
    <div>
      <span>{`Proposition ${proposalIndex} : `}</span>
      <a className="link" onClick={handleClick}>
        {proposal
          ? toPrettyDateRange(proposal.startDate, proposal.endDate)
          : 'à définir'}
      </a>
    </div>
  );
};

export default AppointmentProposal;
