import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { parse } from 'query-string';
import classNames from 'classnames';

import Product from 'modules/products/models/Product.model';
import Search from 'modules/products/models/Search.model';
import Section from 'modules/shared/components/Section';
import productsService from 'modules/products/services/products.service';
import Loading from 'modules/shared/components/Loading';
import useNotifications from 'modules/shared/hooks/useNotifications';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';
import requestsService from 'modules/requests/services/requests.service';
import AddContactRequest from 'modules/requests/models/AddContactRequest.model';
import { getIsConnected } from 'modules/shared/selectors/currentUser.selectors';
import { getUserId } from 'modules/authentication/selectors/token.selectors';
import Modal from 'modules/shared/components/Modal';
import BreadcrumbItem from 'modules/shared/models/BreadcrumbItem.model';
import Breadcrumb from 'modules/shared/components/Breadcrumb';

import ProductCard from '../ProductCard';
import SearchBar from '../SearchBar';
import styles from './SearchResult.module.scss';
import Filters from '../Filters';
import searchRoutes from '../search.routes';

const SearchResult = () => {
  const isConnected = useSelector(getIsConnected);
  const currentUserId = useSelector(getUserId);

  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [productCategories, setProductCategories] = useState<string[]>([]);
  const [productOrigins, setProductOrigins] = useState<string[]>([]);
  const [keywordParam, setKeywordParam] = useState('');

  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [isModalActive, setModalActive] = useState(false);
  const [items, setItems] = useState<BreadcrumbItem[]>([]);

  const history = useHistory();

  const { addNotification } = useNotifications();

  const getProducts = useSafeApiCall(productsService.getProducts);
  const addRequest = useSafeApiCall(requestsService.addRequest);

  const toggleModal = () => setModalActive(!isModalActive);

  const getSearchData = (
    keyword: string | null,
    categories: string[],
    origins: string[],
  ) => {
    const search: Search = {
      keyword: keyword || '',
      categories,
      origins,
    };
    return search;
  };

  const loadProducts = async (search: Search) => {
    try {
      setLoading(true);
      const [result, isAborted] = await getProducts(search);
      if (isAborted) {
        return;
      }
      setProducts(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      addNotification('error', error);
    }
  };

  const getCategories = (categories: string | null | undefined | string[]) => {
    if (!categories) {
      return [];
    }

    return Array.isArray(categories) ? categories : [categories];
  };

  const getOrigins = (origins: string | null | undefined | string[]) => {
    if (!origins) {
      return [];
    }

    return Array.isArray(origins) ? origins : [origins];
  };

  const handleContactButtonClick = (product: Product) => {
    setSelectedProduct(product);
    toggleModal();
  };

  const onContactSalesman = async () => {
    if (!selectedProduct) {
      return;
    }

    if (!isConnected) {
      history.push({
        pathname: searchRoutes.PRODUCT_REQUEST,
        search: `productId=${selectedProduct?.id}`,
      });

      return;
    }

    const addContactRequest: AddContactRequest = {
      productId: selectedProduct?.id!,
      userId: currentUserId!,
    };

    try {
      setLoading(true);
      const [, isAborted] = await addRequest(addContactRequest);
      if (isAborted) {
        return;
      }

      toggleModal();
      setLoading(false);
      addNotification('success', 'Demande bien envoyée !');
    } catch (err) {
      addNotification('error', err.messages);
    }
  };

  useEffect(() => {
    setLoading(true);
    const query = parse(location.search);
    const { categories, origins, keyword } = query;
    const catArray = getCategories(categories);
    const originArray = getOrigins(origins);

    setKeywordParam(keyword as string);
    setProductCategories(catArray as string[]);
    setProductOrigins(originArray as string[]);

    const searchData = getSearchData(
      keyword as string,
      catArray as string[],
      originArray as string[],
    );
    loadProducts(searchData);
    const breadcrumbItems: BreadcrumbItem[] = [
      { title: 'Produits', url: '#' },
      { title: keyword as string, url: '#' },
    ];
    setItems(breadcrumbItems);
  }, [location.search]);

  return (
    <Section hasAlwaysPadding>
      <Breadcrumb items={items} />
      <div className="container">
        <SearchBar inline inputValue={keywordParam} />
      </div>
      <hr />
      <Loading loading={isLoading}>
        {isModalActive && (
          <Modal
            title="Confirmation de demande de contact"
            actions={[
              {
                text: 'Confirmer',
                className: 'button is-success',
                onClick: onContactSalesman,
              },
              {
                text: 'Annuler',
                className: 'button is-dark',
                onClick: toggleModal,
              },
            ]}
          >
            Vous allez envoyer une demande de contact pour ce produit, êtes-vous sûr ?
          </Modal>
        )}
        <div className={styles.results}>
          <div className={styles.filters}>
            <Filters
              selectedCategories={productCategories}
              selectedOrigins={productOrigins}
              keyword={keywordParam}
            />
          </div>
          <div className={classNames('columns is-multiline flex-full-center-mobile', styles.products)}>
            {products.length
              ? products.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  handleContactRequest={handleContactButtonClick}
                />
              ))
              : (
                <div className="mt-4">
                  <h2 className="mb-1 subtitle">Aucun résultat ne correspond à votre recherche.</h2>
                  <p>
                    Vous pouvez élargir votre recherche en désactivant certains
                    filtres ou ajoutant des mots clés dans la barre de recherche.
                  </p>
                </div>
              )}
          </div>
        </div>
      </Loading>
    </Section>
  );
};

export default SearchResult;
