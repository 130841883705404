import React from 'react';
import { Link } from 'react-router-dom';
import Section from 'modules/shared/components/Section';
import searchRoutes from 'modules/search/components/search.routes';
import styles from './PageNotFound.module.scss';

const PageNotFound = () => (
  <Section>
    <div className={styles.pageNotFound}>
      <h1 className={styles.title}>404 - Page introuvable</h1>
      <p className={styles.description}>
        La page que vous souhaitez consulter n&apos;existe pas ou a été supprimée.
      </p>
      <div>
        <Link to={`${searchRoutes.MAIN_PAGE}`}>
          Retourner à l&apos;accueil du site
        </Link>
      </div>
    </div>
  </Section>
);

export default PageNotFound;
