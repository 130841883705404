import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import useSubPath from 'modules/shared/hooks/useSubPath.hook';
import AddSalesman from 'modules/salesmen/components/AddSalesman';

import RootState from 'modules/shared/types/reducers/RootState';
import { getUserId } from 'modules/authentication/selectors/token.selectors';
import supplierRoutes from '../supplier.routes';
import SupplierSalesmen from './SupplierSalesmen';
import ManageSalesmanRoutes from './ManageSalesmanRoutes';

const SalesmanRoutes = ({ id }: ReduxProps) => (
  <Switch>
    <Route path={useSubPath(supplierRoutes.NEW_SALESMAN)}>
      <AddSalesman supplierId={id!} />
    </Route>
    <Route path={useSubPath('/:salesmanId')}>
      <ManageSalesmanRoutes />
    </Route>
    <Route path={useSubPath('/')}>
      <SupplierSalesmen supplierId={id!} />
    </Route>
  </Switch>
);

const mapStateToProps = (state: RootState) => ({
  id: getUserId(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SalesmanRoutes);
