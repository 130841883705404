import React from 'react';
import Appointment from 'modules/requests/models/Appointment.model';
import { toPrettyDateRange } from 'modules/requests/utils/dates.utils';

interface Props {
  appointment: Appointment;
}

const CustomerAppointmentDetails = ({ appointment }: Props) => {
  const {
    startDate,
    endDate,
    contactRequest: {
      salesmanCompanyName,
      productName,
      productDescription,
    },
  } = appointment;

  return (
    <>
      <h2 className="has-text-weight-bold is-size-4">
        {toPrettyDateRange(new Date(startDate), new Date(endDate))}
      </h2>
      <p className="has-text-weight-bold mt-2 is-size-5">Entreprise :</p>
      <p>
        <span className="has-text-weight-bold">Nom : </span>
        {salesmanCompanyName}
      </p>
      <p className="has-text-weight-bold mt-2 is-size-5">Produit :</p>
      <p>
        <span className="has-text-weight-bold">Nom : </span>
        {productName}
      </p>
      <p>
        <span className="has-text-weight-bold">Description : </span>
        {productDescription}
      </p>
    </>
  );
};

export default CustomerAppointmentDetails;
