import React from 'react';

import Notif from 'modules/shared/models/Notification.model';
import Notification from 'modules/shared/components/Notification';
import styles from './NotificationContainer.module.scss';

interface Props {
  notif: Notif[];
  removeNotif: (id: string) => void;
}

const NotificationContainer = ({ notif, removeNotif }: Props) => (
  <div className={styles.NotificationContainer}>
    {notif.map((n) => (
      <Notification
        key={n.id}
        onCloseClick={() => removeNotif(n.id)}
        text={n.text}
        type={n.type}
      />
    ))}
  </div>
);

export default NotificationContainer;
