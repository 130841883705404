import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import styles from './ChooseBox.module.scss';

interface Props {
  title: string,
  text: string,
  to: string,
}

const ChooseBox = (props: Props) => {
  const {
    title,
    text,
    to,
  } = props;

  return (
    <Link to={to} className={classNames('box', styles.column)}>
      <h1 className={classNames('title is-1 has-text-centered', styles.title)}>{title}</h1>
      <h4 className={classNames('title is-4 has-text-centered', styles.text)}>
        {text}
      </h4>
    </Link>
  );
};

export default ChooseBox;
