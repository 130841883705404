const SUPPLIER = '/supplier';
const PRODUCTS = '/products';
const ADD_PRODUCT = '/add';
const EDIT_PRODUCT = '/edit';
const SALESMEN = '/salesmen';
const NEW_SALESMAN = '/add';
const EDIT_SALESMAN = '/edit';
const REQUESTS = '/requests';

export default {
  SUPPLIER,
  PRODUCTS,
  ADD_PRODUCT,
  EDIT_PRODUCT,
  SALESMEN,
  NEW_SALESMAN,
  EDIT_SALESMAN,
  REQUESTS,
};
