import React, { PropsWithChildren } from 'react';

import styles from './Form.module.scss';

interface Props {
  title: string;
  submit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const Form = ({ title, submit, children }: PropsWithChildren<Props>): JSX.Element => (
  <form onSubmit={submit} className={styles.form}>
    <h2 className={styles.title}>{title}</h2>
    {children as JSX.Element}
  </form>
);

export default Form;
