import React from 'react';

import Product from 'modules/products/models/Product.model';

import Button from '../../shared/components/Button';
import Card from '../../shared/components/Card';
import IconText from '../../shared/components/IconText';
import AddProduct from '../models/AddProduct.model';

interface Props {
  product: Product;
  goToEditProduct: (product: Product) => void;
  goToDeleteProduct: (productId: string) => void;
  onProductUpdate: (product: AddProduct, productId: string) => void;
}

const renderVisibleButton = (
  product: Product,
  onProductUpdate: (product: AddProduct, productId: string) => void,
) => {
  const addProduct = {
    id: product.id,
    names: product.names,
    descriptions: product.descriptions,
    format: product.format,
    categoryId: product.category.id,
    isVisible: !product.isVisible,
    userId: product.supplier.user.id,
    originId: product.origin.id,
  };
  const eye = product.isVisible ? 'fa-eye' : 'fa-eye-slash';
  return (
    <Button
      className="button is-ghost is-small"
      style={{ width: '32px' }}
      type="button"
      iconName={eye}
      onClick={() => onProductUpdate(addProduct, product.id)}
    />
  );
};

const ProductCard = (
  {
    product,
    goToEditProduct,
    goToDeleteProduct,
    onProductUpdate,
  }: Props,
) => (
  <li className="column is-full-tablet is-one-third-desktop">
    <div className="card is-fullheight">
      <Card
        title={product.name}
        rightElement={renderVisibleButton(product, onProductUpdate)}
      >
        <IconText iconName="fa-info-circle">
          {product.format}
        </IconText>
        <IconText iconName="fa-coffee">
          {product.category.label}
        </IconText>
        <IconText iconName="fa-map-marker-alt">
          {product.origin.name}
        </IconText>
      </Card>
      <div className="card-footer">
        <Button
          style={{ margin: '10px' }}
          value="Éditer"
          className="button is-dark is-small is-fullwidth"
          iconName="fa-edit"
          type="button"
          onClick={() => goToEditProduct(product)}
        />
        <Button
          className="button is-danger is-small"
          style={{ margin: '10px', width: '100px' }}
          type="button"
          iconName="fa-trash"
          onClick={() => goToDeleteProduct(product.id)}
        />
      </div>
    </div>
  </li>
);

export default ProductCard;
