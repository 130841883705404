import React, { useState } from 'react';
import { stringify } from 'query-string';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import Button from 'modules/shared/components/Button';
import categoriesService from 'modules/products/services/categories.service';
import zonesService from 'modules/products/services/zones.service';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

import styles from './Filters.module.scss';
import searchRoutes from '../search.routes';
import Filter from '../Filter';

interface Props {
  selectedCategories: string[];
  selectedOrigins: string[];
  keyword: string;
}

const Filters = ({ selectedCategories, selectedOrigins, keyword }: Props) => {
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();

  const getCategoriesFilters = useSafeApiCall(categoriesService.getCategoriesFilter);

  const getZones = useSafeApiCall(zonesService.getZones);

  const addFilter = (filters: any) => {
    // On push les paramètres à l'url
    history.push({
      pathname: searchRoutes.SEARCH_RESULT,
      search: `?${stringify({ ...filters, keyword })}`,
    });
  };

  const toggleFilters = () => {
    setIsActive(!isActive);
  };

  const numberOfFilters = selectedOrigins.length + selectedCategories.length;

  return (
    <div>
      <div className={styles.filtersButton}>
        <Button
          type="button"
          onClick={toggleFilters}
          className="button is-small"
          value={`Filtres (${numberOfFilters})`}
        />
      </div>
      <div className={classNames(styles.filters, { [styles.active]: isActive })}>
        <Filter
          selectedFilters={selectedCategories}
          filterName="catégorie"
          loadFilters={getCategoriesFilters}
          onFilterClicked={(categories) => addFilter({ categories, origins: selectedOrigins })}
        />
        <hr />
        <Filter
          selectedFilters={selectedOrigins}
          filterName="origine"
          loadFilters={getZones}
          onFilterClicked={(origins) => addFilter({ categories: selectedCategories, origins })}
        />
      </div>
    </div>
  );
};

export default Filters;
