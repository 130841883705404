import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import { randomString } from '../utils/strings.utils';

interface Props {
  className?: string;
  placeholder?: string;
  value: string;
  name?: string;
  onChange?: (value: string, isValid: boolean, name?: string) => void;
  required?: boolean;
  isValid?: (text: string) => boolean;
  disabled?: boolean;
}

const TextArea = (props: Props) => {
  const {
    className,
    placeholder,
    onChange,
    value,
    name,
    isValid,
    required,
    disabled,
  } = props;

  const id = useRef(randomString());
  const [isInvalid, setIsInvalid] = useState(false);

  const handleChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = e.target.value;

    if (!onChange) {
      return;
    }

    const requiredValid = !required || !!val;

    if (isValid) {
      const valid = requiredValid && isValid(val);
      setIsInvalid(!valid);
      onChange(val, valid, name);
    } else {
      setIsInvalid(!requiredValid);
      onChange(val, requiredValid, name);
    }
  };

  return (
    <textarea
      id={id.current}
      className={classNames('textarea', className, { 'is-danger': isInvalid })}
      placeholder={placeholder}
      onChange={handleChangeText}
      value={value}
      name={name}
      disabled={disabled}
    />
  );
};

export default TextArea;
