import React from 'react';

import SupplierReceived from 'modules/account/models/SupplierReceived.model';

import Button from '../../shared/components/Button';
import Card from '../../shared/components/Card';
import IconText from '../../shared/components/IconText';

interface Props {
  supplier: SupplierReceived,
  goToEditSupplier: (supplier: SupplierReceived) => void;
  goToSupplierProducts: (supplier: SupplierReceived) => void;
  validateSupplier: (supplier: SupplierReceived) => void;
}

const SupplierCard = ({
  supplier,
  goToEditSupplier,
  goToSupplierProducts,
  validateSupplier,
}: Props) => {
  const renderEmailConfirmed = (supplierReceived: SupplierReceived) => {
    const fas = supplierReceived.user.emailConfirmed ? 'fas fa-check' : 'fas fa-times';
    const status = supplierReceived.user.emailConfirmed ? 'vérifiée' : 'non vérifiée';

    return (
      <IconText iconName={fas}>
        Adresse e-mail
        {' '}
        {status}
      </IconText>
    );
  };

  const renderValidationButton = (supplierReceived: SupplierReceived) => {
    if (!supplierReceived.user.emailConfirmed) {
      return (
        <Button
          style={{ margin: '10px' }}
          className="button is-success"
          iconName="fa-user-check"
          type="button"
          onClick={() => validateSupplier(supplierReceived)}
        />
      );
    }

    return null;
  };

  return (
    <li className="column is-full-tablet is-one-third-desktop">
      <div className="card is-fullheight">
        <Card title={`${supplier.user.lastName} ${supplier.user.firstName}`}>
          <IconText iconName="fa-user-circle">
            {supplier.company.vat}
          </IconText>
          <IconText iconName="fa-phone">
            <a href={`tel:${supplier.user.phoneNumber}`}>
              {supplier.user.phoneNumber}
            </a>
          </IconText>
          {renderEmailConfirmed(supplier)}
        </Card>
        <div className="card-footer">
          <Button
            style={{ margin: '10px' }}
            value="Éditer"
            className="button is-dark is-small is-fullwidth"
            iconName="fa-edit"
            type="button"
            onClick={() => goToEditSupplier(supplier)}
          />
          <Button
            style={{ margin: '10px' }}
            value="Produits"
            className="button is-dark is-small is-fullwidth"
            iconName="fa-wine-glass"
            type="button"
            onClick={() => goToSupplierProducts(supplier)}
          />
          {renderValidationButton(supplier)}
        </div>
      </div>
    </li>
  );
};

export default SupplierCard;
