import { createSelector } from 'reselect';

import RootState from 'modules/shared/types/reducers/RootState';
import { decodeToken } from '../utils/jwt.utils';

const tokenSelector = ({ token }: RootState) => token.token;

export const getDecodedToken = createSelector(
  [tokenSelector],
  (token) => (token ? decodeToken(token) : null),
);

export const getUserId = createSelector(
  [getDecodedToken],
  (token) => (token ? token.id : null),
);

export const getUserEmail = createSelector(
  [getDecodedToken],
  (token) => (token ? token.name : null),
);
