import React, { useEffect, useRef, useState } from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';

import { FieldValue } from 'modules/shared/hooks/useForm.hook';
import usePrevious from 'modules/shared/hooks/usePrevious.hook';

import { randomString } from '../../utils/strings.utils';
import SelectOption from '../../models/SelectOption.model';
import GroupSelectOption from '../../models/GroupSelectOption.model';

import inputStyles from '../Input/Input.module.scss';
import { groupStyles, customStyles, groupBadgeStyles } from './SelectStyles';

interface Props {
  selectClassName?: string;
  labelClassName?: string;
  labelText?: string;
  options: SelectOption[] | GroupSelectOption[];
  onChange: (value: FieldValue, isValid: boolean, name?: string) => void;
  required?: boolean;
  name?: string;
  value?: FieldValue;
  placeholder?: string;
  isSearchable?: boolean;
  disabled?: boolean;
}

const formatGroupLabel = ({ label, options }: GroupSelectOption) => (
  <div style={groupStyles}>
    <span>{label}</span>
    <span style={groupBadgeStyles}>{options.length}</span>
  </div>
);

const Select = (props: Props) => {
  const {
    selectClassName,
    labelClassName,
    labelText,
    options,
    onChange,
    required,
    name,
    value,
    placeholder,
    isSearchable,
    disabled,
  } = props;

  const getSelectedValue = () => {
    if (!options.length) {
      return undefined;
    }

    const firstElement = options[0];

    const groupOptions = (firstElement as GroupSelectOption).options;

    if (groupOptions) {
      if (!value) {
        return groupOptions[0];
      }

      const flattenOptions = (options as GroupSelectOption[])
        .reduce((acc: SelectOption[], curr: GroupSelectOption) => [
          ...acc,
          ...curr.options,
        ], []);

      return flattenOptions.find((o) => o.value === value);
    }

    const selectOptions = (options as SelectOption[]);

    return selectOptions.find((o) => o.value === value);
  };

  const id = useRef(randomString());
  const [selected, setSelected] = useState(getSelectedValue);
  const previousSelected = usePrevious(selected);
  const previousValue = usePrevious(value);
  const [optionsString, setOptionsString] = useState(() => JSON.stringify(options));
  const previousOptionsString = usePrevious(optionsString);

  useEffect(() => {
    const requiredValid = !required || !!selected;
    if (selected && selected !== previousSelected) {
      onChange(selected.value, requiredValid, name);
    }
  }, [selected]);

  useEffect(() => {
    if (value !== previousValue || optionsString !== previousOptionsString) {
      setSelected(getSelectedValue());
    }
  }, [value, optionsString]);

  useEffect(() => {
    const serializedOptions = JSON.stringify(options);

    if (serializedOptions !== optionsString) {
      setOptionsString(serializedOptions);
    }
  }, [options]);

  const handleChangeSelect = (val: SelectOption | null) => {
    if (!val) {
      return;
    }

    setSelected(val);
  };

  return (
    <div className={inputStyles.formControl}>
      {labelText && (
        <label className={classNames('label', labelClassName)} htmlFor={id.current}>
          {labelText}
        </label>
      )}

      <ReactSelect
        inputId={id.current}
        placeholder={placeholder}
        options={options}
        value={selected}
        isSearchable={isSearchable}
        onChange={handleChangeSelect}
        isDisabled={disabled}
        openMenuOnFocus
        closeMenuOnSelect
        formatGroupLabel={formatGroupLabel}
        styles={customStyles}
        className={selectClassName}
      />
    </div>
  );
};

export default Select;
