import { AnyAction } from 'redux';

import TokenState from 'modules/shared/types/reducers/TokenState';
import { REFRESH_TOKEN_SUCCESS } from 'modules/shared/types/actions/httpService.actionTypes';

import { SIGN_IN_SUCCESS, SIGN_OUT } from './auth.actionTypes';

const initialState: TokenState = {
  token: null,
  refreshToken: null,
};

export default (state: TokenState = initialState, action: AnyAction): TokenState => {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
    case REFRESH_TOKEN_SUCCESS:
      return {
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};
