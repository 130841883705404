import React, { useState, useEffect } from 'react';

import FilterModel from 'modules/shared/models/Filter.model';
import CheckBox from 'modules/shared/components/CheckBox';
import useNotifications from 'modules/shared/hooks/useNotifications';

interface InnerFilter extends FilterModel {
  checked: boolean;
}

interface Props {
  filterName: string;
  loadFilters: () => Promise<[FilterModel[], boolean]>;
  selectedFilters: string[];
  onFilterClicked: (filters: string[]) => void;
}

const Filter = (props: Props) => {
  const {
    filterName,
    loadFilters,
    selectedFilters,
    onFilterClicked,
  } = props;
  const [filters, setFilters] = useState<InnerFilter[]>([]);
  const [loading, setLoading] = useState(false);

  const { addNotification } = useNotifications();

  const load = async () => {
    setLoading(true);
    try {
      const [results, isAborted] = await loadFilters();
      if (isAborted) {
        return;
      }

      const mapped = results.map((c) => ({
        ...c,
        checked: selectedFilters.some((sc) => sc === c.id),
      }));

      setFilters(mapped);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  useEffect(() => {
    load();
  }, [selectedFilters]);

  const addFilter = (filter: string) => {
    const selected = filters
      .map((f) => (f.id !== filter ? f : ({ ...f, checked: !f.checked })))
      .filter((f) => f.checked);

    onFilterClicked(selected.map((c) => c.id));
  };

  if (loading) {
    return null;
  }

  return (
    <div>
      <p className="has-text-weight-bold">
        {`Filtrer par ${filterName}`}
      </p>
      <div className="mt-5">
        {filters.map((item) => (
          <CheckBox
            key={item.id}
            onChange={() => addFilter(item.id)}
            labelText={item.name}
            checked={item.checked}
          />
        ))}
      </div>
    </div>
  );
};

export default Filter;
