import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Navbar from 'modules/app/components/Navbar';
import store from 'modules/app/store';
import httpService from 'modules/shared/services/http.service';

import AppRoutes from './AppRoutes';
import NotificationContextProvider from '../NotificationContext';

httpService.init(store.store);

const App = () => (
  <BrowserRouter>
    <Provider store={store.store}>
      <PersistGate persistor={store.persistor}>
        <NotificationContextProvider>
          <Navbar />
          <AppRoutes />
        </NotificationContextProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

export default App;
