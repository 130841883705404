import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './Section.module.scss';

interface Props {
  isFullHeight?: boolean;
  isLightGrey?: boolean;
  isCentered?: boolean;
  hasAlwaysPadding?: boolean;
  className?: string,
  containerClassName?: string,
}

const Section = (props: PropsWithChildren<Props>) => {
  const {
    isFullHeight,
    isLightGrey,
    isCentered,
    hasAlwaysPadding,
    className,
    containerClassName,
    children,
  } = props;

  return (
    <section className={classNames(
      'section',
      styles.customSection,
      {
        'full-height-with-navbar': isFullHeight,
        'has-bg-grey-light': isLightGrey,
        'flex-full-center': isCentered,
        [styles.hasAlwaysPadding]: hasAlwaysPadding,
      },
      className,
    )}
    >
      <div className={classNames('container', containerClassName)}>
        {children}
      </div>
    </section>
  );
};

export default Section;
