import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isSameDay } from 'date-fns';

import ContactRequest from 'modules/requests/models/ContactRequest.model';
import requestSalesmanService from 'modules/requests/services/requests.service';
import Section from 'modules/shared/components/Section';
import useNotifications from 'modules/shared/hooks/useNotifications';
import Button from 'modules/shared/components/Button';
import Loading from 'modules/shared/components/Loading';
import Proposal from 'modules/requests/models/Proposal.model';
import AddAppointment from 'modules/requests/models/AddAppointment.model';
import appointmentService from 'modules/requests/services/appointment.service';
import Appointment from 'modules/requests/models/Appointment.model';
import { getUserId } from 'modules/authentication/selectors/token.selectors';
import Zone from 'modules/products/models/Zone.model';
import zonesService from 'modules/products/services/zones.service';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

import { NUMBER_OF_PROPOSALS } from '../../constants/requests.constant';
import styles from './ManageRequests.module.scss';
import CustomerInfo from '../CustomerInfo';
import ProductInfo from '../ProductInfo';
import ProposalModal from '../ProposalModal';
import RequestPanel from '../RequestPanel';
import SalesmanChoice from '../SalesmanChoice';
import AppointmentProposals from '../AppointmentProposals';

interface Props {
  salesmanUserId?: string;
}

const RequestManagement = ({ salesmanUserId }: Props) => {
  const supplierUserId = useSelector(getUserId);

  const [isLoading, setLoading] = useState(false);
  const [isModalActive, setModalActive] = useState(false);

  const [requests, setRequests] = useState<ContactRequest[]>([]);
  const [selectedRequest, setSelectedRequest] = useState<ContactRequest>();

  const [zoneSalesmen, setZoneSalesmen] = useState<Zone[]>([]);
  const [selectedSalesmanId, setSelectedSalesmanId] = useState<string>();

  const [salesmanAppointments, setSalesmanAppointments] = useState<Appointment[]>([]);

  const [proposals, setProposals] = useState<Proposal[]>([]);

  const [selectedProposalIndex, setSelectedProposalIndex] = useState(-1);

  const isSelectedRequest = (request: ContactRequest) => request.id === selectedRequest?.id;

  const { addNotification } = useNotifications();

  const getSupplierRequests = useSafeApiCall(requestSalesmanService.getSupplierRequests);
  const getSalesmanRequests = useSafeApiCall(requestSalesmanService.getRequestsBySalesman);

  const getPrioritizedZones = useSafeApiCall(zonesService.getPrioritizedZones);

  const getSalesmanAppointments = useSafeApiCall(appointmentService.getBySalesman);
  const addAppointment = useSafeApiCall(appointmentService.addAppointment);

  const loadRequets = async () => {
    try {
      setLoading(true);
      const [result, isAborted] = await getSupplierRequests(supplierUserId!);
      if (isAborted) {
        return;
      }

      setRequests(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  const loadSalesmanRequests = async (userId: string) => {
    try {
      setLoading(true);
      const [result, isAborted] = await getSalesmanRequests(userId);
      if (isAborted) {
        return;
      }

      setRequests(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  const loadSalesmen = async (contactRequestId: string) => {
    try {
      setLoading(true);
      const [result, isAborted] = await getPrioritizedZones(supplierUserId!, contactRequestId);
      if (isAborted) {
        return;
      }

      setZoneSalesmen(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  const loadSalesmenAppointments = async (userId: string) => {
    try {
      const [result, isAborted] = await getSalesmanAppointments(userId);
      if (isAborted) {
        return;
      }

      setSalesmanAppointments(result);
    } catch (err) {
      addNotification('error', err.messages);
    }
  };

  const toggleModal = () => setModalActive(!isModalActive);

  const addProposal = (start: Date, end: Date) => {
    if (!isSameDay(start, end)) {
      addNotification('warning', 'La proposition de rendez-vous doit avoir lieu le même jour !');
      return;
    }

    const newProposal = {
      startDate: start,
      endDate: end,
    };
    if (proposals.length <= selectedProposalIndex) {
      setProposals([...proposals, newProposal]);
      toggleModal();
      return;
    }
    const newProposals = proposals.map((proposal: Proposal, index: number) => {
      if (index === selectedProposalIndex) {
        return newProposal;
      }
      return proposal;
    });

    setProposals(newProposals);
    toggleModal();
  };

  const submitProposals = async () => {
    const errors = [];

    // If the supplier hasn't selected a salesman
    if (!salesmanUserId && !selectedSalesmanId) {
      errors.push('Veuillez sélectionner un représentant');
    }

    if (proposals.length !== NUMBER_OF_PROPOSALS) {
      errors.push('Veuillez établir 3 propositions');
    }

    if (!selectedRequest) {
      errors.push('Veuillez sélectionner une demande');
    }

    if (errors.length) {
      addNotification('warning', errors);
      return;
    }

    const appointment: AddAppointment = {
      contactRequestId: selectedRequest?.id!,
      salesmanUserId: salesmanUserId || selectedSalesmanId!,
      timeSlots: proposals,
    };

    try {
      const [, isAborted] = await addAppointment(appointment);
      if (isAborted) {
        return;
      }

      addNotification('success', 'Propositions soumises avec succès');
    } catch (err) {
      addNotification('error', err.messages);
    }
  };

  const handleSalesmanChange = (id: string) => {
    setSelectedSalesmanId(id);
  };

  const handleSelectProposal = (index: number) => setSelectedProposalIndex(index);

  useEffect(() => {
    if (salesmanUserId) {
      loadSalesmanRequests(salesmanUserId);
      loadSalesmenAppointments(salesmanUserId);
    } else {
      loadRequets();
    }
  }, []);

  useEffect(() => {
    if (!salesmanUserId && selectedRequest?.id) {
      loadSalesmen(selectedRequest.id);
    }
  }, [selectedRequest]);

  useEffect(() => {
    if (zoneSalesmen?.length && zoneSalesmen[0]?.salesmen?.length) {
      const firstSalesman = zoneSalesmen[0].salesmen[0];
      handleSalesmanChange(firstSalesman.user.id!);
    }
  }, [zoneSalesmen]);

  useEffect(() => {
    if (selectedSalesmanId) {
      loadSalesmenAppointments(selectedSalesmanId);
    }
  }, [selectedSalesmanId]);

  const renderSalesmanChoice = () => {
    if (salesmanUserId) {
      return null;
    }

    if (selectedRequest?.salesmanId) {
      return null;
    }

    return (
      <div className={styles.box}>
        <SalesmanChoice
          salesmen={zoneSalesmen!}
          handleSalesmanChange={handleSalesmanChange}
        />
      </div>
    );
  };

  return (
    <Loading loading={isLoading}>
      <Section isFullHeight isLightGrey>
        <ProposalModal
          isActive={isModalActive}
          addProposal={addProposal}
          toggleModal={toggleModal}
          events={salesmanAppointments}
        />
        <div className={classNames(styles.container, 'columns', 'is-flex-desktop-only')}>
          <div className="column is-4">
            <RequestPanel
              requests={requests}
              isSelectedRequest={isSelectedRequest}
              setSelectedRequest={setSelectedRequest}
            />
          </div>
          <div className="column is-8">
            {renderSalesmanChoice()}
            <div className={styles.box}>
              <CustomerInfo request={selectedRequest!} />
            </div>
            <div className={styles.box}>
              <ProductInfo request={selectedRequest!} />
            </div>
            {selectedRequest && (
              <div className={styles.box}>
                <AppointmentProposals
                  proposals={proposals}
                  selectedContactRequest={selectedRequest!}
                  toggleModal={toggleModal}
                  onProposalClick={handleSelectProposal}
                />
              </div>
            )}
            <Button
              type="button"
              className="button is-success mt-4 is-fullwidth"
              value="Proposer"
              onClick={submitProposals}
              disabled={!selectedRequest || !!selectedRequest?.salesmanId}
            />
          </div>
        </div>
      </Section>
    </Loading>
  );
};

export default RequestManagement;
