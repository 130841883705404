import Appointment from 'modules/requests/models/Appointment.model';

import CalendarEvent from 'modules/shared/models/CalendarEvent.model';

const mapToSalesmanCalendarEvent = ({
  id,
  contactRequest,
  startDate,
  endDate,
  appointmentStatusId,
}: Appointment): CalendarEvent => ({
  id,
  title: `
    ${contactRequest.customerLastName}
    ${' '}
    ${contactRequest.customerFirstName} 
    ${' '}
    (${contactRequest.productName})
  `,
  start: new Date(startDate),
  end: new Date(endDate),
  status: appointmentStatusId,
  appointment: {
    id,
    contactRequest,
    startDate,
    endDate,
    appointmentStatusId,
  },
});

const mapToCustomerCalendarEvent = (appointment: Appointment): CalendarEvent => ({
  id: appointment.id,
  title: appointment.contactRequest.productName,
  start: new Date(appointment.startDate),
  end: new Date(appointment.endDate),
  status: appointment.appointmentStatusId,
  appointment: { ...appointment },
});

export default {
  mapToSalesmanCalendarEvent,
  mapToCustomerCalendarEvent,
};
