import { Dispatch } from 'redux';

import createAction from 'modules/shared/utils/createAction.util';
import categoriesService from 'modules/products/services/categories.service';
import httpService from 'modules/shared/services/http.service';

import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAILED,
} from './category.actionTypes';

export const loadCategories = () => async (dispatch: Dispatch) => {
  dispatch(createAction(LOAD));
  try {
    const result = await categoriesService.getCategories(httpService)();
    dispatch(createAction(LOAD_SUCCESS, result));
  } catch (error) {
    dispatch(createAction(LOAD_FAILED, error));
  }
};
