import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Product from 'modules/products/models/Product.model';
import Button from 'modules/shared/components/Button';

import searchRoutes from '../search.routes';
import styles from './ProductCard.module.scss';

export interface Props {
  product: Product;
  handleContactRequest: (product: Product) => void;
}

const ProductCard = ({ product, handleContactRequest }: Props) => (
  <div className={styles.cardLink}>
    <div className={classNames('card', styles.card)}>
      <div className="card-content">
        <Link to={`${searchRoutes.PRODUCT_DESCRIPTION}?id=${product.id}`}>
          <div className="card-image">
            <figure className="image is-16by9">
              <img src={product.imageUrl} alt={product.name} className={styles.image} />
            </figure>
          </div>
          <div className="media">
            <div className="media-content">
              <div>
                <p className="title is-6 mt-2">
                  {product.name.substring(0, 50)}
                </p>
              </div>
              <div>
                <p className="mt-4">{product.category.label}</p>
              </div>
            </div>
          </div>
        </Link>
        <hr />
        <div className="content">
          <div className="mt-4">
            <Button
              type="button"
              className="button is-success is-centered is-fullwidth"
              value="Contactez-moi"
              onClick={() => handleContactRequest(product)}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ProductCard;
