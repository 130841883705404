import React, { useState, useEffect } from 'react';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';

import AddContactRequest from 'modules/requests/models/AddContactRequest.model';
import AddCustomer from 'modules/customers/models/AddCustomer.model';
import requestSalesmanService from 'modules/requests/services/requests.service';
import customerServices from 'modules/customers/services/customer.service';
import Customer from 'modules/customers/models/Customer.model';
import useNotifications from 'modules/shared/hooks/useNotifications';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

import SignUp from './SignUp';

const CustomerSignUp = () => {
  const [productId, setProductId] = useState('');
  const [showSaveBox, setShowSaveBox] = useState(!!productId);
  const [isLoading, setLoading] = useState(false);

  const location = useLocation();

  const { addNotification } = useNotifications();

  const addRequest = useSafeApiCall(requestSalesmanService.addRequest);

  const addCustomer = useSafeApiCall(customerServices.addCustomer);

  useEffect(() => {
    const { productId: pId } = parse(location.search);
    if (pId && !Array.isArray(pId)) {
      setProductId(pId as string);
      setShowSaveBox(true);
    }
  }, []);

  const createContactRequest = async (customer: AddCustomer) => {
    const request: AddContactRequest = {
      productId,
      userId: customer.id,
    };

    return addRequest(request);
  };

  const sendRequest = async (tmpCust: Customer, confirmation: boolean) => {
    try {
      setLoading(true);
      const [tempCustomer, isFirstAborted] = await addCustomer(tmpCust, confirmation);
      if (isFirstAborted) {
        return;
      }

      if (productId) {
        const [, isSecondAborted] = await createContactRequest(tempCustomer);
        if (isSecondAborted) return;
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      addNotification('error', error.messages);
    }
  };

  return (
    <SignUp
      submit={sendRequest}
      buttonValue={productId ? 'Faire une demande' : 'S\'inscrire'}
      title={productId ? 'Faire une demande' : 'S\'inscrire'}
      showSaveCheckBox={showSaveBox}
      isLoading={isLoading}
    />
  );
};

export default CustomerSignUp;
