import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import AddProduct from 'modules/products/components/AddProduct';
import useSubPath from 'modules/shared/hooks/useSubPath.hook';
import SupplierProducts from 'modules/suppliers/components/SupplierProducts';
import supplierRoutes from 'modules/suppliers/supplier.routes';

import ManageSupplierProductRoutes from './ManageSupplierProductRoutes';

const ManageProductsRoutes = () => {
  const match = useRouteMatch<{ supplierId: string, productId: string }>();
  const { params, path } = match;
  const { supplierId } = params;

  return (
    <Switch>
      <Route path={useSubPath(supplierRoutes.ADD_PRODUCT)}>
        <AddProduct supplierId={supplierId} />
      </Route>
      <Route path={useSubPath('/:productId')}>
        <ManageSupplierProductRoutes />
      </Route>
      <Route path={path}>
        <SupplierProducts supplierId={supplierId} />
      </Route>
    </Switch>
  );
};

export default ManageProductsRoutes;
