import React, { useEffect, useMemo, useState } from 'react';

import zonesService from 'modules/products/services/zones.service';
import LicensePrice from 'modules/salesmen/models/LicensePrice.model';
import PaymentMethod from 'modules/salesmen/models/PaymentMethod.model';
import licensesPriceService from 'modules/salesmen/services/licensesPrice.service';
import paymentMethodsService from 'modules/salesmen/services/paymentMethods.service';
import Loading from 'modules/shared/components/Loading';
import Section from 'modules/shared/components/Section';
import { provinces as initialProvinces } from 'modules/salesmen/constants/belgiumMap.constants';
import useNotifications from 'modules/shared/hooks/useNotifications';
import useQuickForm from 'modules/shared/hooks/useQuickForm.hook';
import {
  mapLicensePriceToSelectOption,
  mapPaymentMethodToSelectOption,
  mapToSelectOption,
} from 'modules/shared/mappers/filters.mappers';
import Filter from 'modules/shared/models/Filter.model';
import ProvinceMap from 'modules/salesmen/models/ProvinceMap.model';
import Zone from 'modules/products/models/Zone.model';
import { FieldValue } from 'modules/shared/hooks/useForm.hook';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';
import Modal from 'modules/shared/components/Modal';

import AddSalesman from '../models/AddSalesman.model';
import Salesman from '../models/Salesman.model';
import licenseService from '../services/licenses.service';

import SalesmanForm from './SalesmanForm';
import salesmenService from '../services/salesmen.service';

interface Props {
  salesman?: Salesman;
  supplierUserId: string;
  title: string;
  buttonValue: string;
  submitForm: (salesman: AddSalesman) => void;
}

const ManageSalesman = ({
  salesman,
  supplierUserId,
  title,
  buttonValue,
  submitForm,
}: Props) => {
  const { addNotification } = useNotifications();

  const getInitialFormState = () => {
    if (salesman) {
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
      } = salesman.user;

      const {
        licensePrice: {
          id: licensePriceId,
          license: { id: licenseId },
        },
        licenseOrder: { paymentMethodId },
      } = salesman.salesmanLicense;

      return [
        firstName,
        lastName,
        email,
        phoneNumber,
        licenseId,
        licensePriceId,
        paymentMethodId,
      ].map((value) => ({ value, isValid: true }));
    }

    return ['', '', '', '', '', '', ''];
  };

  const {
    fields: [
      FIRSTNAME,
      LASTNAME,
      EMAIL,
      PHONENUMBER,
      LICENSE_ID,
      LICENSE_PRICE_ID,
      PAYMENT_METHOD_ID,
    ],
    getFieldValue,
    handleChange,
    isValid,
  } = useQuickForm(getInitialFormState());

  const firstName = getFieldValue<string>(FIRSTNAME);
  const lastName = getFieldValue<string>(LASTNAME);
  const email = getFieldValue<string>(EMAIL);
  const phoneNumber = getFieldValue<string>(PHONENUMBER);
  const licenseId = getFieldValue<string>(LICENSE_ID);
  const licensePriceId = getFieldValue<string>(LICENSE_PRICE_ID);
  const paymentMethodId = getFieldValue<string>(PAYMENT_METHOD_ID);

  const [licenses, setLicenses] = useState<Filter[]>([]);
  const [licensePrices, setLicensePrices] = useState<LicensePrice[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [price, setPrice] = useState<number | null>();

  const [isDisabled, setIsDisabled] = useState(true);

  const [loading, setLoading] = useState(false);

  const [provinces, setProvinces] = useState<ProvinceMap[]>(initialProvinces);

  const [isActive, setIsActive] = useState(false);

  const getBelgiumProvinces = useSafeApiCall(zonesService.getBelgiumProvinces);

  const getLicenses = useSafeApiCall(licenseService.getLicense);
  const getLicensePricesFromService = useSafeApiCall(licensesPriceService.getLicensePrices);
  const getPaymentMethodsFromService = useSafeApiCall(paymentMethodsService.get);

  const removeSalesman = useSafeApiCall(salesmenService.deleteSalesman);

  const loadZones = async () => {
    try {
      setLoading(true);
      const [result, isAborted] = await getBelgiumProvinces();
      if (isAborted) {
        return;
      }

      if (salesman?.zones?.length) {
        setProvinces(result.map((pr: ProvinceMap) => ({
          ...pr,
          isSelected: salesman.zones.some((zone: Zone) => zone.id === pr.zone.id),
        })));
      } else {
        setProvinces(result);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  const loadLicenses = async () => {
    try {
      setLoading(true);
      const [result, isAborted] = await getLicenses();
      if (isAborted) {
        return;
      }

      setLicenses(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValid) {
      return;
    }

    const salesmanZones = provinces
      .filter((pr: ProvinceMap) => pr.isSelected)
      .map((pr: ProvinceMap) => ({ ...pr.zone }));

    const salesmanCreated: AddSalesman = {
      supplierUserId,
      firstName,
      lastName,
      email,
      phoneNumber,
      licensePriceId,
      paymentMethodId,
      zonesIds: salesmanZones.map((zone) => zone.id),
    };

    submitForm(salesmanCreated);
  };

  const handleZoneClick = (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
    const provinceId = e.currentTarget.id;
    const selectedProvince = provinces.find((p: ProvinceMap) => p.zone.id === provinceId);

    if (selectedProvince) {
      selectedProvince.isSelected = !selectedProvince.isSelected;
      setProvinces([
        ...provinces.filter((p: ProvinceMap) => p.zone.id !== provinceId),
        selectedProvince,
      ]);
    }
  };

  const getLicensePrices = async (license: string) => {
    try {
      const [result, isAborted] = await getLicensePricesFromService(license);
      if (isAborted) {
        return [];
      }

      setIsDisabled(false);

      return result;
    } catch (err) {
      setIsDisabled(false);
      addNotification('error', err.messages);

      return [];
    }
  };

  const loadPaymentMethod = async () => {
    try {
      setLoading(true);
      const [result, isAborted] = await getPaymentMethodsFromService();
      if (isAborted) {
        return;
      }

      setPaymentMethods(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  const handleLicenseChange = async (value: FieldValue, valid: boolean, name?: string) => {
    handleChange(value, valid, name);
    const result = await getLicensePrices(value as string);
    if (!result?.length) {
      return;
    }

    setLicensePrices(result);

    handleChange(result.length ? result[0].id : '', !!result.length, LICENSE_PRICE_ID);
  };

  const loadLicensePrices = async () => {
    if (licenseId) {
      const result = await getLicensePrices(licenseId);
      setLicensePrices(result);
    }
  };

  const licenseOptions = useMemo(() => licenses.map(mapToSelectOption), [licenses]);
  const licensePriceOptions = useMemo(() => licensePrices
    .map(mapLicensePriceToSelectOption), [licensePrices]);
  const paymentMethodOptions = useMemo(() => paymentMethods
    .map(mapPaymentMethodToSelectOption), [paymentMethods]);

  useEffect(() => {
    loadLicenses();
    loadPaymentMethod();
    loadLicensePrices();
  }, []);

  useEffect(() => {
    loadZones();
  }, [salesman]);

  useEffect(() => {
    const licensePrice = licensePrices.find((lic) => lic.id === licensePriceId);
    setPrice(licensePrice ? licensePrice.price : null);
  }, [licensePriceId, licensePrices]);

  const toggleModal = () => {
    setIsActive(!isActive);
  };

  const deleteSalesman = async () => {
    const [, isAborted] = await removeSalesman(salesman?.id!);
    if (isAborted) {
      return;
    }

    toggleModal();
  };

  return (
    <Section isFullHeight isLightGrey>
      <Loading loading={loading}>
        {isActive && (
          <Modal
            title="Confirmation"
            actions={[
              {
                text: 'Supprimer',
                onClick: deleteSalesman,
                className: 'button is-danger',
              },
              {
                text: 'Annuler',
                onClick: toggleModal,
                className: 'button is-dark',
              },
            ]}
          >
            <p>Êtes-vous certain de vouloir supprimer ce représentant ?</p>
          </Modal>
        )}
        <SalesmanForm
          title={title}
          provinces={provinces}
          licenseOptions={licenseOptions}
          licensePriceOptions={licensePriceOptions}
          paymentMethodOptions={paymentMethodOptions}
          price={price!}
          isDisabled={isDisabled}
          buttonValue={buttonValue}
          fields={[
            FIRSTNAME,
            LASTNAME,
            EMAIL,
            PHONENUMBER,
            LICENSE_ID,
            LICENSE_PRICE_ID,
            PAYMENT_METHOD_ID,
          ]}
          fieldValues={[
            firstName,
            lastName,
            email,
            phoneNumber,
            licenseId,
            licensePriceId,
            paymentMethodId,
          ]}
          submit={submit}
          onChange={handleChange}
          onZoneClick={handleZoneClick}
          onLicenseChange={handleLicenseChange}
          handleRemoveSalesman={toggleModal}
        />
      </Loading>
    </Section>
  );
};

export default ManageSalesman;
