import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { randomString } from 'modules/shared/utils/strings.utils';

import styles from './CheckBox.module.scss';

interface Props {
  className?: string;
  labelText?: string;
  checked?: boolean;
  name?: string;
  onChange?: (checked: boolean, isValid: boolean, name?: string) => void;
  required?: boolean;
  disabled?: boolean;
}

const CheckBox = (props: Props) => {
  const {
    className,
    labelText,
    checked,
    name,
    onChange,
    required,
    disabled,
  } = props;

  const id = useRef(randomString());
  const [isInvalid, setIsInvalid] = useState(false);

  const handleChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkedValue = e.target.checked;

    if (!onChange) {
      return;
    }

    const requiredValid = !required || checkedValue;

    setIsInvalid(!requiredValid);
    onChange(checkedValue, requiredValid, name);
  };

  return (
    <div className={styles.switchContainer}>
      <div className={classNames(styles.switch, className, { 'is-danger': isInvalid })}>
        <input
          type="checkbox"
          onChange={handleChangeCheckBox}
          checked={checked}
          id={id.current}
          name={name}
          className={classNames({ 'is-danger': isInvalid })}
          required={required}
          disabled={disabled}
        />
        <label htmlFor={id.current}>
          <span className={classNames(styles.slider, styles.round)} />
        </label>
      </div>
      <label htmlFor={id.current}>
        <span className={styles.label}>{labelText}</span>
      </label>
    </div>
  );
};

export default CheckBox;
