import { AnyAction } from 'redux';

import AuthState from 'modules/shared/types/reducers/AuthState';

import {
  SIGN_IN,
  SIGN_IN_FAILED,
  SIGN_OUT,
} from './auth.actionTypes';

const initialState: AuthState = {
  error: null,
};

export default (state: AuthState = initialState, action: AnyAction): AuthState => {
  switch (action.type) {
    case SIGN_IN:
      return { error: null };
    case SIGN_IN_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};
