import Supplier from 'modules/products/models/Supplier.model';
import SupplierReceived from 'modules/account/models/SupplierReceived.model';
import ApiError from 'modules/shared/models/ApiError.model';
import { makeThrowError } from 'modules/shared/utils/apiErrors.utils';
import HttpService from 'modules/shared/models/HttpService.model';
import errorCodes from 'modules/shared/constants/errorCodes.constants';

import {
  CONFIRMED_EMAIL,
  CONFIRMED_VAT,
} from '../constants/suppliers.constants';

const mapError = (error: ApiError) => {
  switch (error.code) {
    case CONFIRMED_EMAIL:
      return 'Cet adresse email est déjà utilisée au sein de notre site';
    case CONFIRMED_VAT:
      return 'Ce numéro de TVA est déjà utilisé au sein de notre site';
    case '401':
      return 'Votre session à expirée';
    case errorCodes.ITEM_NOT_FOUND:
      return 'Le fournisseur désiré est introuvable';
    case errorCodes.ITEMS_NOT_FOUND:
      return 'Fournisseurs introuvables';
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const endpoint = '/suppliers';

const getSuppliers = (service: HttpService) => (): Promise<SupplierReceived[]> => service
  .get<SupplierReceived[]>(endpoint)
  .catch(throwError);

const getById = (service: HttpService) => (
  supplierId: string,
): Promise<SupplierReceived> => service
  .get<SupplierReceived>(`${endpoint}/${supplierId}`)
  .catch(throwError);

const addSupplier = (service: HttpService) => (supplier: Supplier): Promise<Supplier> => service
  .post<Supplier>(endpoint, supplier)
  .catch(throwError);

const confirmAccount = (service: HttpService) => (
  supplier: SupplierReceived,
): Promise<void> => service
  .post<void>(`${endpoint}/send-confirmation`, supplier)
  .catch(throwError);

const updateSupplier = (service: HttpService) => (
  supplier: Supplier,
  id: string,
): Promise<Supplier> => service
  .put<Supplier>(`${endpoint}/${id}`, supplier)
  .catch(throwError);

const deleteSupplier = (service: HttpService) => (supplierId: string): Promise<void> => service
  .remove(`${endpoint}/${supplierId}`)
  .catch(throwError);

export default {
  getSuppliers,
  getById,
  confirmAccount,
  addSupplier,
  updateSupplier,
  deleteSupplier,
};
