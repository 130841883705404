import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import styles from './Card.module.scss';

interface Props {
  title: string;
  rightElement?: JSX.Element;
}

const Card = (props: PropsWithChildren<Props>) => {
  const { title, rightElement, children } = props;

  return (
    <div className="card-content">
      <h2 className="title is-5">
        <div className={classNames(styles.flexContainer, 'icon-text')}>
          <span>{title}</span>
          {rightElement}
        </div>
      </h2>
      <div className="content">
        {children}
      </div>
    </div>
  );
};

export default Card;
