import { makeThrowError } from 'modules/shared/utils/apiErrors.utils';
import ApiError from 'modules/shared/models/ApiError.model';
import HttpService from 'modules/shared/models/HttpService.model';
import errorCodes from 'modules/shared/constants/errorCodes.constants';

import Salesman from '../models/Salesman.model';
import AddSalesman from '../models/AddSalesman.model';

const mapError = (error: ApiError) => {
  switch (error.code) {
    case '401':
      return 'Votre session à expirée';
    case errorCodes.ITEM_NOT_FOUND:
      return 'Le représentant désiré est introuvable';
    case errorCodes.ITEMS_NOT_FOUND:
      return 'Représentants introuvables';
    case errorCodes.ADD_USER_ERROR:
      return 'Cet utilisateur est déjà inscrit sur le site';
    case '403':
      return 'Cette action est interdite, veuillez d\'abord retirer sa licence au représentant';
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const endpoint = '/salesmen';

const getById = (service: HttpService) => (salesmanId: string): Promise<Salesman> => service
  .get<Salesman>(`${endpoint}/${salesmanId}`)
  .catch(throwError);

const getBySupplier = (service: HttpService) => (supplierId: string): Promise<Salesman[]> => (
  service.get<Salesman[]>(`${endpoint}?supplierId=${supplierId}`))
  .catch(throwError);

const getFullSalesman = (service: HttpService) => (
  salesmanId: string,
): Promise<Salesman> => service
  .get<Salesman>(`${endpoint}/${salesmanId}/full`)
  .catch(throwError);

const getLocalizedSalesmen = (service: HttpService) => (
  contactRequestId: string,
): Promise<Salesman[]> => service
  .get<Salesman[]>(`${endpoint}/localized?contactRequestId=${contactRequestId}`)
  .catch(throwError);

const createSalesman = (service: HttpService) => (
  salesman: AddSalesman,
): Promise<Salesman> => service
  .post<Salesman>(endpoint, salesman)
  .catch(throwError);

const updateSalesman = (service: HttpService) => (
  salesmanId: string,
  salesman: AddSalesman,
): Promise<Salesman> => (
  service.put<Salesman>(`${endpoint}/${salesmanId}`, salesman));

const deleteSalesman = (service: HttpService) => (
  salesmanId: string,
): Promise<void> => service
  .remove(`${endpoint}/${salesmanId}`)
  .catch(throwError);

export default {
  createSalesman,
  updateSalesman,
  getBySupplier,
  getById,
  getFullSalesman,
  getLocalizedSalesmen,
  deleteSalesman,
};
