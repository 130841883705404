import React, { useRef } from 'react';
import classNames from 'classnames';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fr } from 'date-fns/locale';

import { roundByInterval } from 'modules/requests/utils/dates.utils';

import { FieldValue } from '../hooks/useForm.hook';
import styles from './Input/Input.module.scss';
import { randomString } from '../utils/strings.utils';

// Bind 'fr' string locale to date-fns fr locale
registerLocale('fr', fr);

interface Props {
  label?: string;
  labelClassName?: string;
  name?: string;
  value?: Date;
  min?: Date;
  minuteInterval?: number;
  disabled?: boolean
  className?: string;
  onChange: (value: FieldValue, valid: boolean, name?: string) => void;
}

const DatePicker = ({
  label,
  labelClassName,
  name,
  value,
  min,
  minuteInterval,
  disabled,
  className,
  onChange,
}: Props) => {
  const id = useRef(randomString());
  const roundMinutes = roundByInterval(minuteInterval || 15);

  const handleDateChange = (newDate: Date) => {
    onChange(roundMinutes(newDate), true, name);
  };

  const renderLabel = () => (
    <label className={classNames('label', labelClassName)} htmlFor={id.current}>
      {label}
    </label>
  );

  const renderDatePicker = () => (
    <ReactDatePicker
      name={name}
      selected={value}
      onChange={handleDateChange}
      locale="fr"
      showTimeSelect
      timeIntervals={minuteInterval || 15}
      minDate={min}
      dateFormat="Pp"
      className={classNames(
        styles.inputControl,
        {
          [styles.disabled]: disabled,
        },
        className,
      )}
    />
  );

  return (
    <div className={styles.formControl}>
      {label && renderLabel()}
      {renderDatePicker()}
    </div>
  );
};

export default DatePicker;
