import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import useSubPath from 'modules/shared/hooks/useSubPath.hook';
import EditProduct from 'modules/products/components/EditProduct';

const ManageSupplierProductRoutes = () => {
  const match = useRouteMatch<{ supplierId: string, productId: string }>();
  const { params } = match;
  const { productId, supplierId } = params;

  return (
    <Switch>
      <Route path={useSubPath('/edit')}>
        <EditProduct productId={productId} supplierId={supplierId} />
      </Route>
    </Switch>
  );
};

export default ManageSupplierProductRoutes;
