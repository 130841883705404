import Filter from 'modules/shared/models/Filter.model';
import ApiError from 'modules/shared/models/ApiError.model';
import { makeThrowError } from 'modules/shared/utils/apiErrors.utils';
import HttpService from 'modules/shared/models/HttpService.model';
import errorCodes from 'modules/shared/constants/errorCodes.constants';

import mapToFilter from '../mappers/licenses.mappers';
import License from '../models/License.model';

const mapError = (error: ApiError) => {
  switch (error.code) {
    case '401':
      return 'Votre session à expirée';
    case errorCodes.ITEM_NOT_FOUND:
      return 'La licence désirée est introuvable';
    case errorCodes.ITEMS_NOT_FOUND:
      return 'Licences introuvables';
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = makeThrowError(mapError);

const endpoint = '/licenses';

const getLicense = (service: HttpService) => (): Promise<Filter[]> => service
  .get<License[]>(endpoint)
  .then((data) => data.map(mapToFilter))
  .catch(throwError);

export default {
  getLicense,
};
