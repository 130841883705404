import React, { PropsWithChildren } from 'react';
import { ClipLoader } from 'react-spinners';
import styles from './Loading.module.scss';

interface Props {
  loading: boolean;
}

const Loading = (props: PropsWithChildren<Props>): JSX.Element => {
  const {
    loading,
    children,
  } = props;

  if (!loading) {
    return children as JSX.Element;
  }

  return (
    <div className={styles.loader}>
      <ClipLoader
        size={150}
        color="#9F0C11"
        loading={loading}
      />
    </div>
  );
};

export default Loading;
