import Filter from 'modules/shared/models/Filter.model';
import ProvinceMap from 'modules/salesmen/models/ProvinceMap.model';
import { provinces } from 'modules/salesmen/constants/belgiumMap.constants';
import GroupSelectOption from 'modules/shared/models/GroupSelectOption.model';
import Salesman from 'modules/salesmen/models/Salesman.model';

import Zone from '../models/Zone.model';

const mapToFilter = (zone: Zone): Filter => ({
  id: zone.id,
  name: zone.name!,
});

const mapToGroupOption = ({ name, salesmen }: Zone): GroupSelectOption => ({
  label: name!,
  options: salesmen?.length ? salesmen.map(({ user }: Salesman) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
  })) : [],
});

const mapToProvince = (zone: Zone): ProvinceMap => {
  const province = provinces.find((p: ProvinceMap) => p.zone.id === zone.id);

  province!.zone = { ...zone };

  return {
    ...province!,
  };
};

export default {
  mapToFilter,
  mapToProvince,
  mapToGroupOption,
};
