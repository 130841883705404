import React from 'react';
import { useHistory } from 'react-router-dom';

import supplierRoutes from 'modules/suppliers/supplier.routes';
import Section from 'modules/shared/components/Section';
import useNotifications from 'modules/shared/hooks/useNotifications';
import managementRoutes from 'modules/management/management.routes';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

import ManageSalesman from './ManageSalesman';

import salesmanService from '../services/salesmen.service';
import NewSalesman from '../models/AddSalesman.model';

interface Props {
  supplierId: string;
}

const AddSalesman = ({ supplierId }: Props) => {
  const { addNotification } = useNotifications();
  const history = useHistory();

  const addSalesman = useSafeApiCall(salesmanService.createSalesman);

  const goToSalesmanTable = () => {
    history.push({
      pathname: `${managementRoutes.MANAGE}${supplierRoutes.SALESMEN}`,
      search: 'action=added',
    });
  };

  const createSalesman = async (salesmanCreated: NewSalesman) => {
    try {
      const [, isAborted] = await addSalesman(salesmanCreated);
      if (isAborted) {
        return;
      }

      goToSalesmanTable();
    } catch (err) {
      addNotification('error', err.messages);
    }
  };

  return (
    <Section isFullHeight isLightGrey>
      <ManageSalesman
        supplierUserId={supplierId}
        title="Ajouter un représentant"
        buttonValue="Ajouter"
        submitForm={createSalesman}
      />
    </Section>
  );
};

export default AddSalesman;
