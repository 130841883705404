import React, { useState, useEffect, useRef } from 'react';
import { parse } from 'query-string';

import { useHistory, useLocation } from 'react-router-dom';

import Section from 'modules/shared/components/Section';
import SupplierReceived from 'modules/account/models/SupplierReceived.model';
import managementRoutes from 'modules/management/management.routes';
import Modal from 'modules/shared/components/Modal';
import suppliersService from 'modules/suppliers/services/suppliers.service';
import useNotifications from 'modules/shared/hooks/useNotifications';
import Loading from 'modules/shared/components/Loading';
import SupplierCard from 'modules/suppliers/components/SupplierCard';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

const SuppliersManagement = () => {
  const [suppliers, setSuppliers] = useState<SupplierReceived[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotifications();

  const history = useHistory();
  const location = useLocation();

  const supplierId = useRef('');
  const { deleted } = parse(location.search);

  const getSuppliers = useSafeApiCall(suppliersService.getSuppliers);
  const confirmSupplierAccount = useSafeApiCall(suppliersService.confirmAccount);
  const removeSupplier = useSafeApiCall(suppliersService.deleteSupplier);

  const loadSuppliers = async () => {
    try {
      setLoading(true);
      const [result, isAborted] = await getSuppliers();
      if (isAborted) {
        return;
      }

      setSuppliers(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  useEffect(() => {
    if (deleted) {
      addNotification('success', 'Le fournisseur a bien été supprimé');
      setTimeout(() => {
        history.push({
          pathname: `${managementRoutes.MANAGE}${managementRoutes.SUPPLIERS}`,
        });
      }, 4000);
    }
    loadSuppliers();
  }, []);

  const gotToEditSupplier = (supplier: SupplierReceived) => {
    history.push({
      pathname: `${managementRoutes.MANAGE}${managementRoutes.SUPPLIERS}/${supplier.user.id}`,
    });
  };

  const toggleModal = () => {
    supplierId.current = '';
    setIsActive(!isActive);
  };

  const deleteSupplier = async () => {
    if (supplierId.current) {
      return;
    }

    try {
      const [, isAborted] = await removeSupplier(supplierId.current);
      if (isAborted) {
        return;
      }

      toggleModal();
    } catch (err) {
      toggleModal();
      addNotification('error', err.messages);
    }
  };

  const validateSupplier = async (supplier: SupplierReceived) => {
    try {
      await confirmSupplierAccount(supplier);
    } catch (err) {
      addNotification('error', err.messages);
    }
  };

  const gotToSupplierProducts = (supplier: SupplierReceived) => {
    history.push({
      pathname: `${managementRoutes.MANAGE}${managementRoutes.SUPPLIERS}/${supplier.user.id}/products`,
    });
  };

  return (
    <Section isFullHeight isLightGrey hasAlwaysPadding>
      <Loading loading={loading}>
        {isActive && (
          <Modal
            title="Confirmation"
            actions={[
              {
                text: 'Supprimer',
                onClick: deleteSupplier,
                className: 'button is-danger',
              },
              {
                text: 'Annuler',
                onClick: toggleModal,
                className: 'button is-dark',
              },
            ]}
          >
            <p>Etes-vous sur de vouloir supprimer ce fournisseur?</p>
          </Modal>
        )}
        <div className="container">
          <ul className="columns is-multiline">
            {suppliers.length
              ? suppliers.map((supplier) => (
                <SupplierCard
                  key={supplier.id}
                  supplier={supplier}
                  goToEditSupplier={gotToEditSupplier}
                  goToSupplierProducts={gotToSupplierProducts}
                  validateSupplier={validateSupplier}
                />
              )) : (
                <div>
                  <h2 className="mb-1 subtitle">Aucun fournisseur enregistré</h2>
                  <p>
                    Il n&apos;y a aucun fournisseur inscrit sur My Salesman actuellement.
                    Veuillez réessayer plus tard.
                  </p>
                </div>
              )}
          </ul>
        </div>
      </Loading>
    </Section>
  );
};

export default SuppliersManagement;
