import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { parse } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import Section from 'modules/shared/components/Section';
import Product from 'modules/products/models/Product.model';
import productsService from 'modules/products/services/products.service';
import Button from 'modules/shared/components/Button';
import Loading from 'modules/shared/components/Loading';
import useNotifications from 'modules/shared/hooks/useNotifications';
import { getCurrentUserId, getIsConnected } from 'modules/shared/selectors/currentUser.selectors';
import AddContactRequest from 'modules/requests/models/AddContactRequest.model';
import requestSalesmanService from 'modules/requests/services/requests.service';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';
import Modal from 'modules/shared/components/Modal';
import Breadcrumb from 'modules/shared/components/Breadcrumb';
import BreadcrumbItem from 'modules/shared/models/BreadcrumbItem.model';

import searchRoutes from '../search.routes';
import RelatedProducts from '../RelatedProducts';

import styles from './ProductDescription.module.scss';

const ProductDescription = () => {
  const isConnected = useSelector(getIsConnected);
  const currentUserId = useSelector(getCurrentUserId);

  const [product, setProduct] = useState<Product>();
  const [loading, setLoading] = useState(false);
  const [isModalActive, setModalActive] = useState(false);
  const [items, setItems] = useState<BreadcrumbItem[]>([]);

  const { addNotification } = useNotifications();

  const history = useHistory();
  const location = useLocation();

  const getProduct = useSafeApiCall(productsService.getProduct);

  const addRequest = useSafeApiCall(requestSalesmanService.addRequest);

  const toggleModal = () => setModalActive(!isModalActive);

  const goToMainPage = () => {
    history.push({
      pathname: searchRoutes.MAIN_PAGE,
    });
  };

  const onContactSalesman = async () => {
    if (!isConnected) {
      history.push({
        pathname: searchRoutes.PRODUCT_REQUEST,
        search: `productId=${product?.id}`,
      });

      return;
    }

    if (!product) {
      return;
    }

    const addContactRequest: AddContactRequest = {
      productId: product?.id!,
      userId: currentUserId!,
    };

    try {
      setLoading(true);
      const [, isAborted] = await addRequest(addContactRequest);
      if (isAborted) {
        return;
      }

      toggleModal();
      setLoading(false);
      addNotification('success', 'Demande bien envoyée !');
    } catch (err) {
      addNotification('error', err.messages);
    }
  };

  const loadProduct = async (productId: string) => {
    try {
      setLoading(true);
      const [result, isAborted] = await getProduct(productId);
      if (isAborted) {
        return;
      }

      setProduct(result);

      setLoading(false);
    } catch (err) {
      addNotification('error', err.messages);

      goToMainPage();
    }
  };

  useEffect(() => {
    if (product === undefined) {
      return;
    }

    const breadcrumbItems: BreadcrumbItem[] = [
      { title: 'Produit', url: `${searchRoutes.SEARCH_RESULT}?keyword=${product.name}` },
      { title: 'Description', url: '#' },
      { title: product.name, url: '#' },
    ];
    setItems(breadcrumbItems);
  }, [product]);

  useEffect(() => {
    const { id } = parse(location.search);

    if (!id) {
      goToMainPage();
    } else {
      loadProduct(id as string);
    }
  }, [location.search]);

  return (
    <Section hasAlwaysPadding>
      <Breadcrumb items={items} />
      <Loading loading={loading}>
        {isModalActive && (
          <Modal
            title="Confirmation de demande de contact"
            actions={[
              {
                text: 'Confirmer',
                className: 'button is-success',
                onClick: onContactSalesman,
              },
              {
                text: 'Annuler',
                className: 'button is-dark',
                onClick: toggleModal,
              },
            ]}
          >
            Vous allez envoyer une demande de contact pour ce produit, êtes-vous sûr ?
          </Modal>
        )}
        <div className={classNames(
          styles.columns,
          'columns is-flex-desktop-only is-justify-content-space-between',
        )}
        >
          <div className={classNames('column is-4 flex-full-center', styles.imageColumn)}>
            <figure className={styles.imageWrapper}>
              <img
                src={product?.imageUrl}
                alt={product?.name}
                className={styles.image}
              />
              <figcaption className={styles.buttonWrapper}>
                <Button
                  type="submit"
                  value="Envoyer une demande"
                  onClick={toggleModal}
                  className={styles.requestButton}
                />
              </figcaption>
            </figure>
          </div>
          <div className={classNames('column', styles.description)}>
            <h1 className="title">{product?.name}</h1>
            <h5 className="subtitle">
              {product?.category.label}
              {' - '}
              {product?.supplier.company.establishmentName}
            </h5>
            <div className="content">{product?.description}</div>
          </div>
        </div>
        <div className={styles.relatedProducts}>
          <hr />
          <p>Autres produits de ce fournisseur :</p>
          <div className="mt-4">
            <RelatedProducts product={product} />
          </div>
        </div>
      </Loading>
    </Section>
  );
};

export default ProductDescription;
