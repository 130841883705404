const MAIN_PAGE = '/';
const SEARCH_RESULT = '/search';
const PRODUCT_DESCRIPTION = '/product-description';
const PRODUCT_REQUEST = '/product-request';

export default {
  MAIN_PAGE,
  SEARCH_RESULT,
  PRODUCT_DESCRIPTION,
  PRODUCT_REQUEST,
};
