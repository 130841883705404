import React, { useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import Modal from 'modules/shared/components/Modal';
import Category from 'modules/products/models/Category.model';
import AddCategory from 'modules/products/models/AddCategory.model';
import RootState from 'modules/shared/types/reducers/RootState';

import ManageCategory, { ManageCategoryRef } from './ManageCategory';
import { getCategories } from '../category.selectors';

interface Props extends ReduxProps {
  title: string;
  manageCat: (category: AddCategory, id?: string) => Promise<[Category, boolean]>;
  category?: Category | null;
  toggleModal: () => void;
}

const CategoryModal = (props: Props) => {
  const {
    title,
    manageCat,
    category,
    categories,
    toggleModal,
  } = props;

  const manageCategoryRef = useRef<ManageCategoryRef>();

  const toggleEditModalManage = () => {
    toggleModal();
  };

  const submitForm = () => {
    if (manageCategoryRef.current) {
      manageCategoryRef.current.submit();
    }
  };

  if (!categories) {
    return null;
  }

  return (
    <>
      <Modal
        title={title}
        actions={[
          {
            text: 'Fermer',
            onClick: toggleEditModalManage,
            className: 'button is-dark',
          },
          {
            text: 'Ajouter/modifier',
            onClick: submitForm,
            className: 'button is-dark',
          },
        ]}
      >
        <ManageCategory
          // @ts-ignore
          ref={manageCategoryRef}
          manageCat={manageCat}
          categories={categories}
          category={category}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  categories: getCategories(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CategoryModal);
