import React from 'react';

import ContactRequest from 'modules/requests/models/ContactRequest.model';
import EmbeddedMap from 'modules/shared/components/EmbeddedMap';

interface Props {
  request?: ContactRequest;
}

const CustomerInfo = ({ request }: Props) => (request
  ? (
    <>
      <h2 className="subtitle has-text-weight-bold is-5">Informations du client</h2>
      <div>
        {`${request.customerLastName} ${request.customerFirstName}`}
      </div>
      <div>
        {`Entreprise : ${request.customerCompanyName}`}
      </div>
      <div>
        {`Coordonées : ${request.customerEmail}, ${request.customerPhoneNumber}`}
      </div>
      <div className="mt-4">
        <EmbeddedMap
          address={request.customerAddress}
          city={request.customerCity}
        />
      </div>
    </>
  )
  : (
    <>
      <h2 className="subtitle has-text-weight-bold is-5">Informations du client</h2>
      <div className="has-text-weight-bold">Aucune demande n&apos;est actuellement sélectionnée</div>
      <div>Veuillez sélectionner une demande</div>
    </>
  ));

export default CustomerInfo;
