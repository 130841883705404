import React, { useEffect, useState } from 'react';

import Salesman from 'modules/salesmen/models/Salesman.model';
import salesmenService from 'modules/salesmen/services/salesmen.service';
import Loading from 'modules/shared/components/Loading';
import useNotifications from 'modules/shared/hooks/useNotifications';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

import Salesmen from './Salesmen';

interface Props {
  supplierId: string;
}

const SupplierSalesmen = ({ supplierId }: Props) => {
  const [salesmen, setSalesmen] = useState<Salesman[]>([]);
  const [loading, setLoading] = useState(false);

  const { addNotification } = useNotifications();

  const getSalesmenBySupplier = useSafeApiCall(salesmenService.getBySupplier);

  const loadSupplierSalesmen = async () => {
    try {
      setLoading(true);
      const [results, isAborted] = await getSalesmenBySupplier(supplierId);
      if (!isAborted) {
        setSalesmen(results || []);
      }
    } catch (err) {
      addNotification('error', err.messages);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSupplierSalesmen();
  }, []);

  return (
    <Loading loading={loading}>
      <Salesmen salesmen={salesmen} />
    </Loading>
  );
};

export default SupplierSalesmen;
