import React from 'react';

import ContactRequest from '../models/ContactRequest.model';

interface Props {
  request?: ContactRequest;
}

const ProductInfo = ({ request }: Props) => (request ? (
  <>
    <h2 className="subtitle has-text-weight-bold is-5">Informations du produit</h2>
    <div className="has-text-weight-bold">{request.productName}</div>
    <div>
      {request.productDescription}
      {` (${request.productFormat})`}
    </div>
  </>
) : (
  <>
    <h2 className="subtitle has-text-weight-bold is-5">Informations du produit</h2>
    <div className="has-text-weight-bold">Aucune demande n&apos;est actuellement sélectionnée</div>
    <div>Veuillez sélectionner une demande</div>
  </>
));

export default ProductInfo;
