import React from 'react';
import BreadcrumbItem from '../models/BreadcrumbItem.model';

interface Props {
  items: BreadcrumbItem[];
}

const Breadcrumb = ({ items }: Props) => (
  <div>
    <nav
      className="breadcrumb"
      aria-label="breadcrumbs"
      style={{ paddingBottom: 24 }}
    >
      <ul>
        {items
          .map(({ title, url }) => (
            <li>
              <a href={url}>
                <span>{title}</span>
              </a>
            </li>
          ))}
      </ul>
    </nav>
  </div>
);

export default Breadcrumb;
