import React from 'react';

interface Props {
  address: string;
  city: string;
}

const EmbeddedMap = ({ address, city }: Props) => {
  const baseMapUrl = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyA-Tb72cBSvPjpuMlLM6dRnrtUB9NT00_g';

  const normalizeAddress = (street: string, town: string) => `${street.replace(/\n/g, '+')},${town.replace(/\s/g, '+')}`;

  return (
    <iframe
      title="Client location"
      style={{
        border: 0,
        width: '100%',
        height: '450px',
      }}
      allowFullScreen
      src={`${baseMapUrl}&q=${normalizeAddress(address, city)}`}
    />
  );
};

export default EmbeddedMap;
