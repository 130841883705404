import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'query-string';

import Salesman from 'modules/salesmen/models/Salesman.model';
import useNotifications from 'modules/shared/hooks/useNotifications';
import Section from 'modules/shared/components/Section';
import Button from 'modules/shared/components/Button';
import managementRoutes from 'modules/management/management.routes';
import SalesmanCard from 'modules/salesmen/components/SalesmanCard';
import SearchInput from 'modules/shared/components/SearchInput';

import supplierRoutes from '../supplier.routes';

interface Props {
  salesmen: Salesman[];
}

const Salesmen = (props: Props) => {
  const {
    salesmen,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const [filter, setFilter] = useState('');

  const { addNotification } = useNotifications();

  const { action } = parse(location.search);

  const showNotification = () => {
    switch (action) {
      case 'added':
        addNotification('success', 'Le représentant a bien été ajouté');
        break;
      case 'updated':
        addNotification('success', 'Le représentant a bien été modifié');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    showNotification();
  }, []);

  const goToEditSalesman = (salesman: Salesman) => {
    history.push({
      pathname: `${managementRoutes.MANAGE}${supplierRoutes.SALESMEN}/${salesman.id}${supplierRoutes.EDIT_SALESMAN}`,
    });
  };

  const goToAddSalesman = () => {
    history.push({
      pathname: `${managementRoutes.MANAGE}${supplierRoutes.SALESMEN}${supplierRoutes.NEW_SALESMAN}`,
    });
  };

  const handleChange = (key: string) => {
    setFilter(key);
  };

  const filterWithName = useMemo(() => salesmen.filter((salesman) => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
    } = salesman.user;

    return firstName
      .concat(lastName)
      .concat(email)
      .concat(phoneNumber)
      .toLowerCase()
      .includes(filter.toLowerCase());
  }),
  [salesmen, filter]);

  return (
    <Section isFullHeight isLightGrey hasAlwaysPadding>
      <Button
        type="button"
        className="button is-dark"
        onClick={goToAddSalesman}
        value="Ajouter un représentant"
      />

      <hr />

      <div className="container">
        <SearchInput
          placeholder="Rechercher un représentant"
          onChanged={handleChange}
        />
        <ul className="columns is-multiline">
          {salesmen.length
            ? filterWithName.map((salesman) => (
              <SalesmanCard
                key={salesman.id}
                goToEditSalesman={goToEditSalesman}
                salesman={salesman}
              />
            ))
            : (
              <div>
                <h2 className="mb-1 subtitle">Aucun représentant enregistré</h2>
                <p>
                  Ce fournisseur n&apos;a pas encore enregistré de représentant.
                  Veuillez réessayer plus tard.
                </p>
              </div>
            )}
        </ul>
      </div>
    </Section>
  );
};

export default Salesmen;
