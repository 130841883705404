import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import RootState from '../types/reducers/RootState';
import { getIsConnected } from '../selectors/currentUser.selectors';

interface Props extends ReduxProps {
  path: string;
  children: JSX.Element;
}

const AnonymousRoute = (props: Props) => {
  const {
    path,
    children,
    isConnected,
  } = props;

  return (
    <Route
      path={path}
      render={({ location }) => {
        if (isConnected) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          );
        }

        return children;
      }}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  isConnected: getIsConnected(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AnonymousRoute);
