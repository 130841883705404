import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import searchRoutes from '../search.routes';
import styles from './SearchBar.module.scss';

interface Props {
  inputValue?: string;
  inline?: boolean;
}

const SearchBar = (props: Props) => {
  const {
    inputValue,
    inline,
  } = props;

  const history = useHistory();
  const [value, setValue] = useState(inputValue);

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (value && value.length >= 3) {
      history.push({
        pathname: searchRoutes.SEARCH_RESULT,
        search: `?keyword=${value}`,
      });
    }
  };

  const onChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <form
      className={classNames(
        styles.searchContainer,
        {
          [styles.multiLine]: !inline,
          [styles.inline]: inline,
        },
      )}
      onSubmit={handleSubmit}
    >
      <div className={styles.searchBar}>
        <input
          type="text"
          placeholder="Que recherchez-vous ?"
          value={value || ''}
          onChange={onChanged}
        />
        <button type="submit">
          <i className="fas fa-search" />
        </button>
      </div>
    </form>
  );
};

export default SearchBar;
