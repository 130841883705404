import React, { useState } from 'react';
import classNames from 'classnames';

import Tab from '../../models/Tab.model';
import LanguageTab from '../LanguageTab';

import scss from './LanguagesTabs.module.scss';

interface Props {
  tabs: Tab[];
  onValueChange: (languageId: string, propName: string, value: string) => void;
  onFormValidityChange: (languageId: string, isValid: boolean) => void;
  disableInputs?: boolean;
}

const LanguagesTabs = (props: Props) => {
  const {
    tabs,
    onValueChange,
    onFormValidityChange,
    disableInputs,
  } = props;

  const [selectedIndex, setSelectedIndex] = useState(0);

  const renderTabHeader = (tab: Tab, index: number) => {
    const { language } = tab;
    return (
      <li key={language.id} className={classNames({ 'is-active': index === selectedIndex }, scss.tabHeader)}>
        <a onClick={() => setSelectedIndex(index)}>
          <span>{language.label}</span>
        </a>
      </li>
    );
  };

  return (
    <div>
      <div className={classNames('tabs', scss.tabsColor)}>
        <ul>
          {tabs.map(renderTabHeader)}
        </ul>
      </div>
      {tabs.map((tab, index) => {
        if (index !== selectedIndex) {
          return null;
        }

        return (
          <LanguageTab
            key={tab.language.id}
            tab={tab}
            onValueChange={onValueChange}
            onFormValidityChange={onFormValidityChange}
            disableInputs={disableInputs}
          />
        );
      })}
    </div>
  );
};

export default LanguagesTabs;
