import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Section from 'modules/shared/components/Section';
import Loading from 'modules/shared/components/Loading';

import * as categoryAction from '../../category.actions';
import AddCategory from './AddCategory';
import CategoriesContainer from './CategoriesContainer';
import { getCategories, getIsLoading } from '../category.selectors';

const CategoriesManagement = () => {
  const categories = useSelector(getCategories);
  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch();
  const loadCat = useCallback(() => dispatch(categoryAction.loadCategories()), [dispatch]);

  useEffect(() => {
    loadCat();
  }, []);

  return (
    <Section isFullHeight isLightGrey hasAlwaysPadding>
      <Loading loading={isLoading}>
        {categories?.length && (
          <>
            <AddCategory />
            <CategoriesContainer />
          </>
        )}
      </Loading>
    </Section>
  );
};

export default CategoriesManagement;
