import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserId } from 'modules/authentication/selectors/token.selectors';
import useNotifications from 'modules/shared/hooks/useNotifications';
import Appointment from 'modules/requests/models/Appointment.model';
import appointmentsMappers from 'modules/requests/mappers/appointments.mappers';
import Loading from 'modules/shared/components/Loading';
import Section from 'modules/shared/components/Section';
import Modal from 'modules/shared/components/Modal';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';
import appointmentService from 'modules/requests/services/appointment.service';
import CalendarEvent from 'modules/shared/models/CalendarEvent.model';
import Calendar from 'modules/shared/components/Calendar';
import UpdateAppointment from 'modules/requests/models/UpdateAppointment.model';

import AppointmentDetails from '../CustomerAppointmentDetails';

import styles from './CustomerAgenda.module.scss';

const CustomerAgenda = () => {
  const userId = useSelector(getUserId);

  const { addNotification } = useNotifications();

  const [isLoading, setLoading] = useState(false);
  const [isModalActive, setModalActive] = useState(false);
  const [isConfirmationActive, setConfirmationActive] = useState(false);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment>();

  const getCustomerAppointments = useSafeApiCall(appointmentService.getByCustomer);
  const cancelAppointment = useSafeApiCall(appointmentService.cancelAppointment);

  const toggleModal = () => setModalActive(!isModalActive);
  const toggleConfirmationModal = () => setConfirmationActive(!isConfirmationActive);

  const loadAppointments = async () => {
    try {
      setLoading(true);
      const [result, isAborted] = await getCustomerAppointments(userId!);
      if (isAborted) {
        return;
      }

      setAppointments(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  const handleCancelAppointment = async () => {
    if (!selectedAppointment?.id) {
      addNotification('error', 'Aucun rendez-vous n\'à été sélectionné');
      return;
    }

    const appointmentCancellation: UpdateAppointment = {
      updatedByUserId: userId!,
    };

    try {
      setLoading(true);
      const [, isAborted] = await cancelAppointment(
        selectedAppointment.id,
        appointmentCancellation,
      );
      if (isAborted) {
        return;
      }

      toggleConfirmationModal();
      setLoading(false);
      addNotification('success', 'Rendez-vous annulé avec succès');
    } catch (err) {
      setLoading(false);
      addNotification('error', err.messages);
    }
  };

  const handleEventClick = (event: CalendarEvent) => {
    const appointment = appointments.find((ap) => event.id === ap.id);

    if (!appointment) {
      return;
    }

    setSelectedAppointment(appointment);
    toggleModal();
  };

  const events = useMemo(() => appointments
    .map(appointmentsMappers.mapToCustomerCalendarEvent), [appointments]);

  useEffect(() => {
    if (userId) {
      loadAppointments();
    }
  }, [userId]);

  return (
    <Loading loading={isLoading}>
      <Section isFullHeight isLightGrey>
        {isModalActive && (
          <Modal
            title="Détail d'un rendez-vous"
            actions={[
              {
                text: 'Fermer',
                className: 'button is-dark',
                onClick: toggleModal,
              },
              {
                text: 'Annuler le rendez-vous',
                className: 'button is-danger u-ml-a',
                onClick: toggleConfirmationModal,
              },
            ]}
          >
            <AppointmentDetails
              appointment={selectedAppointment!}
            />
          </Modal>
        )}
        {isConfirmationActive && (
          <Modal
            title="Confirmer annulation"
            actions={[
              {
                text: 'Confirmer',
                className: 'button is-danger',
                onClick: handleCancelAppointment,
              },
              {
                text: 'Je ne veux plus annuler',
                className: 'button is-dark',
                onClick: toggleConfirmationModal,
              },
            ]}
          >
            <p className="has-text-weight-bold is-size-4">
              Confirmer vous l&apos;annulation de ce rendez-vous ?
            </p>
          </Modal>
        )}
        <div className={styles.calendarWrapper}>
          <h2 className={styles.title}>
            Agenda
          </h2>
          <div className={styles.calendar}>
            <Calendar
              events={events}
              onClickEvent={handleEventClick}
            />
          </div>
        </div>
      </Section>
    </Loading>
  );
};

export default CustomerAgenda;
