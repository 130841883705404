const MANAGE = '/manage';
const SUPPLIERS = '/suppliers';
const COMPANY_TYPES = '/company-types';
const PRODUCTS = '/products';
const CATEGORIES = '/categories';

export default {
  MANAGE,
  PRODUCTS,
  SUPPLIERS,
  CATEGORIES,
  COMPANY_TYPES,
};
