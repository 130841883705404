import { useCallback, useContext } from 'react';

import { NotificationContext } from 'modules/app/NotificationContext';

import Notification, { NotificationType } from '../models/Notification.model';
import { randomString } from '../utils/strings.utils';

const useNotifications = () => {
  const {
    addNotification: addNotif,
    addNotifications: addNotifs,
  } = useContext(NotificationContext);

  const addNotification = useCallback((type: NotificationType, text: string | string[]) => {
    if (Array.isArray(text)) {
      const newNotifications = text.map((t: string): Notification => ({
        text: t,
        type,
        id: randomString(),
      }));

      addNotifs(newNotifications);
    } else {
      addNotif({ text, type, id: randomString() });
    }
  }, [addNotif, addNotifs]);

  return {
    addNotification,
  };
};

export default useNotifications;
