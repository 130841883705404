import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import useQuickForm from 'modules/shared/hooks/useQuickForm.hook';
import CustomerReceived from 'modules/account/models/CustomerReceived.model';
import Input from 'modules/shared/components/Input';
import Section from 'modules/shared/components/Section';
import Button from 'modules/shared/components/Button';
import SupplierReceived from 'modules/account/models/SupplierReceived.model';
import Select from 'modules/shared/components/Select';
import Supplier from 'modules/products/models/Supplier.model';
import Customer from 'modules/customers/models/Customer.model';
import CompanyType from 'modules/company/models/companyType.model';
import companyTypeService from 'modules/company/services/companyType.service';
import suppliersService from 'modules/suppliers/services/suppliers.service';
import Modal from 'modules/shared/components/Modal';
import managementRoutes from 'modules/management/management.routes';
import rolesConstants from 'modules/shared/constants/roles.constants';
import Form from 'modules/shared/components/Form';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';
import formStyles from 'modules/shared/components/Form/Form.module.scss';
import * as companyTypeMappers from 'modules/company/mappers/companies.mappers';
import useNotifications from 'modules/shared/hooks/useNotifications';

import styles from './MyProfile.module.scss';

interface Props {
  user: CustomerReceived | SupplierReceived;
  submitForm: (user: Customer | Supplier) => void;
  role: string;
  isLoading: boolean;
}

const MyProfile = (props: Props) => {
  const {
    user,
    submitForm,
    role,
    isLoading,
  } = props;

  const getInitialFormState = () => {
    const {
      email,
      phoneNumber,
      firstName,
      lastName,
    } = user.user;

    const {
      establishmentName,
      vat,
      companyTypeId,
      companyName,
    } = user.company;

    const {
      street,
      city,
      zipCode,
    } = user.address;

    return [
      email,
      phoneNumber,
      lastName,
      firstName,
      establishmentName,
      street,
      city,
      zipCode,
      companyTypeId,
      vat,
      companyName,
    ].map((value) => ({ value, isValid: true }));
  };

  const {
    fields: [
      EMAIL,
      PHONENUMBER,
      LASTNAME,
      FIRSTNAME,
      COMPANY,
      STREET,
      CITY,
      ZIP,
      TYPE,
      VAT,
      COMPANYNAME,
    ],
    getFieldValue,
    isValid,
    handleChange,
  } = useQuickForm(getInitialFormState());

  const email = getFieldValue<string>(EMAIL);
  const phoneNumber = getFieldValue<string>(PHONENUMBER);
  const lastName = getFieldValue<string>(LASTNAME);
  const firstName = getFieldValue<string>(FIRSTNAME);
  const establishmentName = getFieldValue<string>(COMPANY);
  const street = getFieldValue<string>(STREET);
  const city = getFieldValue<string>(CITY);
  const zip = getFieldValue<string>(ZIP);
  const type = getFieldValue<string>(TYPE);
  const vat = getFieldValue<string>(VAT);
  const companyName = getFieldValue<string>(COMPANYNAME);

  const [companyType, setCompanyType] = useState<CompanyType[]>([]);
  const [isActive, setIsActive] = useState(false);
  const { addNotification: addNotif } = useNotifications();

  const history = useHistory();

  const getAllCompanies = useSafeApiCall(companyTypeService.getAll);

  const deleteSupplier = useSafeApiCall(suppliersService.deleteSupplier);

  const loadCompanyType = async () => {
    try {
      const [result, isAborted] = await getAllCompanies();
      if (isAborted) {
        return;
      }

      setCompanyType(result);
    } catch (err) {
      addNotif('error', err.messages);
    }
  };

  const companyTypeOptions = useMemo(() => companyType
    .map(companyTypeMappers.mapToSelectOption), [companyType]);

  useEffect(() => {
    loadCompanyType();
  }, []);

  const toggleModal = () => {
    setIsActive(!isActive);
  };

  const permanentDeleteSupplier = async () => {
    if (!user.id) {
      return;
    }

    try {
      const [, isAborted] = await deleteSupplier(user.id);
      if (isAborted) {
        return;
      }

      history.push({
        pathname: `${managementRoutes.MANAGE}${managementRoutes.SUPPLIERS}`,
        search: 'deleted=true',
      });
    } catch (error) {
      addNotif('error', error.messages);
    }
  };

  const renderAccount = () => {
    if (role === rolesConstants.ADMINISTRATOR) {
      return (
        <>
          <hr />
          <div>
            <h3 className={classNames('title is-3', styles.title)}>Gestion du compte</h3>
            <div className={classNames(styles.buttons)}>
              <Button
                type="button"
                className="button is-warning"
                value="Désactiver le compte"
                onClick={toggleModal}
              />
              <Button
                type="button"
                className="button is-danger"
                value="Supprimer définitivement le compte"
                onClick={toggleModal}
              />
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValid) {
      return;
    }

    const userCreated: Supplier | Customer = {
      id: user.id,
      userid: user.user.id,
      email,
      phoneNumber,
      firstName,
      lastName,
      companyName,
      companyEstablishmentName: establishmentName,
      addressStreet: street,
      addressCity: city,
      companyTypeId: type,
      companyVat: vat,
      addressZipCode: zip,
    };

    submitForm(userCreated);
  };

  return (
    <Section isFullHeight isLightGrey>
      {isActive && (
        <Modal
          title="Confirmation"
          actions={[
            {
              text: 'Supprimer',
              onClick: permanentDeleteSupplier,
              className: 'button is-danger',
            },
            {
              text: 'Annuler',
              onClick: toggleModal,
              className: 'button is-dark',
            },
          ]}
        >
          <p>Etes-vous sur de vouloir supprimer ce fournisseur?</p>
        </Modal>
      )}

      <Form
        title="Modifier mes informations"
        submit={submit}
      >
        <div className={classNames(formStyles.formControls, formStyles.isDividedBy2)}>
          <Input
            label="Numéro de TVA"
            name={VAT}
            value={vat}
            required
            disabled
          />

          <Input
            label="Nom de l'entreprise"
            name={COMPANYNAME}
            value={companyName}
            required
            disabled
          />
        </div>

        <div className={classNames(formStyles.formControls, formStyles.isDividedBy2)}>
          <Input
            label="Nom de l'établissement"
            placeholder="Nom de l'établissement"
            name={COMPANY}
            value={establishmentName}
            required
            onChange={handleChange}
            disabled={isLoading}
          />

          <Select
            labelText="Type d&apos;établissement"
            placeholder="Veuillez choisir un type d'établissement"
            name={TYPE}
            onChange={handleChange}
            value={type}
            required
            options={companyTypeOptions}
            disabled={isLoading}
          />
        </div>

        <div className={classNames(formStyles.formControls, formStyles.isDividedBy2)}>
          <Input
            label="Adresse mail"
            placeholder="Email"
            name={EMAIL}
            value={email}
            required
            onChange={handleChange}
            disabled={isLoading}
          />

          <Input
            label="Numéro de téléphone"
            placeholder="0472 47 31 28"
            delimiters={[' ']}
            name={PHONENUMBER}
            value={phoneNumber}
            required
            onChange={handleChange}
            disabled={isLoading}
          />
        </div>

        <div className={classNames(formStyles.formControls, formStyles.isDividedBy2)}>
          <Input
            label="Nom de la personne de contact"
            placeholder="Nom"
            name={LASTNAME}
            value={lastName}
            required
            className="is-rounded"
            onChange={handleChange}
            disabled={isLoading}
          />

          <Input
            label="Prénom de la personne de contact"
            placeholder="Prénom"
            name={FIRSTNAME}
            value={firstName}
            required
            className="is-rounded"
            onChange={handleChange}
            disabled={isLoading}
          />
        </div>

        <div className={classNames(formStyles.formControls, formStyles.isDividedBy3)}>
          <Input
            label="Rue et numéro"
            placeholder="Rue et numéro"
            name={STREET}
            value={street}
            required
            className="is-rounded"
            onChange={handleChange}
            disabled={isLoading}
          />

          <Input
            label="Ville"
            placeholder="Ville"
            name={CITY}
            value={city}
            required
            className="is-rounded"
            onChange={handleChange}
            disabled={isLoading}
          />

          <Input
            label="Code postal"
            placeholder="Code postal"
            name={ZIP}
            value={zip}
            required
            className="is-rounded"
            onChange={handleChange}
            disabled={isLoading}
          />
        </div>

        <div className={classNames(formStyles.formControls, formStyles.right)}>
          <div className={classNames('buttons', styles.button)}>
            <Button
              type="submit"
              className={classNames('button is-dark is-fullwidth', { 'is-loading': isLoading })}
              value="Valider les modifications"
              disabled={isLoading}
            />
          </div>
        </div>
        {renderAccount()}
      </Form>
    </Section>
  );
};

export default MyProfile;
