import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import productsService from 'modules/products/services/products.service';
import Product from 'modules/products/models/Product.model';
import useNotifications from 'modules/shared/hooks/useNotifications';
import useSafeApiCall from 'modules/shared/hooks/useSafeApiCall.hook';

import styles from './RelatedProducts.module.scss';

interface Props {
  product?: Product;
}

const RelatedProducts = (props: Props) => {
  const { product } = props;
  const [products, setProducts] = useState<Product[]>([]);
  const { addNotification } = useNotifications();

  const getSupplierProducts = useSafeApiCall(productsService.getProductBySupplier);

  const loadProducts = async () => {
    try {
      if (product?.supplier.id !== undefined) {
        const [result, isAborted] = await getSupplierProducts(product?.supplier.id, true);
        if (isAborted) {
          return;
        }

        setProducts(result);
      }
    } catch (err) {
      addNotification('error', err.messages);
    }
  };

  useEffect(() => {
    loadProducts();
  }, [product]);

  return (
    <>
      <div className={styles.container}>
        {products
          .filter((item) => item.id !== product?.id)
          .map((item) => (
            <div
              key={item.id}
              className={classNames('flex-full-center', styles.supplierProduct)}
            >
              <Link to={`?id=${item.id}`}>
                <img
                  key={item.id}
                  className={styles.image}
                  src={item.imageUrl}
                  alt={item.name}
                />
              </Link>
            </div>
          ))}
      </div>
    </>
  );
};

export default RelatedProducts;
